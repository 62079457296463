<div class="content-wrapper">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <ng-template #content let-modal>
        <div class="share-modal">
            <div class="modal-header">
                <h5 class="font-weight-400 share-emotion">
                    <i class="fas fa-user-circle cursor-pointer mr-2"></i>
                    <span>Update Profile Image</span>
                </h5>
                <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                   (click)="this.modalService.dismissAll('')"></i>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="imageChangedProfileEvent">
                    <div class="text-center col-md-12">
                        <image-cropper
                                [imageChangedEvent]="imageChangedProfileEvent"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="4 / 4"
                                [resizeToWidth]="400"
                                format="png, jpeg, jpg"
                                (imageCropped)="imageProfileCropped($event)"
                                (imageLoaded)="imageLoaded()"
                                (cropperReady)="cropperReady()"
                                (loadImageFailed)="loadImageFailed()">
                        </image-cropper>
                    </div>
                    <div class="text-center display-hidden">
                        <img [src]="croppedProfileImage" width="150px" height="150px"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <div class="profile-with-cover text-center">
                            <div class="card-img-top img-fluid bg-cover height-300 profile-background-image image-upload-area cursor-pointer mt-1 mb-1"
                                 *ngIf="!imageChangedProfileEvent" (click)="fileProfileUpload.click()"></div>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="fileChangeProfileEvent($event)" #fileProfileUpload>
                        </div>
                    </div>
                    <div class="col-sm-3"></div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-raised mr-1" type="button" (click)="fileProfileUpload.click()">New
                    Upload
                </button>
                <button class="btn btn-primary btn-raised" type="button" *ngIf="imageChangedProfileEvent"
                        (click)="saveProfileImage()">
                    <i class="fa fa-save mr-1"></i> Save
                </button>
            </div>
        </div>
    </ng-template>
    <div class="content-body">
        <section class="vertical-wizard">
            <div id="stepper" class="bs-stepper vertical vertical-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#personal-info-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Personal Info</span>
                                <span class="bs-stepper-subtitle">Add Personal Info</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#address-step-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Address</span>
                                <span class="bs-stepper-subtitle">Add Address</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#academic-details-step-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Academic Details</span>
                                <span class="bs-stepper-subtitle">Add Academic Details</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#more-qualifications-step-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">4</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">More Qualification</span>
                                <span class="bs-stepper-subtitle">Add More Qualification</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#social-links-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">5</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Course Details</span>
                                <span class="bs-stepper-subtitle">Add Social Links</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form class="form mt-1" novalidate [formGroup]="personalInfoForm">
                        <div id="personal-info-vertical" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">Personal Info</h5>
                                <small>Enter Your Personal Info.</small>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="fullName">Full Name</label>
                                    <input type="text" id="fullName" class="form-control" placeholder="Luv Kush"
                                           formControlName="full_name"/>
                                    <small *ngIf="submitted && personalInfoForm.get('full_name').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="email">Email</label>
                                    <input type="text" id="email" class="form-control" formControlName="email"
                                           placeholder="luvkush@ayodhya.com"/>
                                    <small *ngIf="submitted && personalInfoForm.get('email').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="fatherName">Father's Name</label>
                                    <input type="text" id="fatherName" class="form-control" placeholder="Ram"
                                           formControlName="father_name"/>
                                    <small *ngIf="submitted && personalInfoForm.get('father_name').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="motherName">Mother's Name</label>
                                    <input type="text" id="motherName" class="form-control" placeholder="Sita"
                                           formControlName="mother_name"/>
                                    <small *ngIf="submitted && personalInfoForm.get('mother_name').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="contactNumber">Contact Number</label>
                                    <input type="text" id="contactNumber" class="form-control" placeholder="9999999999"
                                           formControlName="contact_no"/>
                                    <small *ngIf="submitted && personalInfoForm.get('contact_no').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="alternateNumber">Alternate Contact Number</label>
                                    <input type="number" id="alternateNumber" class="form-control"
                                           placeholder="8888888888" formControlName="alternate_contact_no"/>
                                    <small
                                            *ngIf="submitted && personalInfoForm.get('alternate_contact_no').hasError('required')"
                                            class="text-danger">
                                        Field is required.
                                    </small>
                                    <small *ngIf="submitted && personalInfoForm.controls && personalInfoForm.controls['alternate_contact_no'] && personalInfoForm.controls['alternate_contact_no'].errors && personalInfoForm.controls['alternate_contact_no'].errors.pattern"
                                           class="text-danger">Invalid alternate contact number format</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Upload Your Photograph<span class="text-danger">*</span></label>
                                    <br>
                                    <button class="btn btn-primary color-white" (click)="openPhotographModal(content)">
                                        <i class="fas fa-cloud-upload-alt mr-1"></i>Upload
                                    </button>
                                    <span class="text-info cursor-pointer ml-1" (click)="openPhotograph()"
                                          *ngIf="filePhotograph">View</span>
                                    <input id="photograph" class="form-control-file display-hidden" type="text">
                                    <small *ngIf="submitted && personalInfoForm.get('photograph').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="govt_photo_id">Upload Your Govt. Photo Id<span
                                            class="text-danger">*</span></label>
                                    <br>
                                    <button class="btn btn-primary color-white"
                                            (click)="fileUploadPhotoId.click()" *ngIf="!disableAll">
                                        <i class="fas fa-cloud-upload-alt mr-1"></i>Upload
                                    </button>
                                    <button class="btn btn-secondary color-white" *ngIf="disableAll">
                                        <i class="fas fa-cloud-upload-alt mr-1"></i>Upload
                                    </button>
                                    <span class="text-info cursor-pointer ml-1" (click)="openPhotoId()"
                                          *ngIf="fileGovtPhotoId">
                                        View</span>
                                    <input id="govt_photo_id" formControlName="govt_photo_id"
                                           class="form-control-file display-hidden" type="text">
                                    <small *ngIf="submitted && personalInfoForm.get('govt_photo_id').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="onFileChange($event, 'GOVT_PHOTO_ID')" #fileUploadPhotoId>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between upper-shadow-light">
                                <button class="btn btn-primary btn-prev mt-1 disabled"
                                        (click)="verticalWizardPrevious()"
                                        rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button class="btn btn-primary btn-next mt-1" (click)="savePersonalInfo()"
                                        rippleEffect *ngIf="!personalInfoData">
                                    <span class="align-middle d-sm-inline-block d-none">Save & Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                                <button class="btn btn-primary btn-next mt-1" (click)="updatePersonalInfo()"
                                        rippleEffect *ngIf="personalInfoData">
                                    <span class="align-middle d-sm-inline-block d-none">Update & Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <form class="form mt-1" novalidate [formGroup]="addressForm">
                        <div id="address-step-vertical" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">Address</h5>
                                <small>Enter Your Address.</small>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="address">Address</label>
                                    <input type="text" id="address" class="form-control"
                                           placeholder="98  Borough bridge Road, Birmingham"
                                           formControlName="address"/>
                                    <small
                                            *ngIf="submitted && addressForm.get('address').hasError('required')"
                                            class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="landmark">Landmark</label>
                                    <input type="text" id="landmark" class="form-control" placeholder="Borough bridge"
                                           formControlName="landmark"/>
                                    <small *ngIf="submitted && addressForm.get('landmark').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="tehsilTown">Tehsil / Town</label>
                                    <input type="text" id="tehsilTown" class="form-control"
                                           placeholder="98  Borough bridge Road, Birmingham"
                                           formControlName="tehsil_town"/>
                                    <small *ngIf="submitted && addressForm.get('tehsil_town').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="district">District</label>
                                    <input type="text" id="district" class="form-control"
                                           placeholder="Borough bridge" formControlName="district"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label">State</label>
                                    <select class="form-control" id="state_id" formControlName="state_id">
                                        <option value="">Select State</option>
                                        <option [value]="state.id" *ngFor="let state of states">{{state?.name}}</option>
                                    </select>
                                    <small *ngIf="submitted && addressForm.get('state_id').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="pincode">Pincode</label>
                                    <input type="number" id="pincode" class="form-control" placeholder="658921"
                                           formControlName="pin"/>
                                    <small *ngIf="submitted && addressForm.get('pin').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between upper-shadow-light">
                                <button class="btn btn-primary btn-prev mt-1" (click)="verticalWizardPrevious()"
                                        rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button class="btn btn-primary btn-next mt-1" (click)="saveAddressInfo()"
                                        rippleEffect *ngIf="!addressData">
                                    <span class="align-middle d-sm-inline-block d-none">Save & Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                                <button class="btn btn-primary btn-next mt-1" (click)="updateAddress()"
                                        rippleEffect *ngIf="addressData">
                                    <span class="align-middle d-sm-inline-block d-none">Update & Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <form class="form mt-1" novalidate [formGroup]="qualificationDetailsForm">
                        <div id="academic-details-step-vertical" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">Academic Details</h5>
                                <small>Enter Your Academic Details.</small>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h5 class="mb-0">Details of School attended - 10th Academic Details</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="tenth_board">Board<span class="text-danger ml-5px">*</span></label>
                                    <select class="form-control" id="tenth_board" formControlName="tenth_board">
                                        <option value="">Select Board</option>
                                        <option [value]="board.value"
                                                *ngFor="let board of boardUniversity">{{board?.name}}</option>
                                    </select>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('tenth_board').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="tenth_institution_name">Institution Name<span
                                            class="text-danger ml-5px">*</span></label>
                                    <input class="form-control" id="tenth_institution_name" type="text"
                                           formControlName="tenth_institution_name"/>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('tenth_institution_name').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="tenth_year_of_passing">Year of Passing<span
                                            class="text-danger ml-5px">*</span></label>
                                    <select class="form-control" id="tenth_year_of_passing" type="text"
                                            formControlName="tenth_year_of_passing">
                                        <option value="">Select Year</option>
                                        <option [value]="year" *ngFor="let year of years">{{year}}</option>
                                    </select>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('tenth_year_of_passing').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="tenth_marking_scheme">Marking Scheme<span
                                            class="text-danger ml-5px">*</span></label>
                                    <select class="form-control" id="tenth_marking_scheme"
                                            formControlName="tenth_marking_scheme">
                                        <option value="">Select Marking Scheme</option>
                                        <option [value]="marking.value"
                                                *ngFor="let marking of markingScheme">{{marking?.name}}</option>
                                    </select>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('tenth_marking_scheme').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="tenth_obtained_percentage_cgpa">Obtained Percentage / CGPA<span
                                            class="text-danger ml-5px">*</span></label>
                                    <input class="form-control" id="tenth_obtained_percentage_cgpa" type="number"
                                           formControlName="tenth_obtained_percentage_cgpa">
                                    <small *ngIf="submitted && qualificationDetailsForm.get('tenth_obtained_percentage_cgpa').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="tenth_certificate">Upload 10th Certificate</label><br>
                                    <button class="btn btn-primary color-white"
                                            (click)="fileUploadTenthCertificate.click()" *ngIf="!disableAll">
                                        <i class="fas fa-cloud-upload-alt mr-10px ml--10px"></i>Upload
                                    </button>
                                    <button class="btn btn-secondary color-white" *ngIf="disableAll">
                                        <i class="fas fa-cloud-upload-alt mr-10px ml--10px"></i>Upload
                                    </button>
                                    <span class="text-info cursor-pointer ml-1" (click)="openTenthCertificate()"
                                          *ngIf="fileTenthCertificate">View</span>
                                    <input id="tenth_certificate" class="form-control-file display-hidden" type="text"
                                           formControlName="tenth_certificate">
                                    <small *ngIf="submitted && qualificationDetailsForm.get('tenth_certificate').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="onFileChange($event, 'TENTH_CERTIFICATE')"
                                           #fileUploadTenthCertificate>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <h5 class="mb-0">Details of School attended - 12th Academic Details</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="twelth_board">Board<span class="text-danger ml-5px">*</span></label>
                                    <select class="form-control" id="twelth_board" formControlName="twelth_board">
                                        <option value="">Select Board</option>
                                        <option [value]="board.value"
                                                *ngFor="let board of boardUniversity">{{board?.name}}</option>
                                    </select>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('twelth_board').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="twelth_institution_name">Institution Name<span
                                            class="text-danger ml-5px">*</span></label>
                                    <input class="form-control" id="twelth_institution_name" type="text"
                                           formControlName="twelth_institution_name"/>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('twelth_institution_name').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="twelth_year_of_passing">Year of Passing<span
                                            class="text-danger ml-5px">*</span></label>
                                    <select class="form-control" id="twelth_year_of_passing" type="text"
                                            formControlName="twelth_year_of_passing">
                                        <option value="">Select Year</option>
                                        <option [value]="year" *ngFor="let year of years">{{year}}</option>
                                    </select>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('twelth_year_of_passing').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="twelth_marking_scheme">Marking Scheme<span
                                            class="text-danger ml-5px">*</span></label>
                                    <select class="form-control" id="twelth_marking_scheme"
                                            formControlName="twelth_marking_scheme">
                                        <option value="">Select Marking Scheme</option>
                                        <option [value]="marking.value"
                                                *ngFor="let marking of markingScheme">{{marking?.name}}</option>
                                    </select>
                                    <small *ngIf="submitted && qualificationDetailsForm.get('twelth_marking_scheme').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="twelth_obtained_percentage_cgpa">Obtained Percentage / CGPA<span
                                            class="text-danger ml-5px">*</span></label>
                                    <input class="form-control" id="twelth_obtained_percentage_cgpa" type="number"
                                           formControlName="twelth_obtained_percentage_cgpa">
                                    <small *ngIf="submitted && qualificationDetailsForm.get('twelth_obtained_percentage_cgpa').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="twelth_certificate">Upload 12th Certificate</label><br>
                                    <button class="btn btn-primary color-white"
                                            (click)="fileUploadTwelthCertificate.click()" *ngIf="!disableAll">
                                        <i class="fas fa-cloud-upload-alt mr-10px ml--10px"></i>Upload
                                    </button>
                                    <button class="btn btn-secondary color-white" *ngIf="disableAll">
                                        <i class="fas fa-cloud-upload-alt mr-10px ml--10px"></i>Upload
                                    </button>
                                    <span class="text-info cursor-pointer ml-1" (click)="openTwelthCertificate()"
                                          *ngIf="fileTwelthCertificate">View</span>
                                    <input id="twelth_certificate" class="form-control-file display-hidden" type="text"
                                           formControlName="twelth_certificate">
                                    <small *ngIf="submitted && qualificationDetailsForm.get('twelth_certificate').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="onFileChange($event, 'TWELTH_CERTIFICATE')"
                                           #fileUploadTwelthCertificate>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between upper-shadow-light">
                                <button class="btn btn-primary btn-prev mt-1" (click)="verticalWizardPrevious()"
                                        rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button class="btn btn-primary btn-next mt-1" (click)="saveQualificationDetails()"
                                        rippleEffect *ngIf="!qualificationData">
                                    <span class="align-middle d-sm-inline-block d-none">Save & Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                                <button class="btn btn-primary btn-next mt-1" (click)="updateQualificationDetails()"
                                        rippleEffect *ngIf="qualificationData">
                                    <span class="align-middle d-sm-inline-block d-none">Update & Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div id="more-qualifications-step-vertical" class="content">
                        <div class="content-header">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <h5 class="mb-0">More Qualifications</h5>
                                    <small>Enter More Qualifications Details.</small>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <button class="btn btn-icon btn-primary pull-right" type="button"
                                            (click)="addItem()"
                                            rippleEffect>
                                        <i data-feather="plus" class="mr-25"></i>
                                        <span>Add New</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="invoice-repeater">
                                    <div *ngFor="let item of items; let i = index" @heightIn>
                                        <div class="row align-items-end">
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <label for="university{{i}}">University</label>
                                                    <input type="text" class="form-control"
                                                           [(ngModel)]="item.university"
                                                           name="university{{i}}" id="university{{i}}"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <label>Institution Name</label>
                                                    <input type="text" class="form-control"
                                                           [(ngModel)]="item.institution" name="institution{{i}}"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <label>Passing Year</label>
                                                    <select class="form-control" [(ngModel)]="item.passing_year"
                                                            name="passing_year{{i}}">
                                                        <option value="">Select Passing Year</option>
                                                        <option [value]="year"
                                                                *ngFor="let year of years"> {{year}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <label>Course</label>
                                                    <input type="text" class="form-control"
                                                           [(ngModel)]="item.course" name="course{{i}}"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <label>Branch</label>
                                                    <input type="text" class="form-control"
                                                           [(ngModel)]="item.branch" name="branch{{i}}"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <div class="form-group">
                                                    <label>Obtained Percentage</label>
                                                    <input type="number" class="form-control"
                                                           [(ngModel)]="item.obtained_percenatge"
                                                           name="obtained_percenatge{{i}}"/>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12">
                                                <div class="form-group">
                                                    <label>Upload Completion Certificate</label>
                                                    <br>
                                                    <button class="btn btn-primary color-white"
                                                            (click)="fileUploadCompletetion.click()">
                                                        <i class="fas fa-cloud-upload-alt mr-5px ml--5px"></i>Upload
                                                    </button>
                                                    <span class="text-info cursor-pointer ml-1"
                                                          (click)="openCompletetionCertificate(item)"
                                                          *ngIf="item.completion_certificate">View</span>
                                                    <input class="form-control-file display-hidden" type="text"
                                                           [(ngModel)]="item.completion_certificate"
                                                           name="completion_certificate{{i}}">
                                                    <input class="form-control-file display-hidden" type="file"
                                                           (change)="onFileChange($event, 'MORE_QUALIFICATION', i)"
                                                           #fileUploadCompletetion>
                                                    <button class="btn btn-outline-danger text-nowrap px-1 pull-right"
                                                            (click)="deleteItem(i)" rippleEffect>
                                                        <i data-feather="x" class="mr-25"></i>
                                                        <span>Delete</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between upper-shadow-light mt-2">
                            <button class="btn btn-primary btn-prev mt-1" (click)="verticalWizardPrevious()"
                                    rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-primary btn-next mt-1" (click)="updateMoreQualifications()"
                                    rippleEffect *ngIf="moreQualificationData && moreQualificationData.length > 0">
                                <span class="align-middle d-sm-inline-block d-none">Update & Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                            <button class="btn btn-primary btn-next mt-1" (click)="saveMoreQualifications()"
                                    rippleEffect *ngIf="!moreQualificationData || moreQualificationData.length === 0">
                                <span class="align-middle d-sm-inline-block d-none">Save & Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                        </div>
                    </div>
                    <form class="form mt-1" novalidate [formGroup]="studentCourseDetailsForm">
                        <div id="social-links-vertical" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">Course Details</h5>
                                <small>Add Course Details.</small>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="program">Program</label>
                                    <select class="form-control" id="program" formControlName="program_id"
                                            (change)="getCourse($event.target.value)">
                                        <option value="">Select Program</option>
                                        <option [value]="program.id"
                                                *ngFor="let program of programs">{{program?.name}}</option>
                                    </select>
                                    <small *ngIf="submitted && studentCourseDetailsForm.get('program_id').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6"></div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="course">Course<span class="text-danger">*</span></label>
                                    <select class="form-control" id="course" formControlName="course_id"
                                            (change)="getBranch($event.target.value)">
                                        <option value="">Select Course</option>
                                        <option [value]="course.course.id"
                                                *ngFor="let course of courses">{{course?.course?.name}}</option>
                                    </select>
                                    <small
                                            *ngIf="submitted && studentCourseDetailsForm.get('course_id').hasError('required')"
                                            class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group col-md-6"></div>
                            </div>
                            <div class="row" *ngIf="this.branches && this.branches.length > 0">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="branch">Branch</label>
                                        <select class="form-control" id="branch" formControlName="branch_id">
                                            <option value="">Select Branch</option>
                                            <option [value]="branch.branch.id"
                                                    *ngFor="let branch of branches">{{branch?.branch?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && studentCourseDetailsForm.get('branch_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group col-md-6"></div>
                            </div>
                            <div class="d-flex justify-content-between upper-shadow-light">
                                <button class="btn btn-primary btn-prev mt-1" (click)="verticalWizardPrevious()"
                                        rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button class="btn btn-primary btn-next mt-1" (click)="updateCourses()"
                                        rippleEffect *ngIf="studentCourseData">
                                    <span class="align-middle d-sm-inline-block d-none">Update & Submit</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                                <button class="btn btn-success btn-next mt-1" (click)="saveCourses()"
                                        rippleEffect *ngIf="!studentCourseData">
                                    <span class="align-middle d-sm-inline-block d-none">Submit</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>
