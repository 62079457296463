import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {EmailService} from '../../shared/services/email.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-mails',
    templateUrl: './mails.component.html',
    styleUrls: ['./mails.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'email-application'}
})

export class MailsComponent implements OnInit {

    constructor(@Inject(DOCUMENT) private document, private route: ActivatedRoute, private _emailService: EmailService) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(val => {
            this._emailService.updateSearchText(val.q);
        });
    }
}
