import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class AdmissionService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                public fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    /*imageUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }*/

    get(data): Observable<any> {
        return this.getRequest(AppUrl.BRANCHES(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.BRANCHES_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.BRANCHES(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.BRANCHES(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.BRANCHES(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.BRANCHES_STATUS(id), data);
    }
}
