import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MailsComponent} from './mails.component';

const routes: Routes = [
    {
        path: 'mails',
        component: MailsComponent,
        data: {animation: 'mails'}
    },
    {
        path: 'mails/:folderName',
        component: MailsComponent,
        data: {animation: 'mails'}
    },
    {
        path: 'mails/label/:labelName',
        component: MailsComponent,
        data: {animation: 'mails'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class MailsRoutingModule {
}
