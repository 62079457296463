import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {IndianNumberPipe} from './pipes/indian-number.pipe';
import {StripHtmlPipe} from './pipes/stripHtml.pipe';
import {SafePipe} from './pipes/safe.pipe';

@NgModule({
    exports: [
        CommonModule,
        NgbModule,
        IndianNumberPipe,
        StripHtmlPipe,
        SafePipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule
    ],
    declarations: [
        IndianNumberPipe,
        StripHtmlPipe,
        SafePipe
    ]
})
export class SharedModule {
}
