import {Component, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {EmailService} from '../../../shared/services/email.service';
import {Subject} from 'rxjs';
import {StudentService} from '../../../shared/services/student.service';
import {MailService} from '../../../shared/services/mail.service';
import {FormGroup} from '@angular/forms';
import {AdminService} from '../../../shared/services/admin.service';
import {StorageService} from '../../../shared/services/storage.service';
import {HeadCounsellorService} from '../../../shared/services/head-counsellor.service';

@Component({
    selector: 'app-mail-compose',
    templateUrl: './mail-compose.component.html',
    styleUrls: ['./mail-compose.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MailComposeComponent implements OnInit {
    students = [];
    admins = [];
    head_counsellors = [];

    @HostListener('keydown.escape') fn() {
        this.closeCompose();
    }

    @ViewChild('emailTo') private _emailTo: any;

    /*public emailToSelect = [
        {name: 'Jane Foster', avatar: 'assets/images/portrait/small/avatar-s-3.jpg'},
        {name: 'Donna Frank', avatar: 'assets/images/portrait/small/avatar-s-1.jpg'},
        {name: 'Gabrielle Robertson', avatar: 'assets/images/portrait/small/avatar-s-4.jpg'},
        {name: 'Lori Spears', avatar: 'assets/images/portrait/small/avatar-s-6.jpg'}
    ];*/

    /*public emailCCSelect = [
        {name: 'Jane Foster', avatar: 'assets/images/portrait/small/avatar-s-3.jpg'},
        {name: 'Donna Frank', avatar: 'assets/images/portrait/small/avatar-s-1.jpg'},
        {name: 'Gabrielle Robertson', avatar: 'assets/images/portrait/small/avatar-s-4.jpg'},
        {name: 'Lori Spears', avatar: 'assets/images/portrait/small/avatar-s-6.jpg'}
    ];*/

    /*public emailBCCSelect = [
        {name: 'Jane Foster', avatar: 'assets/images/portrait/small/avatar-s-3.jpg'},
        {name: 'Donna Frank', avatar: 'assets/images/portrait/small/avatar-s-1.jpg'},
        {name: 'Gabrielle Robertson', avatar: 'assets/images/portrait/small/avatar-s-4.jpg'},
        {name: 'Lori Spears', avatar: 'assets/images/portrait/small/avatar-s-6.jpg'}
    ];*/

    public emailToSelect = [];
    public emailCCSelect = [];
    public emailBCCSelect = [];
    public emailCCSelected = [];
    public emailBCCSelected = [];

    public isOpenCC = false;
    public isOpenBCC = false;

    public isComposeOpen: boolean = false;

    private _unsubscribeAll: Subject<any>;
    createMailForm: FormGroup;
    emailCC: any;
    emailBCC: any;
    user: any;

    constructor(private _emailService: EmailService,
                private studentService: StudentService,
                private adminService: AdminService,
                private counsellorService: HeadCounsellorService,
                private mailService: MailService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        if (this.user && this.user['user_type'] === 'COUNSELLOR') {
            this.getStudents();
            this.getAdmins();
        } else if (this.user && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN')) {
            this.getAdmins();
            this.getHeadCounsellors();
        }
        // Subscribe to Compose Model Changes
        this._emailService.composeEmailChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
            this.isComposeOpen = response;
            if (this.isComposeOpen) {
                setTimeout(() => {
                    this._emailTo.searchInput.nativeElement.focus();
                }, 0);
            }
        });
    }

    togglCcBcc(toggleRef) {
        if (toggleRef == 'cc') {
            this.isOpenCC = !this.isOpenCC;
        } else {
            this.isOpenBCC = !this.isOpenBCC;
        }
    }

    closeCompose() {
        this.isComposeOpen = false;
        this._emailService.composeEmail(this.isComposeOpen);
    }

    sendEmail(mailTo, subject, message) {
        let ccs = [];
        if (this.emailCC && this.emailCC.length > 0) {
            this.emailCC.forEach(cc => {
                ccs.push(cc.user_id);
            });
        }
        let bccs = [];
        if (this.emailBCC && this.emailBCC.length > 0) {
            this.emailBCC.forEach(bcc => {
                bccs.push(bcc.user_id);
            });
        }
        let receivers = [];
        if (mailTo && mailTo['selectedItems'] && mailTo['selectedItems'].length > 0) {
            mailTo['selectedItems'].forEach(receiver => {
                receivers.push(receiver['value'].user_id);
            });
        }
        const params = {
            'subject': (subject && subject.value) ? subject.value : '',
            'message': message['quillEditor']['container'].innerHTML,
            'ccs': JSON.stringify(ccs),
            'bccs': JSON.stringify(bccs),
            'receivers': JSON.stringify(receivers),
        };
        this.mailService.create(params).subscribe(data => {
            if (data) {
                this.isComposeOpen = false;
            }
        });
    }

    getStudents() {
        this.students = [];
        this.studentService.get({'page': -1}).subscribe(data => {
            this.emailToSelect = [];
            if (data && data['data'].length > 0) {
                this.students = data['data'];
                if (this.students && this.students.length > 0) {
                    let emailToSelect = [];
                    this.students.forEach(student => {
                        emailToSelect.push({
                            'name': student['student'].name + ' - ' + student['student'].username,
                            'user_id': student['student'].id,
                            'avatar': 'assets/images/portrait/small/avatar-s-3.jpg'
                        });
                    });
                    this.emailToSelect = emailToSelect;
                }
            } else {
                this.students = [];
            }
        });
    }

    getAdmins() {
        this.admins = [];
        this.adminService.get({'page': -1}).subscribe(data => {
            this.emailCCSelect = [];
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                if (this.admins && this.admins.length > 0) {
                    let emailCCSelect = [];
                    this.admins.forEach(admin => {
                        emailCCSelect.push({
                            'name': admin.name + ' - ' + admin.username,
                            'user_id': admin.id,
                            'avatar': 'assets/images/portrait/small/avatar-s-3.jpg'
                        });
                    });
                    this.emailCCSelect = emailCCSelect;
                    this.emailBCCSelect = emailCCSelect;
                }
            } else {
                this.admins = [];
            }
        });
    }

    getHeadCounsellors() {
        this.head_counsellors = [];
        this.counsellorService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.head_counsellors = data['data'];
                if (this.head_counsellors && this.head_counsellors.length > 0) {
                    let emailToSelect = [];
                    this.head_counsellors.forEach(counsellor => {
                        emailToSelect.push({
                            'name': counsellor.name + ' - ' + counsellor.username,
                            'user_id': counsellor.id,
                            'avatar': 'assets/images/portrait/small/avatar-s-3.jpg'
                        });
                    });
                    this.emailToSelect = emailToSelect;
                }
            } else {
                this.head_counsellors = [];
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
