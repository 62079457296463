import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SrMappingService} from '../../shared/services/sr-mapping.service';
import {StageService} from '../../shared/services/stage.service';
import {ReasonService} from '../../shared/services/reason.service';

@Component({
    selector: 'app-sr-mapping',
    templateUrl: './sr-mapping.component.html',
    styleUrls: ['./sr-mapping.component.scss']
})
export class SrMappingComponent implements OnInit {
    showSearch = false;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    stages = [];
    reasons = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    srmapping = [];

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private srmappingService: SrMappingService,
                private stageService: StageService,
                private reasonService: ReasonService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'stage_id': [''],
            'reason_id': ['']
        });
        this.get();
        this.getStages();
        this.getReasons();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-sr-mapping').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getStages() {
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    getReasons() {
        this.reasonService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.reasons = data['data'];
            }
        });
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
        }
        this.toggleSidebar();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.srmapping = [];
        this.srmappingService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.srmapping = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.srmapping = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'stage_id': (this.searchForm.value && this.searchForm.value.stage_id) ? this.searchForm.value.stage_id : '',
            'reason_id': (this.searchForm.value && this.searchForm.value.reason_id) ? this.searchForm.value.reason_id : '',
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'stage_id': (this.searchForm && this.searchForm.value && this.searchForm.value.stage_id) ? this.searchForm.value.stage_id : '',
            'reason_id': (this.searchForm && this.searchForm.value && this.searchForm.value.reason_id) ? this.searchForm.value.reason_id : '',
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            // tslint:disable-next-line:no-shadowed-variable
            this.srmappingService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }
}
