import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UniversitysettingsRoutingModule} from './universitysettings-routing.module';
import {UniversitysettingsComponent} from './universitysettings.component';
import {AddUniversitysettingComponent} from './add-universitysetting/add-universitysetting.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [UniversitysettingsComponent, AddUniversitysettingComponent],
    imports: [
        CommonModule,
        UniversitysettingsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule
    ],
    exports: [UniversitysettingsComponent, AddUniversitysettingComponent]
})
export class UniversitysettingsModule {
}
