import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WhatsAppsRoutingModule} from './whats-apps-routing.module';
import {WhatsAppsComponent} from './whats-apps.component';
import { AddWhatsappComponent } from './add-whatsapp/add-whatsapp.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {AdminsModule} from '../admins/admins.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [WhatsAppsComponent, AddWhatsappComponent],
    imports: [
        CommonModule,
        WhatsAppsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgSelectModule,
        AdminsModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule
    ],
    exports: [WhatsAppsComponent, AddWhatsappComponent],
})

export class WhatsAppsModule {
}
