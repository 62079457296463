import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdmissionComponent} from './admission.component';

const routes: Routes = [
    {
        path: 'admission/:id',
        component: AdmissionComponent,
        data: {animation: 'admission'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdmissionRoutingModule {
}
