import {Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from 'app/auth/service';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreMediaService} from '@core/services/media.service';
import {User} from 'app/auth/models';
import {coreConfig} from 'app/app-config';
import {Router} from '@angular/router';
import {StorageService} from '../../../shared/services/storage.service';
import {AuthService} from '../../../shared/services/auth.service';
import {UniversityService} from '../../../shared/services/university.service';
import {HeadCounsellorService} from '../../../shared/services/head-counsellor.service';
import {AdminService} from '../../../shared/services/admin.service';
import {environment} from '../../../../environments/environment';
import {DataService} from '../../../shared/services/data.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NavbarComponent implements OnInit, OnDestroy {
    public horizontalMenu: boolean;
    public hiddenMenu: boolean;

    public coreConfig: any;
    public currentSkin: string;
    public prevSkin: string;

    public currentUser: User;

    public languageOptions: any;
    public navigation: any;
    public selectedLanguage: any;

    @HostBinding('class.fixed-top')
    public isFixed = false;

    @HostBinding('class.navbar-static-style-on-scroll')
    public windowScrolled = false;
    profileImage: any = './assets/images/image-backgrounds/1600-1600.jpg';

    // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (
            (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
            this.coreConfig.layout.navbar.type == 'navbar-static-top'
        ) {
            this.windowScrolled = true;
        } else if (
            (this.windowScrolled && window.pageYOffset) ||
            document.documentElement.scrollTop ||
            document.body.scrollTop < 10
        ) {
            this.windowScrolled = false;
        }
    }

    // Private
    private _unsubscribeAll: Subject<any>;
    user: any;
    baseUrl = environment.appUrl;

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _coreConfigService: CoreConfigService,
        private _coreMediaService: CoreMediaService,
        private _coreSidebarService: CoreSidebarService,
        private _mediaObserver: MediaObserver,
        public _translateService: TranslateService,
        private authService: AuthService,
        private universityService: UniversityService,
        private counsellorService: HeadCounsellorService,
        private adminService: AdminService,
        private router: Router,
        private dataService: DataService
    ) {
        this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

        this.languageOptions = {
            en: {
                title: 'English',
                flag: 'us'
            },
            fr: {
                title: 'French',
                flag: 'fr'
            },
            de: {
                title: 'German',
                flag: 'de'
            },
            pt: {
                title: 'Portuguese',
                flag: 'pt'
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.user = StorageService.getItem('self');
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebar(key): void {
        this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    }

    /**
     * Set the language
     *
     * @param language
     */
    setLanguage(language): void {
        // Set the selected language for the navbar on change
        this.selectedLanguage = language;

        // Use the selected language id for translations
        this._translateService.use(language);
    }

    /**
     * Toggle Dark Skin
     */
    toggleDarkSkin() {
        // Get the current skin
        this._coreConfigService
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.currentSkin = config.layout.skin;
            });

        // Toggle Dark skin with prevSkin skin
        this.prevSkin = localStorage.getItem('prevSkin');

        if (this.currentSkin === 'dark') {
            this._coreConfigService.setConfig(
                {layout: {skin: this.prevSkin ? this.prevSkin : 'default'}},
                {emitEvent: true}
            );
        } else {
            localStorage.setItem('prevSkin', this.currentSkin);
            this._coreConfigService.setConfig({layout: {skin: 'dark'}}, {emitEvent: true});
        }
    }

    /**
     * Profile Page
     */
    profilePage() {
        this.router.navigateByUrl('my-profile');
    }

    /**
     * Logout method
     */

    /*logout() {
        this._authenticationService.logout();
        this._router.navigate(['/pages/authentication/login-v2']);
    }*/

    logOut() {
        this.authService.logout().subscribe(data => {
            StorageService.clearAll();
            this.router.navigateByUrl('/auth/login');
        }, error => {
            this.router.navigateByUrl('/auth/login');
        });
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataService.message$.subscribe(message => {
            this.profileImage = message
        });
        // get the currentUser details from localStorage
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        // Subscribe to the config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
            this.horizontalMenu = config.layout.type === 'horizontal';
            this.hiddenMenu = config.layout.menu.hidden === true;
            this.currentSkin = config.layout.skin;

            // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
            if (this.coreConfig.layout.type === 'vertical') {
                setTimeout(() => {
                    if (this.coreConfig.layout.navbar.type === 'fixed-top') {
                        this.isFixed = true;
                    }
                }, 0);
            }
        });

        // Horizontal Layout Only: Add class fixed-top to navbar below large screen
        if (this.coreConfig.layout.type == 'horizontal') {
            // On every media(screen) change
            this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
                this.isFixed = !isFixedTop;
            });
        }

        // Set the selected language from default languageOptions
        this.selectedLanguage = _.find(this.languageOptions, {
            id: this._translateService.currentLang
        });

        this.getUniversityDetails();
        this.getCounsellorDetails();
        this.getAdminDetails();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getUniversityDetails() {
        if (this.user && this.user['userId']) {
            this.universityService.detail(this.user.userId).subscribe(data => {
                if (data && data['university_detail'] && data['university_detail']['profile_image']) {
                    this.profileImage = (data && data['university_detail'] && data['university_detail']['profile_image'] && data['university_detail']['profile_image'].url) ? data['university_detail']['profile_image'].url : '';
                    //this.profileImageUploadArea = this.baseUrl + data['university_detail']['profile_image']['folder_name'] + '/' + data['university_detail']['profile_image']['user_id'] + '/' + data['university_detail']['profile_image']['name'];
                }
            });
        }
    }

    getCounsellorDetails() {
        if (this.user && this.user['userId']) {
            this.counsellorService.detail(this.user.userId).subscribe(data => {
                if (data && data['counsellor_detail'] && data['counsellor_detail']['profile_image']) {
                    this.profileImage = (data && data['counsellor_detail'] && data['counsellor_detail']['profile_image'] && data['counsellor_detail']['profile_image'].url) ? data['counsellor_detail']['profile_image'].url : '';
                    //this.profileImageUploadArea = this.baseUrl + data['counsellor_detail']['profile_image']['folder_name'] + '/' + data['counsellor_detail']['profile_image']['user_id'] + '/' + data['counsellor_detail']['profile_image']['name'];
                }
            });
        }
    }

    getAdminDetails() {
        if (this.user && this.user['userId']) {
            this.adminService.detail(this.user.userId).subscribe(data => {
                if (data && data['admin_detail'] && data['admin_detail']['profile_image']) {
                    this.profileImage = (data && data['admin_detail'] && data['admin_detail']['profile_image'] && data['admin_detail']['profile_image'].url) ? data['admin_detail']['profile_image'].url : '';
                    //this.profileImageUploadArea = this.baseUrl + data['admin_detail']['profile_image']['folder_name'] + '/' + data['admin_detail']['profile_image']['user_id'] + '/' + data['admin_detail']['profile_image']['name'];
                }
            });
        }
    }
}
