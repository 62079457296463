import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoursesComponent} from '../courses/courses.component';
import {AddCourseComponent} from '../courses/add-course/add-course.component';
import {BranchesComponent} from './branches.component';
import {AddBranchesComponent} from './add-branches/add-branches.component';

const routes: Routes = [
    {
        path: 'branches',
        component: BranchesComponent,
        data: {animation: 'branches'}
    },
    {
        path: 'branch/add',
        component: AddBranchesComponent,
        data: {animation: 'branch/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BranchesRoutingModule {
}
