import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {WhatsappService} from '../../../shared/services/whatsapp.service';

@Component({
    selector: 'app-add-whatsapp',
    templateUrl: './add-whatsapp.component.html',
    styleUrls: ['./add-whatsapp.component.scss']
})
export class AddWhatsappComponent implements OnChanges {
    addWhatsAppForm: FormGroup;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private whatsappService: WhatsappService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-whatsapp').toggleOpen();
    }

    refresh() {
        this.addWhatsAppForm = this.fb.group({
            'student': ['', Validators.required],
            'university': ['', Validators.required],
            'message': ['', Validators.required]
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addWhatsAppForm.patchValue({
            'student': (this.data && this.data['student']) ? this.data['student'] : '',
            'university': (this.data && this.data['university']) ? this.data['university'] : '',
            'message': (this.data && this.data['message']) ? this.data['message'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addWhatsAppForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student': (this.addWhatsAppForm.value && this.addWhatsAppForm.value.student) ? this.addWhatsAppForm.value.student : '',
                'university': (this.addWhatsAppForm.value && this.addWhatsAppForm.value.university) ? this.addWhatsAppForm.value.university : '',
                'message': (this.addWhatsAppForm.value && this.addWhatsAppForm.value.message) ? this.addWhatsAppForm.value.message : ''
            };
            this.submitting = true;
            this.whatsappService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addWhatsAppForm.reset();
                    this.addWhatsAppForm.patchValue({
                        'student': '',
                        'university': '',
                        'message': ''

                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].university && error['error']['errors'].university.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].university[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addWhatsAppForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student': (this.addWhatsAppForm.value && this.addWhatsAppForm.value.student) ? this.addWhatsAppForm.value.student : '',
                'university': (this.addWhatsAppForm.value && this.addWhatsAppForm.value.university) ? this.addWhatsAppForm.value.university : '',
                'message': (this.addWhatsAppForm.value && this.addWhatsAppForm.value.message) ? this.addWhatsAppForm.value.message : ''
            };
            this.submitting = true;
            this.whatsappService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].university && error['error']['errors'].university.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].university[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

}
