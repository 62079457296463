import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UploadService extends BaseService {
    progress$;
    progressObserver;
    progress;

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
        this.progress$ = Observable.create(observer => {
            this.progressObserver = observer;
        }).pipe(share());
    }

    fileUpload(url, params, event: Event): Observable<any> {
        let files: any;
        const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
        if (params['fileType'] === 'NORMAL') {
            files = element && element.files ? element.files : '';
        } else {
            files = event;
        }
        return this.makeFileRequest(files, url, params);
    }

    makeFileRequest(files, url: string, params: any): Observable<any> {
        return Observable.create(observer => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();

            for (const param in params) {
                formData.append(param, params[param]);
            }
            formData.append('fileType', params.fileType);

            if (params.fileType === 'NORMAL') {
                formData.append('file', files[0], files[0].name);
            } else {
                formData.append('file', files);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            if (this.globalService.getAccessToken()) {
                xhr.setRequestHeader('Accept', 'application/json');
                xhr.setRequestHeader('device', 'WEB');
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.globalService.getAccessToken());
            }
            xhr.send(formData);
        });
    }
}
