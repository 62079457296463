import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgramsRoutingModule} from './programs-routing.module';
import {ProgramsComponent} from './programs.component';
import {AddProgramComponent} from './add-program/add-program.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [ProgramsComponent, AddProgramComponent],
    imports: [
        CommonModule,
        ProgramsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule
    ],
    exports: [ProgramsComponent, AddProgramComponent]
})
export class ProgramsModule {
}
