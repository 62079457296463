import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CourseCurriculumComponent} from './course-curriculum.component';

const routes: Routes = [
  {
    path: 'course-curriculum',
    component: CourseCurriculumComponent,
    data: {animation: 'course-curriculum'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseCurriculumRoutingModule { }
