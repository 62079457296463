import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EmailService} from '../../../shared/services/email.service';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';

@Component({
    selector: 'app-mail-sidebar',
    templateUrl: './mail-sidebar.component.html',
    styleUrls: ['./mail-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MailSidebarComponent implements OnInit {
    public folders;
    public labels;
    public openComposeRef;
    public draftCount;
    public unReadInboxCount;

    private _unsubscribeAll: Subject<any>;

    constructor(private _emailService: EmailService, private _coreSidebarService: CoreSidebarService) {
        this._unsubscribeAll = new Subject();
    }

    openCompose() {
        this.openComposeRef = true;
        this._emailService.composeEmail(this.openComposeRef);
        this._coreSidebarService.getSidebarRegistry('email-sidebar').toggleOpen();
    }

    toggleSidebar(nameRef): void {
        this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
    }

    ngOnInit(): void {
        // Subscribe to Folder
        this._emailService.onFoldersChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
            this.folders = [
                {
                    id: 0,
                    handle: 'inbox',
                    title: 'Inbox',
                    icon: 'fas fa-envelope'
                },
                {
                    id: 1,
                    handle: 'sent',
                    title: 'Sent',
                    icon: 'fab fa-telegram-plane'
                },
                {
                    id: 2,
                    handle: 'draft',
                    title: 'Draft',
                    icon: 'fas fa-pencil-alt'
                },
                {
                    id: 3,
                    handle: 'spam',
                    title: 'Spam',
                    icon: 'fas fa-info-circle'
                },
                {
                    id: 4,
                    handle: 'trash',
                    title: 'Trash',
                    icon: 'fas fa-trash'
                }
            ];
        });

        // Subscribe to Labels
        this._emailService.onLabelsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
            this.labels = [
                {
                    id: 0,
                    handle: 'personal',
                    title: 'Personal',
                    color: 'bullet-success'
                },
                {
                    id: 1,
                    handle: 'company',
                    title: 'Company',
                    color: 'bullet-primary'
                },
                {
                    id: 2,
                    handle: 'important',
                    title: 'Important',
                    color: 'bullet-warning'
                },
                {
                    id: 3,
                    handle: 'private',
                    title: 'Private',
                    color: 'bullet-danger'
                }
            ];
        });

        // Subscribe to Draft and Unread Mail Count
        this._emailService.onDraftCountChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => (this.draftCount = response));

        this._emailService.onUnreadInboxCountChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => (this.unReadInboxCount = response));
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
