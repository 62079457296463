import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StageService} from '../../../shared/services/stage.service';
import {Helper} from '../../../shared/constants/helper';
import {AlertService} from '../../../shared/services/alert.service';
import {SrMappingService} from '../../../shared/services/sr-mapping.service';
import {StudentFollowUpService} from '../../../shared/services/student-follow-up.service';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
    selector: 'app-detail-student',
    templateUrl: './detail-student.component.html',
    styleUrls: ['./detail-student.component.scss']
})
export class DetailStudentComponent implements OnInit {
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input('data') set data(data: any) {
        this.detail(data);
    }
    studentDetail: any;
    submitted: any = false;
    submitting: any = false;
    addFollowUpForm: FormGroup;
    stages = [];
    reasons = [];
    followUpTypes = [
        {
            'name': 'Call',
            'value': 'CALL'
        },
        {
            'name': 'Text Message',
            'value': 'TEXT_MESSAGE'
        },
        {
            'name': 'Whats App',
            'value': 'WHATS_APP'
        }
    ];
    followUpType = 'ADD';
    followUps: any = [];
    currentYear: any = parseInt(moment(new Date()).format('YYYY'));
    currentMonth: any = parseInt(moment(new Date()).format('MM')) - 1;
    currentDate: any = parseInt(moment(new Date()).format('DD'));
    currentHour: any = parseInt(moment(new Date()).format('HH'));
    currentMinute: any = parseInt(moment(new Date()).format('mm'));
    public currentDateTime = new Date(this.currentYear, this.currentMonth, this.currentDate, this.currentHour, this.currentMinute);
    profileImage: any;
    user: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                public stageService: StageService,
                public srMappingService: SrMappingService,
                public alertService: AlertService,
                public studentFollowUpService: StudentFollowUpService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.addFollowUpForm = this.fb.group({
            'subject': ['', Validators.required],
            'stageId': ['', Validators.required],
            'reasonId': ['', Validators.required],
            'followUpType': ['', Validators.required],
            'followUpDateTime': ['', Validators.required],
            'remark': [''],
        });
        this.getStages();
        this.user = StorageService.getItem('self');
    }

    getStages() {
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    getReason(id) {
        if (id) {
            this.reasons = [];
            this.srMappingService.getReasonByStage(id, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.reasons = Helper.uniqByKeepLast(data['data'], it => it.reason_id);
                }
            });
        } else {
            this.alertService.showErrors('Something went wrong, please select program again');
        }
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('detail-student').toggleOpen();
    }

    detail(data) {
        this.studentDetail = data;
        if (data && data['personal_info'] && data['personal_info']['photograph'] && data['personal_info']['photograph'].name) {
            this.profileImage = (data && data['personal_info'] && data['personal_info']['photograph'] && data['personal_info']['photograph'].url) ? data['personal_info']['photograph'].url : '';
        } else {
            this.profileImage = './assets/images/image-backgrounds/1600-1600.jpg';
        }
        if (this.studentDetail && this.studentDetail['follow_ups'] && this.studentDetail['follow_ups'].length > 0) {
            this.followUps = this.studentDetail['follow_ups'];
        }
    }

    followUp(type, content) {
        this.addFollowUpForm.reset();
        this.addFollowUpForm.patchValue({
            'subject': '',
            'stageId': '',
            'reasonId': '',
            'followUpType': '',
            'followUpDateTime': '',
            'remark': '',
        });
        this.followUpType = type;
        this.modalService.open(content, {'size': 'lg'});
    }

    submitFollowUp() {
        this.submitted = true;
        if (!this.addFollowUpForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_id': this.studentDetail.id,
                'subject': (this.addFollowUpForm.value && this.addFollowUpForm.value.subject) ? this.addFollowUpForm.value.subject : '',
                'stage_id': (this.addFollowUpForm.value && this.addFollowUpForm.value.stageId) ? this.addFollowUpForm.value.stageId : '',
                'reason_id': (this.addFollowUpForm.value && this.addFollowUpForm.value.reasonId) ? this.addFollowUpForm.value.reasonId : '',
                'type': (this.addFollowUpForm.value && this.addFollowUpForm.value.followUpType) ? this.addFollowUpForm.value.followUpType : '',
                'datetime': (this.addFollowUpForm.value && moment(this.addFollowUpForm.value.followUpDateTime).format('YYYY-MM-DD hh:mm a')) ? moment(this.addFollowUpForm.value.followUpDateTime).format('YYYY-MM-DD hh:mm a') : '',
                'remark': (this.addFollowUpForm.value && this.addFollowUpForm.value.remark) ? this.addFollowUpForm.value.remark : ''
            };
            this.submitting = true;
            this.studentFollowUpService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.modalService.dismissAll('');
                    this.valueSubmitted.emit(true);
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }
}
