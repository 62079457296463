import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeadCounsellorsRoutingModule} from './head-counsellors-routing.module';
import {HeadCounsellorsComponent} from './head-counsellors.component';
import {AddHeadCounsellorComponent} from './add-head-counsellor/add-head-counsellor.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {UiSwitchModule} from 'ngx-ui-switch';

@NgModule({
    declarations: [HeadCounsellorsComponent, AddHeadCounsellorComponent],
    imports: [
        CommonModule,
        HeadCounsellorsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgSelectModule,
        UiSwitchModule
    ],
    exports: [HeadCounsellorsComponent, AddHeadCounsellorComponent]
})

export class HeadCounsellorsModule {
}
