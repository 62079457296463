<div class="content-wrapper">
    <div class="content-body">
        <section id="pcbmapping">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">PCB Mapping</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Mapping
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="program">
                                        <option value="">Select Program</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="course">
                                        <option value="">Select Course</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="branch">
                                        <option value="">Select Branch</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Program</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Branch</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="pcbmapping && pcbmapping.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let pcbmap of pcbmapping; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{pcbmap?.program?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{pcbmap?.course?.name}}</h6></td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="pcbmap && pcbmap['branch'] && pcbmap['branch'].name">{{pcbmap?.branch?.name}}</h6>
                                                <h6 class="mt-1" *ngIf="!pcbmap || !pcbmap['branch'] || !pcbmap['branch'].name">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(pcbmap)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="In Active Mapping" *ngIf="pcbmap?.status === 'ACTIVE'"
                                                   (click)="updateStatus('INACTIVE', pcbmap)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock-open f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Active Mapping" *ngIf="pcbmap?.status === 'INACTIVE'"
                                                   (click)="updateStatus('ACTIVE', pcbmap)"></i>
                                                <i aria-hidden="true"
                                                   class="fab fa-artstation f_20 text-primary ml-5px cursor-pointer font-weight-light mt-1"
                                                   title="Perform Actions" (click)="courseCurriculum(pcbmap)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="7" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="pcbmapping && pcbmapping.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let pcbmap of pcbmapping; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{pcbmap?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active Executive" *ngIf="pcbmap?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', pcbmap)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active Executive" *ngIf="pcbmap?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', pcbmap)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 ml-1 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(pcbmap)"></i>
                                    <i aria-hidden="true"
                                       class="fab fa-artstation f_20 text-primary cursor-pointer font-weight-light pull-right"
                                       title="Perform Actions" (click)="courseCurriculum(pcbmap)"></i>

                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Program - {{pcbmap?.program?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Course - {{pcbmap?.course?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Branch - {{pcbmap?.branch?.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-pcb-mapping"
            overlayClass="modal-backdrop">
        <app-add-pcb-mapping [data]="data" (valueSubmitted)="getData($event)"></app-add-pcb-mapping>
    </core-sidebar>

</div>
