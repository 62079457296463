import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdmissionRoutingModule} from './admission-routing.module';
import {AdmissionComponent} from './admission.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbCarouselModule, NgbNavModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {GoogleMapsModule} from '@angular/google-maps';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
    declarations: [AdmissionComponent],
    imports: [
        CommonModule,
        AdmissionRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgbCarouselModule,
        NgbNavModule,
        GoogleMapsModule,
        ImageCropperModule
    ]
})

export class AdmissionModule {
}
