import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';
import {UniversityService} from '../../shared/services/university.service';
import {AlertService} from '../../shared/services/alert.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
    selector: 'app-head-counsellors',
    templateUrl: './head-counsellors.component.html',
    styleUrls: ['./head-counsellors.component.scss']
})
export class HeadCounsellorsComponent implements OnInit {
    showSearch = false;
    assignedUniversities: any;
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    modalType: any;
    head_counsellors = [];
    universities: any = [];
    selectedHeadCounsellor: any;
    user: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private headCounsellorService: HeadCounsellorService,
                private universityService: UniversityService,
                private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        this.refresh();
    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        this.get();
        this.getUniversities();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-head-counsellor').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    assignUniversityModal(data, content, type) {
        this.selectedHeadCounsellor = data;
        let assignedUniversities = [];
        if (this.selectedHeadCounsellor && this.selectedHeadCounsellor['head_counsellor_universities'] && this.selectedHeadCounsellor['head_counsellor_universities'].length > 0) {
            this.selectedHeadCounsellor['head_counsellor_universities'].forEach(headCounsellorUniversity => {
                assignedUniversities.push(headCounsellorUniversity['university']);
            });
        }
        this.assignedUniversities = assignedUniversities;
        this.modalType = type;
        this.modalService.open(content, {'size': 'md'});
    }

    updatePasswordModal(content, id, type) {
        this.id = id;
        this.modalType = type;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    getUniversities(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.universities = [];
        this.universityService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.universities = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.universities = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.head_counsellors = [];
        this.headCounsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.head_counsellors = data['data'];
                if (this.head_counsellors && this.head_counsellors.length > 0) {
                    this.head_counsellors.forEach(head_counsellor => {
                        head_counsellor['count'] = head_counsellor['head_counsellor_universities'].length;
                    });
                }
            } else {
                this.head_counsellors = [];
            }
            this.pagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.headCounsellorService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    assignUniversity() {
        if (this.assignedUniversities && this.assignedUniversities.length === 0) {
            this.alertService.showErrors('Please choose atleast one university');
        } else {
            let universityIds = [];
            this.assignedUniversities.forEach(university => {
                universityIds.push(university.id);
            });
            const params = {
                'university_ids': JSON.stringify(universityIds)
            };
            this.headCounsellorService.headCounsellorUniversity(this.selectedHeadCounsellor['user'].id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.headCounsellorService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    counsellorsPage(data: any) {
        this.router.navigateByUrl('head-counsellors/' + data['user'].id + '/counsellors');
    }

    allowUniqueLink(data, e) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'unique_link_status': e ? 'ACTIVE' : 'INACTIVE'
            };
            this.submitting = true;
            this.headCounsellorService.updateUniqueLinkStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }
}
