import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent} from './dashboard.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {OwlDateTimeModule} from 'ng-pick-datetime';

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        NgSelectModule,
        OwlDateTimeModule,
        OwlDateTimeModule,

    ],
    exports: [DashboardComponent]
})

export class DashboardModule {
}
