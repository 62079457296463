<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add Mapping</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update Mapping</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addpcbMappingForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="program">Program</label>
                                        <select class="form-control" id="program" formControlName="program_id">
                                            <option value="">Select Program</option>
                                            <option [value]="program?.id"
                                                    *ngFor="let program of programs">{{program.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addpcbMappingForm.get('program_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="course">Course</label>
                                        <select class="form-control" id="course" formControlName="course_id">
                                            <option value="">Select Course</option>
                                            <option [value]="course?.id"
                                                    *ngFor="let course of courses ">{{course.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addpcbMappingForm.get('course_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="branch">Branch</label>
                                        <select class="form-control" id="branch" formControlName="branch_id">
                                            <option value="">Select Branch</option>
                                            <option [value]="branch?.id"
                                                    *ngFor="let branch of branches">{{branch.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addpcbMappingForm.get('branch_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect  class="btn btn-danger" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add new user Ends-->
</div>

