import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {EmailService} from '../../../shared/services/email.service';
import {Email} from '../email.model';

@Component({
    selector: 'app-mail-details',
    templateUrl: './mail-details.component.html',
    styleUrls: ['./mail-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MailDetailsComponent implements OnInit {
    public openedEmail: Email;
    public isOpen = false;
    public emailAppDetailReplies = false;
    private _unsubscribeAll: Subject<any>;

    constructor(private _emailService: EmailService) {
        this._unsubscribeAll = new Subject();
    }

    openReplies() {
        this.emailAppDetailReplies = true;
    }

    toggleDetails() {
        this._emailService.closeEmailDetails();
        this._emailService.deselectEmails();
    }

    updateFolderOnSelectedMails(folderRef) {
        this._emailService.updateFolderOnSelectedEmails(folderRef);
    }

    updateLabelOnSelectedEmails(labelRef) {
        this._emailService.updateLabelOnSelectedEmails(labelRef);
    }

    unRead() {
        this._emailService.markAsUnread();
    }

    toggleStarred() {
        this._emailService.toggleStarred(this.openedEmail);
    }

    ngOnInit(): void {
        // Subscribe to Email Close
        this._emailService.onEmailDetailChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
            setTimeout(() => {
                this.isOpen = response;
                // this._emailService.deselectEmails();
            });
        });

        // Subscribe to Email Open & Details
        this._emailService.onOpenedEmailChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(email => {
            this.openedEmail = email;
            if (Object.keys(this.openedEmail).length > 0) {
                this.isOpen = true;
                this._emailService.setOpenedEmail(this.openedEmail);
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
