import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StudentsComponent} from './students.component';
import {AddStudentComponent} from './add-student/add-student.component';

const routes: Routes = [
    {
        path: 'students',
        component: StudentsComponent,
        data: {animation: 'students'}
    },
    {
        path: 'student/add',
        component: AddStudentComponent,
        data: {animation: 'student/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class StudentsRoutingModule {
}
