import {Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-curriculum',
  templateUrl: './course-curriculum.component.html',
  styleUrls: ['./course-curriculum.component.scss']
})
export class CourseCurriculumComponent implements OnInit {
  showSearch = false;
  constructor() {}

  ngOnInit(): void {
  }

}
