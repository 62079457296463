<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title"> Update Password</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="updatePasswordForm">
                        <div class="container">
                            <div class="row" *ngIf="!data || !data.id">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <label for="currentPassword" class="d-flex">
                                            Current Password
                                            <i (click)="inputTypeCurrentPassword = 'text'"
                                               [ngClass]="{'display-hidden': (inputTypeCurrentPassword === 'text')}"
                                               class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                            <i (click)="inputTypeCurrentPassword = 'Password'"
                                               [ngClass]="{'display-hidden': (inputTypeCurrentPassword === 'Password')}"
                                               class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                        </label>
                                        <input class="form-control" id="currentPassword" [type]="inputTypeCurrentPassword"
                                               formControlName="currentPassword">
                                        <small *ngIf="submitted && updatePasswordForm.get('currentPassword').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!data || !data.id">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <label for="newPassword" class="d-flex">
                                            New Password
                                            <i (click)="inputTypeNewPassword = 'text'"
                                               [ngClass]="{'display-hidden': (inputTypeNewPassword === 'text')}"
                                               class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                            <i (click)="inputTypeNewPassword = 'Password'"
                                               [ngClass]="{'display-hidden': (inputTypeNewPassword === 'Password')}"
                                               class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                        </label>
                                        <input class="form-control" id="newPassword" [type]="inputTypeNewPassword"
                                               formControlName="newPassword">
                                        <small *ngIf="submitted && updatePasswordForm.get('newPassword').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!data || !data.id">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <label for="confirmPassword" class="d-flex">
                                            Confirm Password
                                            <i (click)="inputTypeConfirmNewPassword = 'text'"
                                               [ngClass]="{'display-hidden': (inputTypeConfirmNewPassword === 'text')}"
                                               class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                            <i (click)="inputTypeConfirmNewPassword = 'Password'"
                                               [ngClass]="{'display-hidden': (inputTypeConfirmNewPassword === 'Password')}"
                                               class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                        </label>
                                        <input class="form-control" id="confirmPassword" [type]="inputTypeConfirmNewPassword"
                                               formControlName="confirmPassword">
                                        <small *ngIf="submitted && updatePasswordForm.get('confirmPassword').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <button type="reset" rippleEffect class="btn btn-danger" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="updatePassword()">
                                        <i class="fas fa-user mr-1" *ngIf="!submitting"></i>
                                        <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
