import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {ProgramService} from '../../../shared/services/program.service';

@Component({
    selector: 'app-add-program',
    templateUrl: './add-program.component.html',
    styleUrls: ['./add-program.component.scss']
})
export class AddProgramComponent implements OnChanges {
    addProgramForm: FormGroup;
    id: any;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                public programService: ProgramService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-program').toggleOpen();
    }

    refresh() {
        this.addProgramForm = this.fb.group({
            'name': ['', Validators.required]
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addProgramForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addProgramForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addProgramForm.value && this.addProgramForm.value.name) ? this.addProgramForm.value.name : ''
            };
            this.submitting = true;
            this.programService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addProgramForm.reset();
                    this.addProgramForm.patchValue({
                        'name': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addProgramForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addProgramForm.value && this.addProgramForm.value.name) ? this.addProgramForm.value.name : '',
                };
            this.submitting = true;
            this.programService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }
}
