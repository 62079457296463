import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {SrMappingService} from '../../../shared/services/sr-mapping.service';
import {StageService} from '../../../shared/services/stage.service';
import {ReasonService} from '../../../shared/services/reason.service';

@Component({
    selector: 'app-add-sr-mapping',
    templateUrl: './add-sr-mapping.component.html',
    styleUrls: ['./add-sr-mapping.component.scss']
})
export class AddSrMappingComponent implements OnChanges {
    addSRMappingForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    stages = [];
    reasons = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private srmappingService: SrMappingService,
                public stageService: StageService,
                public reasonService: ReasonService) {
        this.getStages();
        this.getReasons();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-sr-mapping').toggleOpen();
    }

    refresh() {
        this.addSRMappingForm = this.fb.group({
            'stage_id': ['', Validators.required],
            'reason_id': ['', Validators.required]
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addSRMappingForm.patchValue({
            'stage_id': (this.data && this.data['stage_id']) ? this.data['stage_id'] : '',
            'reason_id': (this.data && this.data['reason_id']) ? this.data['reason_id'] : ''
        });
    }

    getStages() {
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    getReasons() {
        this.reasonService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.reasons = data['data'];
            }
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addSRMappingForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'stage_id': (this.addSRMappingForm.value && this.addSRMappingForm.value.stage_id) ? this.addSRMappingForm.value.stage_id : '',
                'reason_id': (this.addSRMappingForm.value && this.addSRMappingForm.value.reason_id) ? this.addSRMappingForm.value.reason_id : '',
            };
            this.submitting = true;
            this.srmappingService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addSRMappingForm.reset();
                    this.addSRMappingForm.patchValue({
                        'stage_id': '',
                        'reason_id': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addSRMappingForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'stage_id': (this.addSRMappingForm.value && this.addSRMappingForm.value.stage_id) ? this.addSRMappingForm.value.stage_id : '',
                'reason_id': (this.addSRMappingForm.value && this.addSRMappingForm.value.reason_id) ? this.addSRMappingForm.value.reason_id : '',
            };
            this.submitting = true;
            this.srmappingService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }
}
