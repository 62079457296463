import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/services/auth.service';
import {AlertService} from '../../../shared/services/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-my-profile',
    templateUrl: './add-my-profile.component.html',
    styleUrls: ['./add-my-profile.component.scss']
})
export class AddMyProfileComponent implements OnChanges {
    updatePasswordForm: FormGroup;
    submitted:any= false;
    submitting: any = false;
    inputTypeCurrentPassword: any = 'Password';
    inputTypeNewPassword: any = 'Password';
    inputTypeConfirmNewPassword: any = 'Password';
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;


    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private authService: AuthService,
                private alertService: AlertService,
                private modalService: NgbModal) {
    }

    ngOnChanges(): void {
        this.refresh();
    }
    refresh() {
        this.updatePasswordForm = this.fb.group({
            'currentPassword': ['', Validators.required],
            'newPassword': ['', Validators.required],
            'confirmPassword': ['', Validators.required]
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-my-profile').toggleOpen();
    }


    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.updatePasswordForm.value.confirmPassword !== this.updatePasswordForm.value.newPassword) {
            this.alertService.showErrors('New Password and Confirm Password didn\'t match');
            this.submitted = false;
            return;
        }
        if (this.submitted) {
            const params = {
                'currentPassword': (this.updatePasswordForm.value && this.updatePasswordForm.value.currentPassword) ? this.updatePasswordForm.value.currentPassword : '',
                'newPassword': (this.updatePasswordForm.value && this.updatePasswordForm.value.newPassword) ? this.updatePasswordForm.value.newPassword : ''
            };
            this.submitting = true;
            this.authService.updateSelfPassword(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updatePasswordForm.patchValue({
                    'currentPassword': '',
                    'newPassword': '',
                    'confirmPassword': ''
                });
                this.toggleSidebar();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error']) {
                    if (error['error'].errorDetails) {
                        this.alertService.showErrors(error['error'].errorDetails);
                        return;
                    }
                }
            });
        }
    }
}
