import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HeadCounsellorsComponent} from './head-counsellors.component';
import {AddHeadCounsellorComponent} from './add-head-counsellor/add-head-counsellor.component';
import {CounsellorsComponent} from '../counsellors/counsellors.component';

const routes: Routes = [
    {
        path: 'head-counsellors',
        component: HeadCounsellorsComponent,
        data: {animation: 'head-counsellors'}
    },
    {
        path: 'head-counsellor/add',
        component: AddHeadCounsellorComponent,
        data: {animation: 'head-counsellor/add'}
    },
    {
        path: 'head-counsellors/:id/counsellors',
        component: CounsellorsComponent,
        data: {animation: 'head-counsellors/:id/counsellors'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class HeadCounsellorsRoutingModule {
}
