import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProgramsComponent} from '../programs/programs.component';
import {AddProgramComponent} from '../programs/add-program/add-program.component';
import {StagesComponent} from './stages.component';
import {AddStageComponent} from './add-stage/add-stage.component';

const routes: Routes = [
    {
        path: 'stages',
        component: StagesComponent,
        data: {animation: 'stage'}
    },
    {
        path: 'stage/add',
        component: AddStageComponent,
        data: {animation: 'stage/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StagesRoutingModule {
}
