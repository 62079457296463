<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add User</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update User</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addWhatsAppForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="student">Student</label>
                                        <input class="form-control" id="student" type="text" formControlName="student">
                                        <small *ngIf="submitted && addWhatsAppForm.get('student').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="university">University</label>
                                        <input class="form-control" id="university" type="text"
                                               formControlName="university">
                                        <small *ngIf="submitted && addWhatsAppForm.get('university').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="message">Message</label>
                                        <input class="form-control" id="message" type="text" formControlName="message">
                                        <small *ngIf="submitted && addWhatsAppForm.get('message').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect  class="btn btn-danger" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>


