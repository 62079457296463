<div class="content-wrapper">
    <div class="content-body">
        <section id="whatsApps">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">WhatsApp Users</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add User
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="student"
                                           placeholder="Student">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="university"
                                           placeholder="University">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="message"
                                           placeholder="Message">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Student</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                University</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Message</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="whatsApps && whatsApps.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let whatsapp of whatsApps; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{whatsapp?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{whatsapp?.username}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{whatsapp?.university}}</h6></td>
                                            <td class="text-center"><h6 class="">
                                                <i aria-hidden="true"
                                                   class="fas fa-envelope f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="View Message" (click)="edit(whatsapp)"></i>
                                            </h6></td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="7" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="whatsApps && whatsApps.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let whatsapp of whatsApps; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{whatsapp?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(whatsapp)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                   student - {{whatsapp?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    University - {{whatsapp?.username}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                   Message - {{whatsapp?.university}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination class="pagination-danger" [collectionSize]="pagination.total"
                                [(page)]="pagination.page" [boundaryLinks]="true"
                                [pageSize]="pagination.perpage" [maxSize]="5"
                                (pageChange)="onPageChange($event)">
                    <ng-template> ngbPaginationPrevious>
                        <span [data-feather]="'chevron-left'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                    <ng-template> ngbPaginationNext>
                        <span [data-feather]="'chevron-right'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-whatsapp"
            overlayClass="modal-backdrop">
        <app-add-whatsapp [data]="data" (valueSubmitted)="getData($event)"></app-add-whatsapp>
    </core-sidebar>
</div>


