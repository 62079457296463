import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SrMappingComponent} from './sr-mapping.component';
import {AddSrMappingComponent} from './add-sr-mapping/add-sr-mapping.component';

const routes: Routes = [
  {
    path: 'sr-mapping',
    component: SrMappingComponent,
    data: {animation: 'sr-mapping'}
  },
  {
    path: 'sr-mapping/add',
    component: AddSrMappingComponent,
    data: {animation: 'sr-mapping/add'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SrMappingRoutingModule { }
