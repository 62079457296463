import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StagesRoutingModule} from './stages-routing.module';
import {StagesComponent} from './stages.component';
import {AddStageComponent} from './add-stage/add-stage.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [StagesComponent, AddStageComponent],
    imports: [
        CommonModule,
        StagesRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule
    ],
    exports: [StagesComponent, AddStageComponent]
})
export class StagesModule {
}
