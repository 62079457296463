<section>
    <div class="content-area-wrapper">
        <core-sidebar
                class="sidebar-left"
                name="email-sidebar"
                overlayClass="body-content-overlay"
                collapsibleSidebar="bs-gt-lg"
        >
            <app-mail-sidebar></app-mail-sidebar>
        </core-sidebar>
        <div class="content-right">
            <app-mail-compose></app-mail-compose>
            <div class="content-wrapper">
                <div class="content-body">
                    <app-mail-list></app-mail-list>
                    <app-mail-details></app-mail-details>
                </div>
            </div>
        </div>
    </div>
</section>
