import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextMessagesRoutingModule} from './text-messages-routing.module';
import {TextMessagesComponent} from './text-messages.component';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {AdminsModule} from '../admins/admins.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import { AddTextmessageComponent } from './add-textmessage/add-textmessage.component';

@NgModule({
    declarations: [TextMessagesComponent, AddTextmessageComponent, ],
    imports: [
        CommonModule,
        TextMessagesRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgSelectModule,
        AdminsModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule
    ],
    exports: [TextMessagesComponent,AddTextmessageComponent, ]
})

export class TextMessagesModule {
}
