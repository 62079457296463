import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../../shared/services/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CourseService} from '../../../shared/services/course.service';
import {StudentService} from '../../../shared/services/student.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniversityService} from '../../../shared/services/university.service';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {CoreConfigService} from '../../../../@core/services/config.service';
import {StateService} from '../../../shared/services/state.service';
// @ts-ignore
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {LandingPageSliderService} from '../../../shared/services/landing-page-slider.service';
import {FileService} from '../../../shared/services/file.service';
import {AlertService} from '../../../shared/services/alert.service';
import {LandingPageAboutUsService} from '../../../shared/services/landing-page-about-us.service';
import {LandingPageCourseService} from '../../../shared/services/landing-page-course.service';
import {LandingPageWhyUsService} from '../../../shared/services/landing-page-why-us.service';
import {LandingPageContactUsService} from '../../../shared/services/landing-page-contact-us.service';

@Component({
    selector: 'app-university-landing',
    templateUrl: './university-landing.component.html',
    styleUrls: ['./university-landing.component.scss']
})
export class UniversityLandingComponent implements OnInit {
    private _unsubscribeAll: Subject<any>;
    contentHeader: object;
    universityLandingModalForm: FormGroup;
    user: any;
    landingSliders: any = {
        one: 'assets/images/image-backgrounds/1600-800.png',
        two: 'assets/images/image-backgrounds/1600-800.png',
        three: 'assets/images/image-backgrounds/1600-800.png',
        four: 'assets/images/image-backgrounds/1600-800.png',
        five: 'assets/images/image-backgrounds/1600-800.png',
        six: 'assets/images/image-backgrounds/1600-800.png'
    };
    aboutUsImage: any = 'assets/images/image-backgrounds/500-300.png';
    aboutUsText: any = 'Text about univeristy, will appear here.';
    addStudentForm: FormGroup;
    universityId: any;
    courses: [];
    modalType: any;
    baseUrl = environment.appUrl;
    contactUsEdit: any = false;
    whyUsEdit: any = false;
    coursesEdit: any = false;
    aboutUsEdit: any = false;
    states: any = [];
    aboutLandingForm: FormGroup;
    whyUsLandingForm: FormGroup;
    contactLandingForm: FormGroup;
    courseLandingForm: FormGroup;
    imageLandingChangedEvent: any;
    imageAboutUsChangedEvent: any;
    imageCourse1ChangedEvent: any;
    imageCourse2ChangedEvent: any;
    imageCourse3ChangedEvent: any;
    imageCourse4ChangedEvent: any;
    imageCourse5ChangedEvent: any;
    imageCourse6ChangedEvent: any;
    imageWhyUs1ChangedEvent: any;
    imageWhyUs2ChangedEvent: any;
    imageWhyUs3ChangedEvent: any;
    imageWhyUs4ChangedEvent: any;
    croppedLandingImage: any;
    croppedLandingImageEvent: any;
    croppedAboutUsImage: any;
    croppedAboutUsImageEvent: any;
    croppedCourseImage: any;
    croppedCourseImageEvent: any;
    croppedWhyUsImage: any;
    croppedWhyUsImageEvent: any;
    slider1Data: any;
    slider2Data: any;
    slider3Data: any;
    slider4Data: any;
    slider5Data: any;
    slider6Data: any;
    course1Data: any = {
        'name': 'Course 1',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Course 1 Info'
    };
    course2Data: any = {
        'name': 'Course 2',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Course 2 Info'
    };
    course3Data: any = {
        'name': 'Course 3',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Course 3 Info'
    };
    course4Data: any = {
        'name': 'Course 4',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Course 4 Info'
    };
    course5Data: any = {
        'name': 'Course 5',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Course 5 Info'
    };
    course6Data: any = {
        'name': 'Course 6',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Course 6 Info'
    };
    whyUs1Data: any = {
        'name': 'Why Us 1',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Why Us 1 Info'
    };
    whyUs2Data: any = {
        'name': 'Why Us 2',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Why Us 2 Info'
    };
    whyUs3Data: any = {
        'name': 'Why Us 3',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Why Us 3 Info'
    };
    whyUs4Data: any = {
        'name': 'Why Us 4',
        'image': 'assets/images/image-backgrounds/500-300.png',
        'info': 'Why Us 4 Info'
    };
    aboutUsImageData: any;
    aboutUsData: any;
    updateCourseData: any;
    updateWhyUsData: any;
    updateContactUsData: any;
    mapUrl: any = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.536812738167!2d77.10620371551856!3d28.613669191684483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0354b155556d%3A0xea671f4267872ff9!2sSHIKSHA%20BHARTI%20PRAKASHAN%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1624367848692!5m2!1sen!2sin';
    contactUsData: any = {
        'address': 'University Address',
        'district': 'University District',
        'state_id': 1,
        'pin_code': 'University Postal Code',
        'state': {'id': 10, 'name': 'University State'},
        // 'location': 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.536812738167!2d77.10620371551856!3d28.613669191684483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0354b155556d%3A0xea671f4267872ff9!2sSHIKSHA%20BHARTI%20PRAKASHAN%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1624367848692!5m2!1sen!2sin',
        'contact': 'University Contact Number'
    };
    type: any;

    constructor(public _coreConfigService: CoreConfigService,
                private router: Router,
                private fb: FormBuilder,
                private courseService: CourseService,
                private studentService: StudentService,
                private universityService: UniversityService,
                private stateService: StateService,
                public modalService: NgbModal,
                public landingPageSliderService: LandingPageSliderService,
                public landingPageAboutUsService: LandingPageAboutUsService,
                public landingPageCourseService: LandingPageCourseService,
                public landingPageWhyUsService: LandingPageWhyUsService,
                public landingPageContactUsService: LandingPageContactUsService,
                public alertService: AlertService,
                public fileService: FileService,
                public activatedRoute: ActivatedRoute) {
        this.user = StorageService.getItem('self');
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.universityId = this.activatedRoute.snapshot.params['id'];
        this.user = StorageService.getItem('self');
        if (!this.universityId && this.user['user_type'] !== 'UNIVERSITY') {
            this.router.navigateByUrl('dashboard');
        }
        this.getStates();
        this.refresh();
    }

    refresh() {
        this.universityLandingModalForm = this.fb.group({
            'model': ['', Validators.required],
        });

        this.addStudentForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.required],
            'contact': ['', Validators.required],
            'course_id': ['', Validators.required],
        });

        this.aboutLandingForm = this.fb.group({
            'about_us_image': [''],
            'about_us': ['']
        });

        this.courseLandingForm = this.fb.group({
            'course_1_image': [''],
            'course_1_name': [''],
            'course_1_info': [''],
            'course_2_image': [''],
            'course_2_name': [''],
            'course_2_info': [''],
            'course_3_image': [''],
            'course_3_name': [''],
            'course_3_info': [''],
            'course_4_image': [''],
            'course_4_name': [''],
            'course_4_info': [''],
            'course_5_image': [''],
            'course_5_name': [''],
            'course_5_info': [''],
            'course_6_image': [''],
            'course_6_name': [''],
            'course_6_info': [''],
        });

        this.whyUsLandingForm = this.fb.group({
            'why_us_1_tab_name': [''],
            'why_us_1_image': [''],
            'why_us_1_info': [''],
            'why_us_2_tab_name': [''],
            'why_us_2_image': [''],
            'why_us_2_info': [''],
            'why_us_3_tab_name': [''],
            'why_us_3_image': [''],
            'why_us_3_info': [''],
            'why_us_4_tab_name': [''],
            'why_us_4_image': [''],
            'why_us_4_info': ['']
        });

        this.contactLandingForm = this.fb.group({
            'address': [''],
            'district': [''],
            'state_id': [''],
            'pin_code': [''],
            'contact_us': [''],
            'location': ['']
        });

        this.getUniversityDetail();
        this.getLandingAboutUs();
        this.getLandingCourses();
        this.getLandingWhyUs();
        this.getLandingContactUs();
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    getUniversityDetail() {
        this.universityService.detail(this.universityId).subscribe(data => {
            if (data) {
                this.contentHeader = {
                    headerTitle: 'University Landing',
                    actionButton: false,
                    breadcrumb: {
                        type: '',
                        links: [
                            {
                                name: 'Universities',
                                isLink: true,
                                link: '/'
                            },
                            {
                                name: data.name,
                                isLink: false
                            }
                        ]
                    }
                };
                this.getCourses();
                this.getLandingSliders();
            }
        });
    }

    getCourses(params: any = {}) {
        if (this.universityId) {
            this.courseService.getByAdmin(this.universityId, params).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
            });
        } else {
            this.courseService.get(params).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
            });
        }
    }

    edit(content, type) {
        this.modalType = type;
        this.modalService.open(content,
            {
                centered: true,
                size: 'lg'
            });
    }

    submit() {
        const params = {
            'university_id': this.universityId,
            'name': (this.addStudentForm.value && this.addStudentForm.value.name) ? this.addStudentForm.value.name : '',
            'email': (this.addStudentForm.value && this.addStudentForm.value.email) ? this.addStudentForm.value.email : '',
            'contact': (this.addStudentForm.value && this.addStudentForm.value.contact) ? this.addStudentForm.value.contact : '',
            'course_id': (this.addStudentForm.value && this.addStudentForm.value.course_id) ? this.addStudentForm.value.course_id : '',
        };
        this.studentService.studentRegister(params).subscribe(data => {
            if (data) {
                this.addStudentForm.reset();
                this.addStudentForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'course_id': ''
                });
            }
        });
    }

    editOpen(type) {
        if (type === 'CONTACT_US') {
            this.contactUsEdit = !this.contactUsEdit;
        } else if (type === 'WHY_US') {
            this.whyUsEdit = !this.whyUsEdit;
        } else if (type === 'COURSES') {
            this.coursesEdit = !this.coursesEdit;
        } else if (type === 'ABOUT_US') {
            this.aboutUsEdit = !this.aboutUsEdit;
        }
    }

    openLandingModal(content, type) {
        this.type = type;
        this.modalService.open(content, {'size': 'xl'});
    }

    onImageChange(e, type) {
        if (type === 'SLIDER_1' || type === 'SLIDER_2' || type === 'SLIDER_3' || type === 'SLIDER_4' || type === 'SLIDER_5' || type === 'SLIDER_6') {
            this.imageLandingChangedEvent = e;
        } else if (type === 'ABOUT_US_LANDING_PAGE') {
            this.imageAboutUsChangedEvent = e;
        } else if (type === 'COURSE_1_LANDING_PAGE') {
            this.imageCourse1ChangedEvent = e;
        } else if (type === 'COURSE_2_LANDING_PAGE') {
            this.imageCourse2ChangedEvent = e;
        } else if (type === 'COURSE_3_LANDING_PAGE') {
            this.imageCourse3ChangedEvent = e;
        } else if (type === 'COURSE_4_LANDING_PAGE') {
            this.imageCourse4ChangedEvent = e;
        } else if (type === 'COURSE_5_LANDING_PAGE') {
            this.imageCourse5ChangedEvent = e;
        } else if (type === 'COURSE_6_LANDING_PAGE') {
            this.imageCourse6ChangedEvent = e;
        } else if (type === 'WHY_US_1_LANDING_PAGE') {
            this.imageWhyUs1ChangedEvent = e;
        } else if (type === 'WHY_US_2_LANDING_PAGE') {
            this.imageWhyUs2ChangedEvent = e;
        } else if (type === 'WHY_US_3_LANDING_PAGE') {
            this.imageWhyUs3ChangedEvent = e;
        } else if (type === 'WHY_US_4_LANDING_PAGE') {
            this.imageWhyUs4ChangedEvent = e;
        }
    }

    imageCropped(type, event: ImageCroppedEvent) {
        if (type === 'SLIDE_LANDING') {
            this.croppedLandingImage = event.base64;
            this.croppedLandingImageEvent = event;
        } else if (type === 'ABOUT_US_LANDING') {
            this.croppedAboutUsImage = event.base64;
            this.croppedAboutUsImageEvent = event;
        } else if (type === 'COURSE_LANDING') {
            this.croppedCourseImage = event.base64;
            this.croppedCourseImageEvent = event;
        } else if (type === 'WHY_US_LANDING') {
            this.croppedWhyUsImage = event.base64;
            this.croppedWhyUsImageEvent = event;
        }
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    saveLandingImage(type) {
        let typeData = type;
        let cropImage: any;
        if (type === 'SLIDER_1' || type === 'SLIDER_2' || type === 'SLIDER_3' || type === 'SLIDER_4' || type === 'SLIDER_5' || type === 'SLIDER_6') {
            typeData = 'LANDING_SLIDER';
            cropImage = this.croppedLandingImage;
        } else if (type === 'ABOUT_US_LANDING') {
            typeData = 'LANDING_ABOUT_US';
            cropImage = this.croppedAboutUsImage;
        } else if (type === 'COURSE_1_LANDING' || type === 'COURSE_2_LANDING' || type === 'COURSE_3_LANDING' || type === 'COURSE_4_LANDING' || type === 'COURSE_5_LANDING' || type === 'COURSE_6_LANDING') {
            typeData = 'LANDING_COURSE';
            cropImage = this.croppedCourseImage;
        } else if (type === 'WHY_US_1_LANDING' || type === 'WHY_US_2_LANDING' || type === 'WHY_US_3_LANDING' || type === 'WHY_US_4_LANDING') {
            typeData = 'LANDING_WHY_US';
            cropImage = this.croppedWhyUsImage;
        }
        const params = {
            'type': typeData,
            'user_id': this.user.userId,
            'fileType': 'BASE64'
        };
        this.fileService.fileUpload(params, cropImage).subscribe(data => {
            if (data) {
                if (typeData === 'LANDING_SLIDER') {
                    this.modalService.dismissAll('');
                    this.imageLandingChangedEvent = undefined;
                    let landingSliderId: any = undefined;
                    if (type === 'SLIDER_1' && this.slider1Data) {
                        landingSliderId = this.slider1Data.id;
                    } else if (type === 'SLIDER_2' && this.slider2Data) {
                        landingSliderId = this.slider2Data.id;
                    } else if (type === 'SLIDER_3' && this.slider3Data) {
                        landingSliderId = this.slider3Data.id;
                    } else if (type === 'SLIDER_4' && this.slider4Data) {
                        landingSliderId = this.slider4Data.id;
                    } else if (type === 'SLIDER_5' && this.slider5Data) {
                        landingSliderId = this.slider5Data.id;
                    } else if (type === 'SLIDER_6' && this.slider6Data) {
                        landingSliderId = this.slider6Data.id;
                    }
                    if (landingSliderId) {
                        this.updateLandingSlider(landingSliderId, {
                            'type': type,
                            'university_id': this.user.userId,
                            'file_id': data['data'].id
                        });
                    } else {
                        this.createLandingSlider({'type': type, 'university_id': this.user.userId, 'file_id': data['data'].id});
                    }
                } else if (typeData === 'LANDING_ABOUT_US') {
                    this.aboutUsImageData = data['data'];
                    this.aboutLandingForm.patchValue({
                        'about_us_image': (data && data['data'] && data['data'].id) ? data['data'].id : ''
                    });
                    this.alertService.showSuccess({
                        'title': 'success',
                        'message': 'Image successfully loaded, Click update button to upload'
                    });
                } else if (typeData === 'LANDING_COURSE') {
                    if (type === 'COURSE_1_LANDING') {
                        this.courseLandingForm.patchValue({
                            'course_1_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    } else if (type === 'COURSE_2_LANDING') {
                        this.courseLandingForm.patchValue({
                            'course_2_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    } else if (type === 'COURSE_3_LANDING') {
                        this.courseLandingForm.patchValue({
                            'course_3_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    } else if (type === 'COURSE_4_LANDING') {
                        this.courseLandingForm.patchValue({
                            'course_4_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    } else if (type === 'COURSE_5_LANDING') {
                        this.courseLandingForm.patchValue({
                            'course_5_image': (data && data['data'] && data['data'].id) ? data['data'].id : ''
                        });
                    } else if (type === 'COURSE_6_LANDING') {
                        this.courseLandingForm.patchValue({
                            'course_6_image': (data && data['data'] && data['data'].id) ? data['data'].id : ''
                        });
                    }
                    this.alertService.showSuccess({
                        'title': 'success',
                        'message': 'Image successfully loaded, Click update button to upload'
                    });
                } else if (typeData === 'LANDING_WHY_US') {
                    if (type === 'WHY_US_1_LANDING') {
                        this.whyUsLandingForm.patchValue({
                            'why_us_1_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    } else if (type === 'WHY_US_2_LANDING') {
                        this.whyUsLandingForm.patchValue({
                            'why_us_2_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    } else if (type === 'WHY_US_3_LANDING') {
                        this.whyUsLandingForm.patchValue({
                            'why_us_3_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    } else if (type === 'WHY_US_4_LANDING') {
                        this.whyUsLandingForm.patchValue({
                            'why_us_4_image': (data && data['data'] && data['data'].id) ? data['data'].id : '',
                        });
                    }
                    this.alertService.showSuccess({
                        'title': 'success',
                        'message': 'Image successfully loaded, Click update button to upload'
                    });
                }
            }
        });
    }

    createLandingSlider(data) {
        this.landingPageSliderService.create(data).subscribe(data => {
            if (data) {
                this.getLandingSliders();
            }
        });
    }

    updateLandingSlider(id, data) {
        this.landingPageSliderService.update(id, data).subscribe(data => {
            this.getLandingSliders();
        });
    }

    update(type) {
        if (type === 'ABOUT_US') {
            const params = {
                'university_id': this.user.userId,
                'file_id': (this.aboutLandingForm.value && this.aboutLandingForm.value.about_us_image) ? this.aboutLandingForm.value.about_us_image : '',
                'about': (this.aboutLandingForm.value && this.aboutLandingForm.value.about_us) ? this.aboutLandingForm.value.about_us : ''
            };
            if (this.aboutUsData) {
                this.landingPageAboutUsService.update(this.aboutUsData['data'].id, params).subscribe(data => {
                    this.imageAboutUsChangedEvent = undefined;
                    this.getLandingAboutUs();
                });
            } else {
                this.landingPageAboutUsService.create(params).subscribe(data => {
                    if (data) {
                        this.imageAboutUsChangedEvent = undefined;
                        this.getLandingAboutUs();
                    }
                });
            }
        } else if (type === 'COURSE') {
            const params = {
                'university_id': this.user.userId,
                'course_1_file_id': (this.courseLandingForm.value && this.courseLandingForm.value.course_1_image) ? this.courseLandingForm.value.course_1_image : '',
                'course_1_name': (this.courseLandingForm.value && this.courseLandingForm.value.course_1_name) ? this.courseLandingForm.value.course_1_name : '',
                'course_1_info': (this.courseLandingForm.value && this.courseLandingForm.value.course_1_info) ? this.courseLandingForm.value.course_1_info : '',
                'course_2_file_id': (this.courseLandingForm.value && this.courseLandingForm.value.course_2_image) ? this.courseLandingForm.value.course_2_image : '',
                'course_2_name': (this.courseLandingForm.value && this.courseLandingForm.value.course_2_name) ? this.courseLandingForm.value.course_2_name : '',
                'course_2_info': (this.courseLandingForm.value && this.courseLandingForm.value.course_2_info) ? this.courseLandingForm.value.course_2_info : '',
                'course_3_file_id': (this.courseLandingForm.value && this.courseLandingForm.value.course_3_image) ? this.courseLandingForm.value.course_3_image : '',
                'course_3_name': (this.courseLandingForm.value && this.courseLandingForm.value.course_3_name) ? this.courseLandingForm.value.course_3_name : '',
                'course_3_info': (this.courseLandingForm.value && this.courseLandingForm.value.course_3_info) ? this.courseLandingForm.value.course_3_info : '',
                'course_4_file_id': (this.courseLandingForm.value && this.courseLandingForm.value.course_4_image) ? this.courseLandingForm.value.course_4_image : '',
                'course_4_name': (this.courseLandingForm.value && this.courseLandingForm.value.course_4_name) ? this.courseLandingForm.value.course_4_name : '',
                'course_4_info': (this.courseLandingForm.value && this.courseLandingForm.value.course_4_info) ? this.courseLandingForm.value.course_4_info : '',
                'course_5_file_id': (this.courseLandingForm.value && this.courseLandingForm.value.course_5_image) ? this.courseLandingForm.value.course_5_image : '',
                'course_5_name': (this.courseLandingForm.value && this.courseLandingForm.value.course_5_name) ? this.courseLandingForm.value.course_5_name : '',
                'course_5_info': (this.courseLandingForm.value && this.courseLandingForm.value.course_5_info) ? this.courseLandingForm.value.course_5_info : '',
                'course_6_file_id': (this.courseLandingForm.value && this.courseLandingForm.value.course_6_image) ? this.courseLandingForm.value.course_6_image : '',
                'course_6_name': (this.courseLandingForm.value && this.courseLandingForm.value.course_6_name) ? this.courseLandingForm.value.course_6_name : '',
                'course_6_info': (this.courseLandingForm.value && this.courseLandingForm.value.course_6_info) ? this.courseLandingForm.value.course_6_info : ''
            };
            if (this.updateCourseData && this.updateCourseData.id) {
                this.landingPageCourseService.update(this.updateCourseData.id, params).subscribe(data => {
                    this.imageCourse1ChangedEvent = undefined;
                    this.imageCourse2ChangedEvent = undefined;
                    this.imageCourse3ChangedEvent = undefined;
                    this.imageCourse4ChangedEvent = undefined;
                    this.imageCourse5ChangedEvent = undefined;
                    this.imageCourse6ChangedEvent = undefined;
                    this.getLandingCourses();
                });
            } else {
                this.landingPageCourseService.create(params).subscribe(data => {
                    if (data) {
                        this.imageCourse1ChangedEvent = undefined;
                        this.imageCourse2ChangedEvent = undefined;
                        this.imageCourse3ChangedEvent = undefined;
                        this.imageCourse4ChangedEvent = undefined;
                        this.imageCourse5ChangedEvent = undefined;
                        this.imageCourse6ChangedEvent = undefined;
                        this.getLandingCourses();
                    }
                });
            }
        } else if (type === 'WHY_US') {
            const params = {
                'university_id': this.user.userId,
                'why_us_1_file_id': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_1_image) ? this.whyUsLandingForm.value.why_us_1_image : '',
                'why_us_1_name': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_1_tab_name) ? this.whyUsLandingForm.value.why_us_1_tab_name : '',
                'why_us_1_info': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_1_info) ? this.whyUsLandingForm.value.why_us_1_info : '',
                'why_us_2_file_id': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_2_image) ? this.whyUsLandingForm.value.why_us_2_image : '',
                'why_us_2_name': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_2_tab_name) ? this.whyUsLandingForm.value.why_us_2_tab_name : '',
                'why_us_2_info': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_2_info) ? this.whyUsLandingForm.value.why_us_2_info : '',
                'why_us_3_file_id': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_3_image) ? this.whyUsLandingForm.value.why_us_3_image : '',
                'why_us_3_name': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_3_tab_name) ? this.whyUsLandingForm.value.why_us_3_tab_name : '',
                'why_us_3_info': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_3_info) ? this.whyUsLandingForm.value.why_us_3_info : '',
                'why_us_4_file_id': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_4_image) ? this.whyUsLandingForm.value.why_us_4_image : '',
                'why_us_4_name': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_4_tab_name) ? this.whyUsLandingForm.value.why_us_4_tab_name : '',
                'why_us_4_info': (this.whyUsLandingForm.value && this.whyUsLandingForm.value.why_us_4_info) ? this.whyUsLandingForm.value.why_us_4_info : ''
            };
            if (this.updateWhyUsData && this.updateWhyUsData.id) {
                this.landingPageWhyUsService.update(this.updateWhyUsData.id, params).subscribe(data => {
                    this.imageWhyUs1ChangedEvent = undefined;
                    this.imageWhyUs2ChangedEvent = undefined;
                    this.imageWhyUs3ChangedEvent = undefined;
                    this.imageWhyUs4ChangedEvent = undefined;
                    this.getLandingWhyUs();
                });
            } else {
                this.landingPageWhyUsService.create(params).subscribe(data => {
                    if (data) {
                        this.imageWhyUs1ChangedEvent = undefined;
                        this.imageWhyUs2ChangedEvent = undefined;
                        this.imageWhyUs3ChangedEvent = undefined;
                        this.imageWhyUs4ChangedEvent = undefined;
                        this.getLandingWhyUs();
                    }
                });
            }
        } else if (type === 'CONTACT_US') {
            const params = {
                'university_id': this.user.userId,
                'address': (this.contactLandingForm.value && this.contactLandingForm.value.address) ? this.contactLandingForm.value.address : '',
                'district': (this.contactLandingForm.value && this.contactLandingForm.value.district) ? this.contactLandingForm.value.district : '',
                'state_id': (this.contactLandingForm.value && this.contactLandingForm.value.state_id) ? this.contactLandingForm.value.state_id : '',
                'pin_code': (this.contactLandingForm.value && this.contactLandingForm.value.pin_code) ? this.contactLandingForm.value.pin_code : '',
                'contact': (this.contactLandingForm.value && this.contactLandingForm.value.contact_us) ? this.contactLandingForm.value.contact_us : '',
                'location': (this.contactLandingForm.value && this.contactLandingForm.value.location) ? this.contactLandingForm.value.location : '',
            };
            if (this.updateContactUsData && this.updateContactUsData['data'] && this.updateContactUsData['data'].id) {
                this.landingPageContactUsService.update(this.updateContactUsData['data'].id, params).subscribe(data => {
                    this.getLandingContactUs();
                });
            } else {
                this.landingPageContactUsService.create(params).subscribe(data => {
                    if (data) {
                        this.getLandingContactUs();
                    }
                });
            }
        }
    }

    getLandingSliders() {
        this.landingPageSliderService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                let landingSliders = {};
                let slider1Data: any = undefined;
                let slider2Data: any = undefined;
                let slider3Data: any = undefined;
                let slider4Data: any = undefined;
                let slider5Data: any = undefined;
                let slider6Data: any = undefined;
                data['data'].forEach(data => {
                    if (data['type'] === 'SLIDER_1') {
                        slider1Data = data;
                        landingSliders['one'] = (slider1Data && slider1Data['file'] && slider1Data['file'].url) ? slider1Data['file'].url : '';
                    } else if (data['type'] === 'SLIDER_2') {
                        slider2Data = data;
                        landingSliders['two'] = (slider2Data && slider2Data['file'] && slider2Data['file'].url) ? slider2Data['file'].url : '';
                    } else if (data['type'] === 'SLIDER_3') {
                        slider3Data = data;
                        landingSliders['three'] = (slider3Data && slider3Data['file'] && slider3Data['file'].url) ? slider3Data['file'].url : '';
                    } else if (data['type'] === 'SLIDER_4') {
                        slider4Data = data;
                        landingSliders['four'] = (slider4Data && slider4Data['file'] && slider4Data['file'].url) ? slider4Data['file'].url : '';
                    } else if (data['type'] === 'SLIDER_5') {
                        slider5Data = data;
                        landingSliders['five'] = (slider5Data && slider5Data['file'] && slider5Data['file'].url) ? slider5Data['file'].url : '';
                    } else if (data['type'] === 'SLIDER_6') {
                        slider6Data = data;
                        landingSliders['six'] = (slider6Data && slider6Data['file'] && slider6Data['file'].url) ? slider6Data['file'].url : '';
                    }
                });
                if (landingSliders && !landingSliders['one']) {
                    landingSliders['one'] = 'assets/images/image-backgrounds/1600-800.png';
                }
                if (landingSliders && !landingSliders['two']) {
                    landingSliders['two'] = 'assets/images/image-backgrounds/1600-800.png';
                }
                if (landingSliders && !landingSliders['three']) {
                    landingSliders['three'] = 'assets/images/image-backgrounds/1600-800.png';
                }
                if (landingSliders && !landingSliders['four']) {
                    landingSliders['four'] = 'assets/images/image-backgrounds/1600-800.png';
                }
                if (landingSliders && !landingSliders['five']) {
                    landingSliders['five'] = 'assets/images/image-backgrounds/1600-800.png';
                }
                if (landingSliders && !landingSliders['six']) {
                    landingSliders['six'] = 'assets/images/image-backgrounds/1600-800.png';
                }
                this.landingSliders = landingSliders;
                this.slider1Data = slider1Data;
                this.slider2Data = slider2Data;
                this.slider3Data = slider3Data;
                this.slider4Data = slider4Data;
                this.slider5Data = slider5Data;
                this.slider6Data = slider6Data;
            }
        });
    }

    getLandingAboutUs() {
        this.landingPageAboutUsService.get({'page': -1}).subscribe(data => {
            if (data) {
                this.aboutUsData = data;
                if (data && data['data'] && data['data']['file'] && data['data']['file']) {
                    this.aboutUsImage = (data['data'] && data['data']['file']) ? data['data']['file'].url : '';
                }
                if (data && data['data'] && data['data'].about) {
                    this.aboutUsText = (data['data'] && data['data'].about) ? data['data'].about : '';
                }
                this.aboutLandingForm.patchValue({
                    'about_us_image': (data['data'] && data['data']['file']) ? data['data']['file'].url : '',
                    'about_us': (this.aboutUsText) ? this.aboutUsText : ''
                });
            }
        });
    }

    getLandingCourses() {
        this.landingPageCourseService.get({'page': -1}).subscribe(data => {
            if (data) {
                this.updateCourseData = data['data'];
                this.course1Data = {
                    'file_id': (data['data'] && data['data']?.course_1_file_id) ? data['data'].course_1_file_id : '',
                    'name': (data['data'] && data['data'].course_1_name) ? data['data'].course_1_name : '',
                    'info': (data['data'] && data['data'].course_1_info) ? data['data'].course_1_info : '',
                    'image': (data['data'] && data['data']['course_1_file'] && data['data']['course_1_file'].url) ? data['data']['course_1_file'].url : 'assets/images/image-backgrounds/500-300.png'
                };
                this.course2Data = {
                    'file_id': (data['data'] && data['data'].course_2_file_id) ? data['data'].course_2_file_id : '',
                    'name': (data['data'] && data['data'].course_2_name) ? data['data'].course_2_name : '',
                    'info': (data['data'] && data['data'].course_2_info) ? data['data'].course_2_info : '',
                    'image': (data['data'] && data['data']['course_2_file'] && data['data']['course_2_file'].url) ? data['data']['course_2_file'].url : 'assets/images/image-backgrounds/500-300.png'
                };
                this.course3Data = {
                    'file_id': (data['data'] && data['data'].course_3_file_id) ? data['data'].course_3_file_id : '',
                    'name': (data['data'] && data['data'].course_3_name) ? data['data'].course_3_name : '',
                    'info': (data['data'] && data['data'].course_3_info) ? data['data'].course_3_info : '',
                    'image': (data['data'] && data['data']['course_3_file'] && data['data']['course_3_file'].url) ? data['data']['course_3_file'].url : 'assets/images/image-backgrounds/500-300.png'
                };
                this.course4Data = {
                    'file_id': (data['data'] && data['data'].course_4_file_id) ? data['data'].course_4_file_id : '',
                    'name': (data['data'] && data['data'].course_4_name) ? data['data'].course_4_name : '',
                    'info': (data['data'] && data['data'].course_4_info) ? data['data'].course_4_info : '',
                    'image': (data['data'] && data['data']['course_4_file'] && data['data']['course_4_file'].url) ? data['data']['course_4_file'].url : 'assets/images/image-backgrounds/500-300.png'
                };
                this.course5Data = {
                    'file_id': (data['data'] && data['data'].course_5_file_id) ? data['data'].course_5_file_id : '',
                    'name': (data['data'] && data['data'].course_5_name) ? data['data'].course_5_name : '',
                    'info': (data['data'] && data['data'].course_5_info) ? data['data'].course_5_info : '',
                    'image': (data['data'] && data['data']['course_5_file'] && data['data']['course_5_file'].url) ? data['data']['course_5_file'].url : 'assets/images/image-backgrounds/500-300.png'
                };
                this.course6Data = {
                    'file_id': (data['data'] && data['data'].course_6_file_id) ? data['data'].course_6_file_id : '',
                    'name': (data['data'] && data['data'].course_6_name) ? data['data'].course_6_name : '',
                    'info': (data['data'] && data['data'].course_6_info) ? data['data'].course_6_info : '',
                    'image': (data['data'] && data['data']['course_6_file'] && data['data']['course_6_file'].url) ? data['data']['course_6_file'].url : 'assets/images/image-backgrounds/500-300.png'
                };
                this.courseLandingForm.patchValue({
                    'course_1_file_id': (data && data['data'] && data['data'].course_1_file_id) ? data['data'].course_1_file_id : '',
                    'course_1_name': (data && data['data'] && data['data'].course_1_name) ? data['data'].course_1_name : '',
                    'course_1_info': (data && data['data'] && data['data'].course_1_info) ? data['data'].course_1_info : '',
                    'course_2_file_id': (data && data['data'] && data['data'].course_2_file_id) ? data['data'].course_2_file_id : '',
                    'course_2_name': (data && data['data'] && data['data'].course_2_name) ? data['data'].course_2_name : '',
                    'course_2_info': (data && data['data'] && data['data'].course_2_info) ? data['data'].course_2_info : '',
                    'course_3_file_id': (data && data['data'] && data['data'].course_3_file_id) ? data['data'].course_3_file_id : '',
                    'course_3_name': (data && data['data'] && data['data'].course_3_name) ? data['data'].course_3_name : '',
                    'course_3_info': (data && data['data'] && data['data'].course_3_info) ? data['data'].course_3_info : '',
                    'course_4_file_id': (data && data['data'] && data['data'].course_4_file_id) ? data['data'].course_4_file_id : '',
                    'course_4_name': (data && data['data'] && data['data'].course_4_name) ? data['data'].course_4_name : '',
                    'course_4_info': (data && data['data'] && data['data'].course_4_info) ? data['data'].course_4_info : '',
                    'course_5_file_id': (data && data['data'] && data['data'].course_5_file_id) ? data['data'].course_5_file_id : '',
                    'course_5_name': (data && data['data'] && data['data'].course_5_name) ? data['data'].course_5_name : '',
                    'course_5_info': (data && data['data'] && data['data'].course_5_info) ? data['data'].course_5_info : '',
                    'course_6_file_id': (data && data['data'] && data['data'].course_6_file_id) ? data['data'].course_6_file_id : '',
                    'course_6_name': (data && data['data'] && data['data'].course_6_name) ? data['data'].course_6_name : '',
                    'course_6_info': (data && data['data'] && data['data'].course_6_info) ? data['data'].course_6_info : '',
                });
            }
        });
    }

    getLandingWhyUs() {
        this.landingPageWhyUsService.get({'page': -1}).subscribe(data => {
            if (data) {
                this.updateWhyUsData = data['data'];
                this.whyUs1Data = {
                    'file_id': (data['data'] && data['data'].why_us_1_file_id) ? data['data'].why_us_1_file_id : '',
                    'name': (data['data'] && data['data'].why_us_1_name) ? data['data'].why_us_1_name : '',
                    'info': (data['data'] && data['data'].why_us_1_info) ? data['data'].why_us_1_info : '',
                    'image': (data['data'] && data['data'].why_us_1_file_id) ? (data['data']['why_us_1_file'].url) : 'assets/images/image-backgrounds/500-300.png'
                };
                this.whyUs2Data = {
                    'file_id': (data['data'] && data['data'].why_us_2_file_id) ? data['data'].why_us_2_file_id : '',
                    'name': (data['data'] && data['data'].why_us_2_name) ? data['data'].why_us_2_name : '',
                    'info': (data['data'] && data['data'].why_us_2_info) ? data['data'].why_us_2_info : '',
                    'image': (data['data'] && data['data'].why_us_2_file_id) ? (data['data']['why_us_2_file'].url) : 'assets/images/image-backgrounds/500-300.png'
                };
                this.whyUs3Data = {
                    'file_id': (data['data'] && data['data'].why_us_3_file_id) ? data['data'].why_us_3_file_id : '',
                    'name': (data['data'] && data['data'].why_us_3_name) ? data['data'].why_us_3_name : '',
                    'info': (data['data'] && data['data'].why_us_3_info) ? data['data'].why_us_3_info : '',
                    'image': (data['data'] && data['data'].why_us_3_file_id) ? (data['data']['why_us_3_file'].url) : 'assets/images/image-backgrounds/500-300.png'
                };
                this.whyUs4Data = {
                    'file_id': (data['data'] && data['data'].why_us_4_file_id) ? data['data'].why_us_4_file_id : '',
                    'name': (data['data'] && data['data'].why_us_4_name) ? data['data'].why_us_4_name : '',
                    'info': (data['data'] && data['data'].why_us_4_info) ? data['data'].why_us_4_info : '',
                    'image': (data['data'] && data['data'].why_us_4_file_id) ? (data['data']['why_us_4_file'].url) : 'assets/images/image-backgrounds/500-300.png'
                };
                this.whyUsLandingForm.patchValue({
                    'why_us_1_file_id': (data && data['data'] && data['data'].why_us_1_file_id) ? data['data'].why_us_1_file_id : '',
                    'why_us_1_tab_name': (data && data['data'] && data['data'].why_us_1_name) ? data['data'].why_us_1_name : '',
                    'why_us_1_info': (data && data['data'] && data['data'].why_us_1_info) ? data['data'].why_us_1_info : '',
                    'why_us_2_file_id': (data && data['data'] && data['data'].why_us_2_file_id) ? data['data'].why_us_2_file_id : '',
                    'why_us_2_tab_name': (data && data['data'] && data['data'].why_us_2_name) ? data['data'].why_us_2_name : '',
                    'why_us_2_info': (data && data['data'] && data['data'].why_us_2_info) ? data['data'].why_us_2_info : '',
                    'why_us_3_file_id': (data && data['data'] && data['data'].why_us_3_file_id) ? data['data'].why_us_3_file_id : '',
                    'why_us_3_tab_name': (data && data['data'] && data['data'].why_us_3_name) ? data['data'].why_us_3_name : '',
                    'why_us_3_info': (data && data['data'] && data['data'].why_us_3_info) ? data['data'].why_us_3_info : '',
                    'why_us_4_file_id': (data && data['data'] && data['data'].why_us_4_file_id) ? data['data'].why_us_4_file_id : '',
                    'why_us_4_tab_name': (data && data['data'] && data['data'].why_us_4_name) ? data['data'].why_us_4_name : '',
                    'why_us_4_info': (data && data['data'] && data['data'].why_us_4_info) ? data['data'].why_us_4_info : ''
                });
            }
        });
    }

    getLandingContactUs() {
        this.landingPageContactUsService.get({'page': -1}).subscribe(data => {
            if (data) {
                this.updateContactUsData = data;
                this.contactUsData = {
                    'address': (data && data['data'] && data['data'].address) ? data['data'].address : '',
                    'district': (data && data['data'] && data['data'].district) ? data['data'].district : '',
                    'state': (data && data['data'] && data['data'].state) ? data['data'].state : '',
                    'pin_code': (data && data['data'] && data['data'].pin_code) ? data['data'].pin_code : '',
                    'contact': (data && data['data'] && data['data'].contact) ? data['data'].contact : '',
                    'location': (data && data['data'] && data['data'].location_url) ? data['data'].location_url : ''
                };
                this.contactLandingForm.patchValue({
                    'address': (data && data['data'] && data['data'].address) ? data['data'].address : '',
                    'district': (data && data['data'] && data['data'].district) ? data['data'].district : '',
                    'state_id': (data && data['data'] && data['data'].state_id) ? data['data'].state_id : '',
                    'pin_code': (data && data['data'] && data['data'].pin_code) ? data['data'].pin_code : '',
                    'contact_us': (data && data['data'] && data['data'].contact) ? data['data'].contact : '',
                    'location': (data && data['data'] && data['data'].location) ? data['data'].location : ''
                });
            }
        });
    }
}
