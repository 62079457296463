import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {UniversitysettingsComponent} from './universitysettings.component';
import {AddUniversitysettingComponent} from './add-universitysetting/add-universitysetting.component';

const routes: Routes = [
  {
    path: 'universitysettings',
    component: UniversitysettingsComponent,
    data: {animation: 'universitysettings'}
  },
  {
    path: 'universitysetting/add',
    component: AddUniversitysettingComponent,
    data: {animation: 'universitysetting/add'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UniversitysettingsRoutingModule { }
