<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add Setting</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update Setting</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addSettingForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="currentSessionYear">Current Session Year</label>
                                        <select class="form-control" id="currentSessionYear"
                                                formControlName="current_session_year">
                                            <option value="">Select Year</option>
                                            <option [value]="year" *ngFor="let year of years">{{year}}</option>
                                        </select>
                                        <small *ngIf="submitted && addSettingForm.get('current_session_year').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="studentApplicationIdPrefix">Student Application Id Prefix</label>
                                        <input class="form-control" id="studentApplicationIdPrefix" type="text"
                                               formControlName="student_application_id_prefix">
                                        <small *ngIf="submitted && addSettingForm.get('student_application_id_prefix').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect  class="btn btn-danger" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add new user Ends-->
</div>

