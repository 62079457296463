import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniversityService} from '../../shared/services/university.service';
import {AlertService} from '../../shared/services/alert.service';
import {CounsellorService} from '../../shared/services/counsellor.service';
import {StorageService} from '../../shared/services/storage.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';

@Component({
    selector: 'app-counsellors',
    templateUrl: './counsellors.component.html',
    styleUrls: ['./counsellors.component.scss']
})
export class CounsellorsComponent implements OnInit {
    showSearch = false;
    assignedUniversities: any;
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    modalType: any;
    counsellors: any = [];
    universities: any = [];
    selectedCounsellor: any;
    contentHeader: object;
    user: any;
    headCounsellorId: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private counsellorService: CounsellorService,
                private headCounsellorService: HeadCounsellorService,
                private universityService: UniversityService,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.headCounsellorId = this.activatedRoute.snapshot.params['id'];
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        if (this.headCounsellorId) {
            this.getHeadCounsellorDetail();
            this.getHeadCounsellorsCounsellors();
            this.getUserUniversities();
        } else {
            this.get();
            this.getUniversities();
        }
    }

    getHeadCounsellorDetail() {
        this.headCounsellorService.detail(this.headCounsellorId).subscribe(data => {
            this.contentHeader = {
                headerTitle: (data && data['name']) ? data['name'] + '\'s : Counsellors' : 'Counsellors',
                actionButton: false,
                breadcrumb: {
                    type: '',
                    links: [
                        {
                            name: 'Head Counsellors',
                            isLink: true,
                            link: '/head-counsellors'
                        },
                        {
                            name: 'Counsellors',
                            isLink: false
                        }
                    ]
                }
            };
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-counsellor').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            if (this.headCounsellorId) {
                this.getHeadCounsellorsCounsellors({page: 1, total: null, perpage: 15, pages: null});
            } else {
                this.get({page: 1, total: null, perpage: 15, pages: null});
            }
            this.toggleSidebar();
        }
    }

    assignUniversityModal(data, content, type) {
        this.selectedCounsellor = data;
        let assignedUniversities = [];
        if (this.selectedCounsellor && this.selectedCounsellor['counsellor_universities'] && this.selectedCounsellor['counsellor_universities'].length > 0) {
            this.selectedCounsellor['counsellor_universities'].forEach(counsellorUniversity => {
                assignedUniversities.push(counsellorUniversity['university']);
            });
        }
        this.assignedUniversities = assignedUniversities;
        this.modalType = type;
        this.modalService.open(content, {'size': 'md'});
    }

    updatePasswordModal(content, id, type) {
        this.id = id;
        this.modalType = type;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    getUserUniversities() {
        this.submitting = true;
        this.universities = [];
        this.universityService.getUserUniversity(this.headCounsellorId, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                let universities = [];
                data['data'].forEach(data => {
                    if (data && data['university']) {
                        universities.push(data['university']);
                    }
                });
                this.universities = universities;
            } else {
                this.universities = [];
            }
        });
    }

    getUniversities() {
        this.submitting = true;
        this.universities = [];
        this.universityService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                let universities = [];
                data['data'].forEach(data => {
                    if (data && data['university']) {
                        universities.push(data['university']);
                    }
                });
                this.universities = universities;
            } else {
                this.universities = [];
            }
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.counsellors = [];
        this.counsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
                if (this.counsellors && this.counsellors.length > 0) {
                    this.counsellors.forEach(counsellor => {
                        counsellor['count'] = counsellor['counsellor_universities'].length;
                    });
                }
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.counsellors = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getHeadCounsellorsCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.counsellors = [];
        this.headCounsellorService.getHeadCounsellorsCounsellors(this.headCounsellorId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
                if (this.counsellors && this.counsellors.length > 0) {
                    this.counsellors.forEach(counsellor => {
                        counsellor['count'] = counsellor['counsellor_universities'].length;
                    });
                }
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.counsellors = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.counsellorService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    assignUniversity() {
        if (this.assignedUniversities && this.assignedUniversities.length === 0) {
            this.alertService.showErrors('Please choose atleast one university');
        } else {
            let universityIds = [];
            this.assignedUniversities.forEach(university => {
                universityIds.push(university.id);
            });
            const params = {
                'university_ids': JSON.stringify(universityIds)
            };
            this.counsellorService.counsellorUniversity(this.selectedCounsellor['counsellor'].id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                if (this.headCounsellorId) {
                    this.getHeadCounsellorsCounsellors();
                } else {
                    this.get();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.counsellorService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }
}
