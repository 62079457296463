import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WhatsAppsComponent} from './whats-apps.component';
import {AddWhatsappComponent} from './add-whatsapp/add-whatsapp.component';

const routes: Routes = [
    {
        path: 'whatsapps',
        component: WhatsAppsComponent,
        data: {animation: 'whats-apps'}
    },
    {
        path: 'whatsapps/add',
        component: AddWhatsappComponent,
        data: {animation: 'whats-apps/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class WhatsAppsRoutingModule {
}
