import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UniversitiesComponent} from './universities.component';
import {AddUniversityComponent} from './add-university/add-university.component';
import {UniversityLandingComponent} from './university-landing/university-landing.component';

const routes: Routes = [
    {
        path: 'universities',
        component: UniversitiesComponent,
        data: {animation: 'universities'}
    },
    {
        path: 'university/add',
        component: AddUniversityComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-landing/:id',
        component: UniversityLandingComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-landing',
        component: UniversityLandingComponent,
        data: {animation: 'university'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UniversitiesRoutingModule {
}
