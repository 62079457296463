import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyProfileComponent} from './my-profile.component';
import {AddMyProfileComponent} from './add-my-profile/add-my-profile.component';

const routes: Routes = [
    {
        path: 'my-profile',
        component: MyProfileComponent,
        data: {animation: 'my-profile'}
    },
    {
        path: 'my-profile/add',
        component: AddMyProfileComponent,
        data: {animation: 'my-profile/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class MyProfileRoutingModule {
}
