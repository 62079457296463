import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as moment from 'moment';
// import {StaticEventsService} from './static.event.service';
// import {PubNubAngular} from 'pubnub-angular2';
import {environment} from '../../../environments/environment';

declare var $: any;

@Injectable()
export class GlobalService {
    public user: BehaviorSubject<any> = new BehaviorSubject(undefined);
    public self: BehaviorSubject<any> = new BehaviorSubject(undefined);
    public getWeightModal: BehaviorSubject<any> = new BehaviorSubject(undefined);
    public accessToken: BehaviorSubject<string> = new BehaviorSubject('');
    public permissions: BehaviorSubject<string> = new BehaviorSubject(undefined);
    public viewRefresh: BehaviorSubject<string> = new BehaviorSubject(undefined);
    public reloadSelf: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isSessionExist: Subject<any> = new Subject();
    public notification = new BehaviorSubject(undefined);
    notificationObservable = this.notification.asObservable();
    viewRefresh$ = this.viewRefresh.asObservable();
    self$ = this.self.asObservable();

    constructor() {
        if (StorageService.getItem('accessToken')) {
            this.setAccessToken(StorageService.getItem('accessToken'));
        }
        /*if (!StorageService.getItem('language')) {
          StorageService.setItem('language', 'EN');
        }*/
        /*this.pubnub.init({
            publishKey: 'pub-c-3cd375ad-b742-4f48-a4d1-b5ef6b94722e',
            subscribeKey: 'sub-c-e36efe14-5559-11ea-b828-26d2a984a2e5'
        });*/
        // this.getPermissions();
    }

    setSelf(self) {
        // const menuItems = ROUTES.filter(menuItem => menuItem);
        // const filteredRoutes = [];
        // const blackListedParentRoutes = [];
        // menuItems.forEach((i) => {
        //     if (!i['roles'] || !i['roles'].length) {
        //         filteredRoutes.push(i)
        //     } else if (i['roles'].indexOf(self.roleId) > -1) {
        //         filteredRoutes.push(i)
        //     } else {
        //         blackListedParentRoutes.push(i)
        //     }
        // });
        // self['filteredRoutes'] = filteredRoutes;
        // self['blackListedParentRoutes'] = blackListedParentRoutes;
        StorageService.setItem('self', self);
        this.self.next(self);

        // this.subscribeChannels();
    }

    getSelf() {
        return this.self.value;
    }

    /*subscribeChannels() {
        const channels = [];
        const self = this;
        const prefix = environment.pubNubChannelPrefix;
        // console.log(`${prefix}notification_user_${this.self.value.id}`);
        channels.push(`${prefix}notification_user_${this.self.value.id}`);
        channels.push(`${prefix}user_updated_${this.self.value.id}`);
        channels.push(`${prefix}user_updated_all`);
        this.pubnub.getMessage(channels, (msg) => {
            if (msg && msg.channel === `${prefix}user_updated_all` || msg.channel === `${prefix}user_updated_${this.self.value.id}` && msg.message) {
                if (msg.message.updated) {
                    this.reloadSelf.next(true);
                }
            } else if (msg && msg.message) {
                this.notification.next(msg.message);
            }
        });
        this.pubnub.subscribe({
            channels: channels,
            triggerEvents: ['message']
        });
    }*/

    convertToCSV(objArray) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let line = '';
        for (let i = 0; i < array.length; i++) {
            line = '';

            if (line !== '') {
                line += ',';
            }
            line += array[i];
            str += line + '\r\n';
        }

        return str;
    }

    exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }

        const csv = this.convertToCSV(items);

        const exportedFilename = fileTitle + '.csv' || 'export.csv';

        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const link = document.createElement('a');
            if (link['download'] !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    public setAccessToken(item) {
        StorageService.setItem('accessToken', item);
        this.accessToken.next(item);
    }

    public setIsSessionExist() {
        this.isSessionExist.next();
    }

    public setGetWeightModal(value) {
        this.getWeightModal.next(value);
    }

    public setViewRefresh(value) {
        this.viewRefresh.next(value);
    }

    public getAccessToken() {
        return StorageService.getItem('accessToken');
    }
}

export class MomentService {
    public now = moment;

    constructor() {

    }

    getDatepickerDate(date) {
        const array = this.now(date).format('YYYY-MM-DD').split('-');
        const mainDate = {
            year: parseInt(array[0]),
            month: parseInt(array[1]),
            day: parseInt(array[2])
        };
        return mainDate;
    }

    public getParsedDate(date, isRequested = false) {
        if (isRequested) {
            return date ? this.getRequestDateFormat(new Date(`${date['month']}-${date['day']}-${date['year']}`)) : '';
        }
        return date ? this.now(new Date(`${date['month']}-${date['day']}-${date['year']}`)).format('YYYY-MM-DD') : '';
    }

    public getParsedDateTime(date, isRequested = false) {
        if (isRequested) {
            return date ? this.getRequestDateFormat(new Date(`${date['month']}-${date['day']}-${date['year']}`)) : '';
        }
        return date ? this.now(new Date(`${date['month']}-${date['day']}-${date['year']}`)) : '';
    }

    public getRequestDateFormat(date = new Date(), onlyDate = false) {
        if (onlyDate) {
            return this.now(date).subtract({minutes: this.now().utcOffset()}).format('YYYY-MM-DD');
        }
        return this.now(date).subtract({minutes: this.now().utcOffset()}).format('YYYY-MM-DD HH:mm:ss');
    }

    public parse(date?, format?) {
        if (format) {
            return date ? this.now(date).add({minutes: this.now().utcOffset()}).format(format) : new Date();
        }
        return date ? this.now(date).add({minutes: this.now().utcOffset()}) : new Date();
    }

    public getCounterString(from = new Date(), expiry) {
        const fromDate = from.getTime();
        const expiryDate = expiry.getTime();
        return Math.max(0, expiryDate - fromDate);
    }

    public getBasicDateTimeString(date) {
        return this.now(date).subtract({minutes: this.now().utcOffset()}).format('DD-MM-YYYY kk:mm A');
    }

    public getDateOnly(date) {
        return this.now(date).format('DD-MM-YYYY');
    }

    public yearMonthDayDate(date) {
        return this.now(date).format('YYYY-MM-DD');
    }

    public fromNow(date?, doOffset?) {
        if (doOffset) {
            return date ? this.now(date).add({minutes: this.now().utcOffset()}).fromNow() : this.now().fromNow();
        } else {
            return date ? this.now(date).fromNow() : this.now().fromNow();
        }
    }

    getDisplayDate(date) {
        return this.now(new Date(date)).format('MMM DD, YYYY');
    }

}

@Injectable()
export class MetaService {
    constructor() {
        /*StaticEventsService.refreshContent$.subscribe((e) => {
            if (e) {
                setTimeout(() => {
                    this.refreshMeta();
                }, 100);
            }
        });*/
    }

    // getLanguageObject(): Observable<boolean> {
    //   // Add request for storing the translation
    //
    //   return ;
    // };

    refreshMeta() {
    }
}

