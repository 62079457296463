<section id="profile">
    <ng-template #content let-modal>
        <div class="landing-modal">
            <div class="modal-header">
                <h5 class="font-weight-400">
                    <i class="fab fa-pagelines cursor-pointer mr-2"></i>
                    <span>Update Profile Image</span>
                </h5>
                <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                   (click)="this.modalService.dismissAll('')"></i>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="imageChangedEvent">
                    <div class="text-center col-md-12">
                        <image-cropper
                                [imageChangedEvent]="imageChangedEvent"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="20 / 20"
                                [resizeToWidth]="200"
                                format="png, jpeg, jpg"
                                (imageCropped)="imageCropped($event)"
                                (imageLoaded)="imageLoaded()"
                                (cropperReady)="cropperReady()"
                                (loadImageFailed)="loadImageFailed()">
                        </image-cropper>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="text-center mt-2 mb-2">
                            <img [src]="profileImageUploadArea" class="img-fluid width-85-per" alt="Slide 1"
                                 (click)="imageProfileUpload.click()" *ngIf="!imageChangedEvent"/>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onImageChange($event)" #imageProfileUpload>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                        <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                *ngIf="imageChangedEvent" (click)="saveProfileImage()">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                (click)="imageProfileUpload.click()">New Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header shadow-bottom-light">
                                    <h2 class="card-title font-weight-bold">Profile</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row mt-1">
                                        <div class="col-12 text-center">
                                            <i class="fas fa-user-circle font-large-5 font-weight cursor-pointer"
                                               (click)="openModel(content)" *ngIf="!profileImage && user['user_type'] !== 'SUPER_ADMIN'"></i>
                                            <img [src]="profileImage" class="cursor-pointer profile-image"
                                                 *ngIf="profileImage && user['user_type'] !== 'SUPER_ADMIN'" (click)="openModel(content)"/>
                                            <img src="./assets/images/image-backgrounds/1600-1600.jpg" class="cursor-pointer profile-image"
                                                 *ngIf="user['user_type'] === 'SUPER_ADMIN'"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <form class="form" novalidate [formGroup]="myProfileForm">
                                                <div class="row mt-1">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="profile-name">Name</label>
                                                            <input type="text" id="profile-name"
                                                                   class="form-control"
                                                                   formControlName="name">
                                                            <small *ngIf="submitted && myProfileForm.get('name').hasError('required')"
                                                                   class="text-danger">
                                                                Field is required.
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="username">Username</label>
                                                            <input type="text" id="username" class="form-control"
                                                                   formControlName="username">
                                                            <small *ngIf="submitted && myProfileForm.get('username').hasError('required')"
                                                                   class="text-danger">
                                                                Field is required.
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="email"> Email</label>
                                                            <input type="email" id="email" class="form-control"
                                                                   formControlName="email">
                                                            <small *ngIf="submitted && myProfileForm.get('email').hasError('required')"
                                                                   class="text-danger">
                                                                Field is required.
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="contact">Contact</label>
                                                            <input type="number" id="contact" class="form-control"
                                                                   formControlName="contact">
                                                            <small *ngIf="submitted && myProfileForm.get('contact').hasError('required')"
                                                                   class="text-danger">
                                                                Field is required.
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer width-100-per">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <button class="btn btn-primary pull-left"
                                                    (click)="toggleSidebar()">
                                                <span><i class="fas fa-key mr-1"></i></span>update Password
                                            </button>
                                            <button class="btn btn-success  pull-right" (click)="update()" [disabled]="submitting">
                                            <i class="fas fa-user mr-1" *ngIf="!submitting"></i>
                                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-my-profile"
            overlayClass="modal-backdrop">
        <app-add-my-profile [data]="data" (valueSubmitted)="getData($event)"></app-add-my-profile>
    </core-sidebar>
</section>
