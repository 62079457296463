import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class CounsellorService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.COUNSELLORS(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.COUNSELLORS_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.COUNSELLORS(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.COUNSELLORS(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.COUNSELLORS(id), data);
    }

    updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.COUNSELLOR_PASSWORD(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.COUNSELLOR_STATUS(id), data);
    }

    counsellorUniversity(id, data): Observable<any> {
        return this.putRequest(AppUrl.COUNSELLOR_UNIVERSITY(id), data);
    }

    getCounsellorUniversities(id, data): Observable<any> {
        return this.getRequest(AppUrl.COUNSELLOR_UNIVERSITY(id), data);
    }

    updateProfile(id, data): Observable<any> {
        return this.putRequest(AppUrl.COUNSELLORS_PROFILE_IMAGE(id), data);
    }

    getCounsellorReportCount(id): Observable<any> {
        return this.getRequest(AppUrl.COUNSELLORS_PROGRESS_REPORT_COUNT(id));
    }
}
