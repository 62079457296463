import {Component, OnInit} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {StageService} from '../../shared/services/stage.service';
import {StudentService} from '../../shared/services/student.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SrMappingService} from '../../shared/services/sr-mapping.service';
import {AlertService} from '../../shared/services/alert.service';
import {Helper} from '../../shared/constants/helper';
import {StudentStageStatusService} from '../../shared/services/student-stage-status.service';
import {StorageService} from '../../shared/services/storage.service';
import {CourseService} from '../../shared/services/course.service';
import {Router} from '@angular/router';
import {CounsellorService} from '../../shared/services/counsellor.service';
import {AdminService} from '../../shared/services/admin.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';

@Component({
    selector: 'app-students',
    templateUrl: './students.component.html',
    styleUrls: ['./students.component.scss']
})

export class StudentsComponent implements OnInit {
    tabs: any = [
        {
            'id': 'All',
            'name': 'All',
            'data': []
        }
    ];
    studentDetail: any;
    selectedStudentData: any;
    updateStatusForm: FormGroup;
    addQuickLeadForm: FormGroup;
    bulkUniversityForm: FormGroup;
    stages: any = [];
    reasons: any = [];
    submitted: any = false;
    submitting: any = false;
    quickLeadAdd: any = false;
    bulkUploadAdd: any = false;
    courses = [];
    universities = [];
    user: any;
    counsellorDetail: any;
    assignStudentData: any = false;
    admins: any = [];
    headCounsellors: any = [];
    counsellors: any = [];
    selectAssignable: any;
    studentChecked: any = false;
    onFileChanged: any;
    bulkStudentData: any = [];
    showFetchingDataLoader: any = false;
    showUpdatingDataLoader: any = false;
    studentPagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private _coreSidebarService: CoreSidebarService,
                private stageService: StageService,
                private studentService: StudentService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private srMappingService: SrMappingService,
                public studentStageStatusService: StudentStageStatusService,
                public courseService: CourseService,
                public counsellorService: CounsellorService,
                public alertService: AlertService,
                private router: Router,
                private adminService: AdminService,
                private headCounsellorService: HeadCounsellorService) {
        this.getStages();
    }

    ngOnInit(): void {
        this.user = StorageService.getItem('self');
        if (this.user && this.user['user_type'] && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN' || this.user['user_type'] === 'HEAD_COUNSELLOR')) {
            this.tabs = [
                {
                    'id': 'All',
                    'name': 'All',
                    'data': []
                },
                {
                    'id': 'Unassigned',
                    'name': 'Unassigned',
                    'data': []
                }
            ];
        }
        this.refresh();
    }

    refresh() {
        this.updateStatusForm = this.fb.group({
            'stage_id': ['', Validators.required],
            'reason_id': ['', Validators.required],
            'remark': ['']
        });
        this.addQuickLeadForm = this.fb.group({
            'university_id': ['', Validators.required],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'course_id': ['', Validators.required]
        });
        this.bulkUniversityForm = this.fb.group({
            'university_id': ['', Validators.required]
        });
        this.get();
        this.getCounsellorDetail();
        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins();
        } else if (this.user && this.user['user_type'] === 'ADMIN') {
            this.getHeadCounsellors();
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getCounsellors();
        }
    }

    get() {
        this.stageService.get({'page': -1}).subscribe(data => {
            let tabs = this.tabs;
            if (data && data['data'].length > 0) {
                data['data'].forEach(data => {
                    tabs.push({
                        'id': data.id,
                        'name': data.name,
                        'data': []
                    });
                });
                this.tabs = tabs;
            }
            this.getStudents(this.studentPagination);
        });
    }

    getAdmins(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.admins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
        });
    }

    getHeadCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.headCounsellors = [];
        this.headCounsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.headCounsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.headCounsellors = [];
            }
        });
    }

    getCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.counsellors = [];
        this.counsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.counsellors = [];
            }
        });
    }

    getCounsellorDetail() {
        if (this.user && this.user['userId']) {
            this.counsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.counsellorDetail = data;
                    this.universities = (this.counsellorDetail && this.counsellorDetail['counsellor_universities'] && this.counsellorDetail['counsellor_universities'].length > 0) ? this.counsellorDetail['counsellor_universities'] : [];
                    if (this.universities && this.universities.length > 0) {
                        if (this.user && this.user['user_type'] === 'COUNSELLOR') {
                            this.getUniversityCourses(this.universities[0].university_id);
                        }
                    }
                }
            });
        }
    }

    getUniversityCourses(universityId) {
        if (universityId) {
            this.courseService.getByAdmin(universityId, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
            });
        } else {
            this.alertService.showErrors('Please choose university');
        }
    }

    getStages() {
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.stages = data['data'];
            }
        });
    }

    getReason(id) {
        if (id) {
            this.updateStatusForm.patchValue({
                'reason_id': ''
            });
            this.reasons = [];
            this.srMappingService.getReasonByStage(id, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.reasons = Helper.uniqByKeepLast(data['data'], it => it.reason_id);
                }
            });
        } else {
            this.alertService.showErrors('Something went wrong, please select program again');
        }
    }

    getStageWiseStudents(stageId, index, params: any = {}) {
        params.page = this.studentPagination.page;
        params.per_page = this.studentPagination.perpage;
        this.studentService.getByStage(stageId, params).subscribe(data => {
            this.tabs[index].data = [];
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data['student_id']) ? data['student_id'] : '',
                        'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                        'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status': (data && data['stage'] && data['stage'].name) ? data['stage'].name : '',
                        'status_id': (data && data['stage'] && data['stage'].id) ? data['stage'].id : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['head_counsellor'] && data['head_counsellor']) ? data['head_counsellor'] : ''
                    });
                });
                this.tabs[0].data = allData;
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'course': all.course,
                                            'status': all.status,
                                            'status_id': all.status_id,
                                            'register_course_id': all.register_course_id,
                                            'register_course': all.register_course,
                                            'added_by': all.added_by,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
            }
            this.studentPagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
        });
    }

    getStudents(params: any = {}) {
        params.page = this.studentPagination.page;
        params.per_page = this.studentPagination.perpage;
        this.studentService.get(params).subscribe(data => {
            let allData = [];
            let tabsData = this.tabs;
            if (data && data['data'].length > 0) {
                data['data'].forEach(data => {
                    allData.push({
                        'id': (data['student_id']) ? data['student_id'] : '',
                        'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                        'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                        'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                        'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                        'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                        'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                        'status': (data && data['stage'] && data['stage'].name) ? data['stage'].name : '',
                        'status_id': (data && data['stage'] && data['stage'].id) ? data['stage'].id : '',
                        'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                        'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                        'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                        'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                        'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                        'university': (data && data['university']) ? data['university'] : '',
                        'added_by': (data && data['added_by']) ? data['added_by'] : '',
                        'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                        'head_counsellor': (data && data['head_counsellor'] && data['head_counsellor']) ? data['head_counsellor'] : ''
                    });
                });
                this.tabs[0].data = allData;
                if (tabsData && tabsData.length > 0) {
                    tabsData.forEach(tab => {
                        if (tab) {
                            if (allData && allData.length > 0) {
                                allData.forEach(all => {
                                    if (tab.id === all.status_id) {
                                        tab['data'].push({
                                            'id': all.id,
                                            'name': all.name,
                                            'username': all.username,
                                            'email': all.email,
                                            'phone': all.phone,
                                            'course': all.course,
                                            'status': all.status,
                                            'status_id': all.status_id,
                                            'register_course_id': all.register_course_id,
                                            'register_course': all.register_course,
                                            'added_by': all.added_by,
                                            'counsellor': all.counsellor,
                                            'head_counsellor': all.head_counsellor
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
            }
            this.studentPagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
        });
    }

    getTabData(tab, index, params: any = {}) {
        if (tab && tab.id !== 'All') {
            if (tab && tab.id === 'Unassigned') {
                params.page = this.studentPagination.page;
                params.per_page = this.studentPagination.perpage;
                this.studentService.getUnAssignedStudents(params).subscribe(data => {
                    if (data && data['data'] && data['data'].length > 0) {
                        let unassignedData = [];
                        data['data'].forEach(data => {
                            if (data && (data['added_by'] === this.user['userId']) && this.user && this.user['user_type'] && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN' || this.user['user_type'] === 'HEAD_COUNSELLOR')) {
                                unassignedData.push({
                                    'id': (data['student_id']) ? data['student_id'] : '',
                                    'name': (data['student'] && data['student'].name) ? data['student'].name : '',
                                    'username': (data['student'] && data['student'].username) ? data['student'].username : '',
                                    'email': (data['student'] && data['student'].email) ? data['student'].email : '',
                                    'phone': (data['student'] && data['student'].contact) ? data['student'].contact : '',
                                    'course': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['course'] && data['student']['course_details']['course'].name) ? data['student']['course_details']['course'].name : '',
                                    'branch': (data && data['student'] && data['student']['course_details'] && data['student']['course_details']['branch'] && data['student']['course_details']['branch'].name) ? data['student']['course_details']['branch'].name : '',
                                    'status': (data && data['stage'] && data['stage'].name) ? data['stage'].name : '',
                                    'status_id': (data && data['stage'] && data['stage'].id) ? data['stage'].id : '',
                                    'register_course': (data && data['register_course'] && data['register_course'].name) ? data['register_course'].name : '',
                                    'register_course_id': (data && data['register_course'] && data['register_course'].id) ? data['register_course'].id : '',
                                    'address': (data && data['student'] && data['student'].address) ? data['student'].address : '',
                                    'personal_info': (data && data['student'] && data['student'].personal_info) ? data['student'].personal_info : '',
                                    'follow_ups': (data && data['student'] && data['student'].follow_ups) ? (data && data && data['student'] && data['student'].follow_ups) : '',
                                    'university': (data && data['university']) ? data['university'] : '',
                                    'added_by': (data && data['added_by']) ? data['added_by'] : '',
                                    'counsellor': (data && data['counsellor'] && data['counsellor']) ? data['counsellor'] : '',
                                    'head_counsellor': (data && data['head_counsellor'] && data['head_counsellor']) ? data['head_counsellor'] : ''
                                });
                            }
                        });
                        this.tabs[index].data = unassignedData;
                    } else {
                        this.tabs[index].data = [];
                    }
                    this.studentPagination = {
                        page: data.current_page,
                        total: data.total,
                        perpage: data.per_page,
                        pages: data.last_page,
                    };
                });
            } else {
                this.getStageWiseStudents(tab.id, index);
            }
        } else {
            this.getStudents(this.studentPagination);
        }
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('detail-student').toggleOpen();
    }

    detail(data: any) {
        this.studentDetail = data;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.getStudents({page: 1, total: null, perpage: 15, pages: null});
        }
        this.toggleSidebar();
    }

    changeStatusModal(content, data) {
        this.selectedStudentData = data;
        this.quickLeadAdd = false;
        this.assignStudentData = false;
        this.modalService.open(content, {'size': 'sm'});
    }

    updateStatus() {
        this.submitted = true;
        if (!this.updateStatusForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_id': this.selectedStudentData.id,
                'stage_id': (this.updateStatusForm.value && this.updateStatusForm.value.stage_id) ? this.updateStatusForm.value.stage_id : '',
                'reason_id': (this.updateStatusForm.value && this.updateStatusForm.value.reason_id) ? this.updateStatusForm.value.reason_id : '',
                'remark': (this.updateStatusForm.value && this.updateStatusForm.value.remark) ? this.updateStatusForm.value.remark : '',
            };
            this.submitting = true;
            this.studentStageStatusService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.modalService.dismissAll('');
                    this.getStudents({page: 1, total: null, perpage: 15, pages: null});
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    openBulkUploadModal(content) {
        this.selectedStudentData = undefined;
        this.assignStudentData = false;
        this.quickLeadAdd = false;
        this.bulkUploadAdd = true;
        this.bulkStudentData = [];
        this.modalService.open(content, {'size': 'xl'});
    }

    openQuickLeadModal(content) {
        this.selectedStudentData = undefined;
        this.assignStudentData = false;
        this.bulkUploadAdd = false;
        this.quickLeadAdd = true;
        this.modalService.open(content);
    }

    openAssignmentModal(content) {
        this.selectedStudentData = undefined;
        this.quickLeadAdd = false;
        this.bulkUploadAdd = false;
        this.assignStudentData = true;
        this.modalService.open(content, {'size': 'xl'});
    }

    submitQuickLead() {
        this.submitted = true;
        if (!this.addQuickLeadForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'university_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.university_id) ? this.addQuickLeadForm.value.university_id : '',
                'name': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.name) ? this.addQuickLeadForm.value.name : '',
                'email': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.email) ? this.addQuickLeadForm.value.email : '',
                'contact': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.contact) ? this.addQuickLeadForm.value.contact : '',
                'course_id': (this.addQuickLeadForm.value && this.addQuickLeadForm.value.course_id) ? this.addQuickLeadForm.value.course_id : '',
                'type': 'ADDED_BY_COUNSELLOR',
                'added_by': (this.counsellorDetail && this.counsellorDetail['counsellor_detail'] && this.counsellorDetail['counsellor_detail'].unique_id) ? this.counsellorDetail['counsellor_detail'].unique_id : 1
            };
            this.submitting = true;
            this.studentService.studentRegister(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.addQuickLeadForm.reset();
                    this.addQuickLeadForm.patchValue({
                        'university_id': '',
                        'name': '',
                        'email': '',
                        'contact': '',
                        'course_id': ''
                    });
                    this.modalService.dismissAll('');
                    this.getStudents();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    submitBulkUpload() {
        if (this.bulkStudentData && this.bulkStudentData.length > 0) {
            let repeatedEmail = Helper.repeatedObjects(this.bulkStudentData, 'email');
            let repeatedContact = Helper.repeatedObjects(this.bulkStudentData, 'contact');

            this.bulkStudentData.forEach(data => {
                data['errors']['name_message'] = false;
                data['errors']['email_message'] = false;
                data['errors']['contact_message'] = false;
                data['errors']['course_message'] = false;
                data['errors']['email_duplicate_message'] = false;
                data['errors']['contact_duplicate_message'] = false;
            });

            this.bulkStudentData.forEach(data => {
                if (repeatedEmail && repeatedEmail.length > 0) {
                    repeatedEmail.forEach(dt => {
                        if (dt.email === data.email) {
                            data['errors']['email_duplicate_message'] = 'Duplicate email address';
                        }
                    });
                }

                if (repeatedContact && repeatedContact.length > 0) {
                    repeatedContact.forEach(dt => {
                        if (dt.contact === data.contact) {
                            data['errors']['contact_duplicate_message'] = 'Duplicate contact number';
                        }
                    });
                }

                if (data && data.name === '') {
                    data['errors']['name_message'] = 'Name is required';
                }

                if (data && data.email !== '') {
                    if (!Helper.validateEmail(data.email)) {
                        data['errors']['email_duplicate_message'] = false;
                        data['errors']['email_message'] = 'Invalid email Format';
                    }
                } else {
                    data['errors']['email_duplicate_message'] = false;
                    data['errors']['email_message'] = 'Email is required';
                }

                if (data && data.course_id === '') {
                    data['errors']['course_message'] = 'Course is required';
                }

                if (data && data.contact !== '') {
                    if (!Helper.validateContact(data.contact)) {
                        data['errors']['contact_duplicate_message'] = false;
                        data['errors']['contact_message'] = 'Invalid contact Format';
                    }
                } else {
                    data['errors']['contact_duplicate_message'] = false;
                    data['errors']['contact_message'] = 'Contact is required';
                }
            });

            let errorCount = 0;
            this.bulkStudentData.forEach(data => {
                if (data && data['errors'] && (data['errors']['name_message'] || data['errors']['email_message'] || data['errors']['contact_message'] || data['errors']['course_message'] || data['errors']['email_duplicate_message'] || data['errors']['contact_duplicate_message'])) {
                    errorCount++;
                }
            });

            if (!errorCount) {
                this.submitting = true;
                this.submitted = true;
                this.showUpdatingDataLoader = true;
                let universityId = (this.universities && this.universities.length === 1) ? this.universities[0]['university'].id : ((this.bulkUniversityForm && this.bulkUniversityForm.value) ? this.bulkUniversityForm.value.university_id : '');
                this.studentService.studentBulkRegister({
                    'university_id': universityId,
                    'type': 'ADDED_BY_COUNSELLOR',
                    'students': this.bulkStudentData
                }).subscribe(data => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                    this.bulkUniversityForm.reset();
                    this.bulkUniversityForm.patchValue({
                        'university_id': ''
                    });
                    this.getStudents();
                    this.modalService.dismissAll('');
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    this.showUpdatingDataLoader = false;
                });
            }
        } else {
            this.alertService.showErrors('No student data found');
        }
    }

    navigateToUrl(url) {
        this.router.navigateByUrl(url);
    }

    onAssignPageChange(page) {
        this.pagination.page = page;
        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins(this.pagination);
        } else if (this.user && this.user['user_type'] === 'ADMIN') {
            this.getHeadCounsellors(this.pagination);
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getCounsellors(this.pagination);
        }
    }

    chooseAssignable(data, type, index, status) {
        if (type === 'ADMIN') {
            if (this.admins && this.admins.length > 0) {
                this.admins.forEach(admin => {
                    admin['assignable'] = false;
                });
                this.admins[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        } else if (type === 'HEAD_COUNSELLOR') {
            if (this.headCounsellors && this.headCounsellors.length > 0) {
                this.headCounsellors.forEach(headCounsellor => {
                    headCounsellor['assignable'] = false;
                });
                this.headCounsellors[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        } else if (type === 'COUNSELLOR') {
            if (this.counsellors && this.counsellors.length > 0) {
                this.counsellors.forEach(counsellor => {
                    counsellor['assignable'] = false;
                });
                this.counsellors[index]['assignable'] = true;
                this.selectAssignable = (status) ? data : undefined;
            }
        }
    }

    checkStudent() {
        if (this.tabs && this.tabs.length > 0) {
            let checkedData = [];
            this.tabs.forEach(tab => {
                if (tab && tab['data'] && tab['data'].length > 0) {
                    tab['data'].forEach(data => {
                        if (data && data['isChecked']) {
                            checkedData.push(data);
                        }
                    });
                }
            });
            this.studentChecked = checkedData && checkedData.length > 0;
        }
    }

    assignToStudent() {
        if (this.tabs && this.tabs.length > 0) {
            let checkedData = [];
            this.tabs.forEach(tab => {
                if (tab && tab['data']) {
                    tab['data'].forEach(data => {
                        if (data && data['isChecked']) {
                            checkedData.push(data);
                        }
                    });
                }
            });
            let counsellorType = 'Admin';
            let selectedAssignableId = (this.selectAssignable) ? this.selectAssignable.id : '';
            if (this.user && this.user['user_type'] === 'ADMIN') {
                counsellorType = 'Head Counsellor';
                selectedAssignableId = (this.selectAssignable && this.selectAssignable['user']) ? this.selectAssignable['user'].id : '';
            } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
                counsellorType = 'Counsellor';
                selectedAssignableId = (this.selectAssignable && this.selectAssignable['counsellor']) ? this.selectAssignable['counsellor'].id : '';
            }
            if (this.selectAssignable) {
                let students = [];
                let studentUniversities = [];
                if (checkedData && checkedData.length > 0) {
                    checkedData.forEach(student => {
                        students.push(student.id);
                        studentUniversities.push(student['university'].id);
                    });
                    if (students && students.length > 0) {
                        const params = {
                            'assign_to': selectedAssignableId,
                            'students': (students && students.length > 0) ? JSON.stringify(students) : ''
                        };
                        if (this.user && this.user['user_type'] && this.user['user_type'] === 'ADMIN') {
                            let universityExists = false;
                            if (this.selectAssignable && this.selectAssignable['head_counsellor_universities'] && this.selectAssignable['head_counsellor_universities'].length > 0) {
                                let assignableUniversities = [];
                                this.selectAssignable['head_counsellor_universities'].forEach(head_counsellor_university => {
                                    if (head_counsellor_university && head_counsellor_university['university']) {
                                        assignableUniversities.push(head_counsellor_university['university'].id);
                                    }
                                });
                                if (studentUniversities && assignableUniversities && assignableUniversities.length > 0 && studentUniversities.length > 0) {
                                    studentUniversities = Helper.removeDuplicates(studentUniversities);
                                    // universityExists = Helper.checkEqualArray(assignableUniversities, studentUniversities);
                                    universityExists = studentUniversities.every(function(val) { return assignableUniversities.indexOf(val) >= 0; });
                                }
                            }
                            if (universityExists) {
                                this.studentService.assignStudent(params).subscribe(data => {
                                    if (data) {
                                        this.getStudents();
                                        this.getTabData({
                                            'id': 'Unassigned',
                                            'name': 'Unassigned',
                                            'data': []
                                        }, 1);
                                        this.modalService.dismissAll('');
                                    }
                                });
                            } else {
                                this.alertService.showErrors('Student\'s Univeristy is not assigned to Head Counsellor');
                            }
                        } else {
                            this.studentService.assignStudent(params).subscribe(data => {
                                if (data) {
                                    this.getStudents();
                                    this.getTabData({
                                        'id': 'Unassigned',
                                        'name': 'Unassigned',
                                        'data': []
                                    }, 1);
                                    this.modalService.dismissAll('');
                                }
                            });
                        }
                    } else {
                        this.alertService.showErrors('Please choose students to assign ' + counsellorType);
                        return;
                    }
                } else {
                    this.alertService.showErrors('Please choose students to assign ' + counsellorType);
                    return;
                }
            } else {
                this.alertService.showErrors('Please choose ' + counsellorType + ' to assign students');
                return;
            }
        }
    }

    onStudentPageChange(page) {
        this.pagination.page = page;
        this.getStudents();
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.studentService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'STUDENT_BULK_UPLOAD') {
                this.studentBulkUpload(data['data'].url);
            }
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    studentBulkUpload(url) {
        this.showFetchingDataLoader = true;
        this.studentService.studentBulkUpload({'url': url}).subscribe(data => {
            if (data) {
                this.bulkStudentData = data;
                this.showFetchingDataLoader = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            this.showFetchingDataLoader = false;
        });
    }

    removeStudent(index) {
        this.bulkStudentData.splice(index, 1);
    }

    newUpload() {
        this.bulkStudentData = [];
    }

    downloadSample() {
        window.open('assets/docs/students.xlsx', '_blank')
    }
}
