import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TextMessageService} from '../../shared/services/text-message.service';

@Component({
    selector: 'app-whats-apps',
    templateUrl: './whats-apps.component.html',
    styleUrls: ['./whats-apps.component.scss']
})
export class WhatsAppsComponent implements OnInit {
    showSearch = false;
    whatsApps= [];
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private textMessageService: TextMessageService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'student': [''],
            'university': [''],
            'message': ['']
        });
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-whatsapp').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            /*this.get({page: 1, total: null, perpage: 15, pages: null});*/
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.whatsApps= [];
        this.textMessageService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.whatsApps= data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.whatsApps= [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'student': (this.searchForm.value && this.searchForm.value.student) ? this.searchForm.value.student : '',
            'university': (this.searchForm.value && this.searchForm.value.university) ? this.searchForm.value.university : '',
            'message': (this.searchForm.value && this.searchForm.value.message) ? this.searchForm.value.message : ''
        };
        this.get(params);
    }

    /*updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.textMessageService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }*/

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'student': (this.searchForm && this.searchForm.value && this.searchForm.value.student) ? this.searchForm.value.student : '',
            'university': (this.searchForm && this.searchForm.value && this.searchForm.value.university) ? this.searchForm.value.university : '',
            'message': (this.searchForm && this.searchForm.value && this.searchForm.value.message) ? this.searchForm.value.message : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    /*updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.textMessageService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }*/

}
