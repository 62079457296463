import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import {CoreConfigService} from '../../../@core/services/config.service';
import {StateService} from '../../shared/services/state.service';
import {repeaterAnimation} from 'app/main/animations/form-repeater.animation';
import {ProgramService} from '../../shared/services/program.service';
import {PcbMappingService} from '../../shared/services/pcb-mapping.service';
import {AlertService} from '../../shared/services/alert.service';
import {UniversityService} from '../../shared/services/university.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StudentService} from '../../shared/services/student.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';
import {AdminService} from '../../shared/services/admin.service';
import {CourseService} from '../../shared/services/course.service';
import {BranchService} from '../../shared/services/branch.service';
import {CounsellorService} from '../../shared/services/counsellor.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [repeaterAnimation]
})
export class DashboardComponent implements OnInit, AfterViewInit {
    user: any;
    states: any = [];
    data: any;
    id: any;
    public coreConfig: any;
    programs = [];
    courses = [];
    branches = [];
    universities: any = [];
    headCounsellorData: any;
    counsellorData: any;
    admissionLink: any;
    registeredStudents: any = 0;
    admissionDone: any = 0;
    inProgressStudents: any = 0;
    notConnectedStudents: any = 0;
    admins: any = [];
    programCount: any = 0;
    courseCount: any = 0;
    branchCount: any = 0;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    counsellors: any = [];
    headCounsellors: any = [];
    counsellorsProgressReport: any = {
        'assigned_students': 0,
        'registration_done_students': 0,
        'admission_done_students': 0,
        'not_connected_students': 0
    };
    headCounsellorsProgressReport: any = {
        'assigned_students': 0,
        'registration_done_students': 0,
        'admission_done_students': 0,
        'not_connected_students': 0
    };
    adminsProgressReport: any = {
        'assigned_students': 0,
        'registration_done_students': 0,
        'admission_done_students': 0,
        'not_connected_students': 0
    };

    constructor(public _coreConfigService: CoreConfigService,
                public stateService: StateService,
                public modalService: NgbModal,
                private programService: ProgramService,
                private courseService: CourseService,
                private branchService: BranchService,
                private router: Router,
                private pcbMappingService: PcbMappingService,
                private alertService: AlertService,
                private universityService: UniversityService,
                public headCounsellorService: HeadCounsellorService,
                private counsellorService: CounsellorService,
                public adminService: AdminService,
                public studentService: StudentService) {
        this.user = StorageService.getItem('self');
    }


    ngOnInit(): void {
        this.getCounsellorDetails();
        this.getHeadCounsellorDetails();
        if (this.user && this.user['user_type'] && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN')) {
            this.getHeadCounsellors();
        }

        if (this.user && (this.user['user_type'] === 'SUPER_ADMIN' || this.user['user_type'] === 'ADMIN' || this.user['user_type'] === 'HEAD_COUNSELLOR' || this.user['user_type'] === 'COUNSELLOR')) {
            this.getUniversities();
        }
    }

    ngAfterViewInit(): void {
        this.refresh();
    }

    refresh() {
        this.getStates();
        this.getUniversities(this.data);
        this.getStudentCount();
        this.getAdmissionDoneCount();
        this.getInProgressDoneCount();
        this.getNotConnectedCount();

        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getAdmins();
        } else if (this.user && this.user['user_type'] === 'UNIVERSITY') {
            this.getProgramsCount();
            this.getCoursesCount();
            this.getBranchesCount();
        } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.getCounsellors();
            this.getAdmins();
        }
    }

    getProgramsCount() {
        this.programService.count().subscribe(data => {
            if (data) {
                this.programCount = data;
            }
        }, error => {
            this.programCount = 0;
        });
    }

    getCoursesCount() {
        this.courseService.count().subscribe(data => {
            if (data) {
                this.courseCount = data;
            }
        }, error => {
            this.courseCount = 0;
        });
    }

    getBranchesCount() {
        this.branchService.count().subscribe(data => {
            if (data) {
                this.branchCount = data;
            }
        }, error => {
            this.branchCount = 0;
        });
    }

    getHeadCounsellors(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.headCounsellors = [];
        this.headCounsellorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.headCounsellors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.headCounsellors = [];
            }
        });
    }

    getAdmins(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.admins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
        });
    }

    getUniversities(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.universities = [];
        this.universityService.get(params).subscribe(data => {
            if (data['data'] && data['data'].length > 0) {
                this.universities = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.universities = [];
            }
        });
    }

    getStudentCount() {
        this.studentService.count().subscribe(data => {
            if (data) {
                this.registeredStudents = data;
            }
        }, error => {
            this.registeredStudents = 0;
        });
    }

    getAdmissionDoneCount() {
        this.studentService.admissionCount().subscribe(data => {
            this.admissionDone = data;
        }, error => {
            this.admissionDone = 0;
        });
    }

    getInProgressDoneCount() {
        this.studentService.inProgressCount().subscribe(data => {
            this.inProgressStudents = data;
        }, error => {
            this.inProgressStudents = 0;
        });
    }

    getNotConnectedCount() {
        this.studentService.getNotConnectedCount().subscribe(data => {
            this.notConnectedStudents = data;
        }, error => {
            this.notConnectedStudents = 0;
        });
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    getHeadCounsellorDetails() {
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'HEAD_COUNSELLOR') {
            this.headCounsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.headCounsellorData = data;
                }
            });
        }
    }

    getCounsellorDetails() {
        if (this.user && this.user['user_type'] && this.user['user_type'] === 'COUNSELLOR') {
            this.counsellorService.detail(this.user['userId']).subscribe(data => {
                if (data) {
                    this.counsellorData = data;
                    if (this.counsellorData && this.counsellorData['counsellor_detail'] && this.counsellorData['counsellor_detail']['head_counsellor'] && this.counsellorData['counsellor_detail']['head_counsellor'].unique_link_status && this.counsellorData['counsellor_detail']['head_counsellor'].unique_link_status === 'ACTIVE') {
                        this.admissionLink = (this.counsellorData && this.counsellorData['counsellor_universities'] && this.counsellorData['counsellor_universities'][0] && this.counsellorData['counsellor_universities'][0]['university_detail'] && this.counsellorData['counsellor_universities'][0]['university_detail'].url) ? this.counsellorData['counsellor_universities'][0]['university_detail'].url + '?counsellor=' + this.counsellorData['counsellor_detail'].unique_id : '';
                    }
                }
            });
        }
    }

    openShareLink(content) {
        this.modalService.open(content, {
            centered: true,
            size: 'md'
        });
    }

    copyMessage() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.admissionLink;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.alertService.showSuccess({'title': 'success', 'message': 'Link Copied successfully'});
    }

    navigateTo(url) {
        window.open(url, 'blank');
    }

    getCounsellors() {
        this.counsellorService.get(this.data).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.counsellors = data['data'];
            }
        });
    }

    /*getCounsellorReport(id) {
        this.counsellorService.getCounsellorReportCount(id).subscribe(data => {
            this.counsellorsProgressReport = data['data'];
        }, error => {
            this.counsellorsProgressReport = 0;
        });
    }*/

    getHeadCounsellorReport(id) {
        this.headCounsellorService.getHeadCounsellorReportCount(id).subscribe(data => {
            this.headCounsellorsProgressReport = data;
        });
    }

    getAdminReport(id) {
        this.adminService.getAdminReportCount(id).subscribe(data => {
            this.adminsProgressReport = data;
        });
    }

    getCounsellorReport(id) {
        this.counsellorService.getCounsellorReportCount(id).subscribe(data => {
            this.counsellorsProgressReport = data;
        });
    }
}
