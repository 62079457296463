<div class="content-wrapper">
    <div class="content-body">
        <section id="course-curriculum">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <h2 class="mt-5px">Course Curriculum</h2>
                        </div>
                        <!--<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                            <button class="btn btn-primary pull-right color-white users-btn mr-2"
                                    (click)="showSearch = !showSearch">
                                <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                            </button>
                            <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer">
                                <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Curriculum
                            </button>
                        </div>-->
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card mt-3">
                                <!-- <div class="card-header bottom-shadow-light">
                                     <h2 class="card-title">Course Curriculum</h2>
                                 </div>-->
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="font-weight mt-1">Curriculum Type<span
                                                                class="text-danger">*</span>
                                                        </label>
                                                        <select class="form-control">
                                                            <option value="">Select Curriculum Type</option>
                                                            <option id="semester">Semester</option>
                                                            <option id="year">Year</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="font-weight mt-1">Duration<span
                                                                class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control" type="text" value="3">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label class="font-weight mt-1">Minimum Amount For
                                                            Admission<span
                                                                    class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control" type="text" value="3">
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group p-0">
                                                        <label class="font-weight p-0">Upload Fee Structure<span
                                                                class="text-danger">*</span>
                                                        </label>
                                                        <br>
                                                        <button class="btn btn-primary color-white mt-12px mb-2">
                                                            <i class="fas fa-cloud-upload-alt mr-5px ml--5px"></i>Upload
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
