import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TextMessagesComponent} from './text-messages.component';
import {AddTextmessageComponent} from './add-textmessage/add-textmessage.component';

const routes: Routes = [
    {
        path: 'text-messages',
        component: TextMessagesComponent,
        data: {animation: 'text-messages'}
    },
    {
        path: 'text-messages/add',
        component: AddTextmessageComponent,
        data: {animation: 'text-message/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class TextMessagesRoutingModule {
}
