<!-- Email list Area -->
<div class="email-app-list">
    <!-- Email search starts -->
    <div class="app-fixed-search d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
            <button class="btn p-0" (click)="toggleSidebar('email-sidebar')">
                <i class="fas fa-bars"></i>
            </button>
        </div>
        <div class="d-flex align-content-center justify-content-between w-100">
            <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <i class="fas fa-search text-muted"></i>
                    </span>
                </div>
                <input type="text" class="form-control" id="email-search" placeholder="Search email"
                       aria-label="Search..." aria-describedby="email-search" (keyup)="queryUpdate($event)"
                />
            </div>
        </div>
    </div>
    <!-- Email search ends -->

    <!-- Email actions starts -->
    <div class="app-action">
        <div class="action-left">
            <div class="custom-control custom-checkbox selectAll">
                <input type="checkbox" class="custom-control-input" id="selectAllCheck" [checked]="hasSelectedMails"
                       [indeterminate]="isIndeterminate" (click)="toggleSelectAll()"
                />
                <label class="custom-control-label font-weight-bolder pl-25" for="selectAllCheck">Select All</label>
            </div>
        </div>
        <div class="action-right" *ngIf="hasSelectedMails > 0">
            <ul class="list-inline m-0">
                <li class="list-inline-item">
                    <div ngbDropdown>
                        <a href="javascript:void(0);" ngbDropdownToggle id="folder" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-folder-open f_16"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="folder">
                            <a ngbDropdownItem (click)="updateFolderOnSelectedMails('draft')"
                               class="d-flex align-items-center" href="javascript:void(0);">
                                <i class="fas fa-edit mr-50"></i>
                                <span>Draft</span>
                            </a>
                            <a ngbDropdownItem (click)="updateFolderOnSelectedMails('spam')"
                               class="d-flex align-items-center" href="javascript:void(0);">
                                <i class="fas fa-info-circle mr-50"></i>
                                <span>Spam</span>
                            </a>
                            <a ngbDropdownItem (click)="updateFolderOnSelectedMails('trash')"
                               class="d-flex align-items-center" href="javascript:void(0);">
                                <i class="fas fa-trash mr-50"></i>
                                <span>Trash</span>
                            </a>
                        </div>
                    </div>
                </li>
                <li class="list-inline-item">
                    <div ngbDropdown>
                        <a href="javascript:void(0);" ngbDropdownToggle id="tag" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-tag f_16"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="tag">
                            <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('personal')"
                               href="javascript:void(0);">
                                <span class="mr-50 bullet bullet-success bullet-sm"></span>Personal
                            </a>
                            <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('company')"
                               href="javascript:void(0);">
                                <span class="mr-50 bullet bullet-primary bullet-sm"></span>Company
                            </a>
                            <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('important')"
                               href="javascript:void(0);">
                                <span class="mr-50 bullet bullet-warning bullet-sm"></span>Important
                            </a>
                            <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('private')"
                               href="javascript:void(0);">
                                <span class="mr-50 bullet bullet-danger bullet-sm"></span>Private
                            </a>
                        </div>
                    </div>
                </li>
                <li class="list-inline-item mail-unread">
                    <button class="action-icon btn p-0" (click)="unRead()">
                        <i class="fas fa-envelope f_16"></i>
                    </button>
                </li>
                <li class="list-inline-item mail-delete">
                    <button class="action-icon btn p-0" (click)="updateFolderOnSelectedMails('trash')">
                        <i class="fas fa-trash f_16"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <!-- Email actions ends -->

    <!-- Email list starts -->
    <div class="email-user-list" [perfectScrollbar]>
        <ul class="email-media-list">
            <app-mail-list-item
                    [email]="email"
                    *ngFor="let email of emails; let i = index"
                    (click)="openEmail(email.id); $event.stopPropagation()"
            ></app-mail-list-item>
            <ng-container *ngIf="emails.length; else noResults"></ng-container>
        </ul>
        <ng-template #noResults>
            <div class="no-results show">
                <h5>No Items Found</h5>
            </div>
        </ng-template>
    </div>
    <!-- Email list ends -->
</div>
<!--/ Email list Area -->
