import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CoreConfigService} from '../../../@core/services/config.service';
import {AppConstants} from '../../shared/constants/app-constant';
import {Helper} from '../../shared/constants/helper';
import Stepper from 'bs-stepper';
import {environment} from '../../../environments/environment';
import {StateService} from '../../shared/services/state.service';
import {ProgramService} from '../../shared/services/program.service';
import {PcbMappingService} from '../../shared/services/pcb-mapping.service';
import {AlertService} from '../../shared/services/alert.service';
import {takeUntil} from 'rxjs/operators';
import {repeaterAnimation} from '../animations/form-repeater.animation';
import {GlobalService} from '../../shared/services/global.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
// @ts-ignore
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {AdmissionService} from '../../shared/services/admission.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StudentPersonalInfoService} from '../../shared/services/student-personal-info.service';
import {AuthService} from '../../shared/services/auth.service';
import {StudentAddressService} from '../../shared/services/student-address.service';
import {StudentQualificationService} from '../../shared/services/student-qualification.service';
import {StudentMoreQualifcationService} from '../../shared/services/student-more-qualifcation.service';
import {StudentCourseDetailsService} from '../../shared/services/student-course-details.service';
import {UniversityService} from '../../shared/services/university.service';
import {StudentService} from '../../shared/services/student.service';

@Component({
    selector: 'app-admission',
    templateUrl: './admission.component.html',
    styleUrls: ['./admission.component.scss'],
    animations: [repeaterAnimation]
})
export class AdmissionComponent implements OnInit {
    private _unsubscribeAll: Subject<any>;
    disableAll: false;
    user: any;
    states: any = [];
    boardUniversity = AppConstants.boardUniversity;
    markingScheme = AppConstants.markingSchemes;
    years = Helper.getYearArray();
    public coreConfig: any;
    private verticalWizardStepper: Stepper;
    baseUrl = environment.appUrl;
    onFileChanged: any;
    filePhotograph: any;
    fileGovtPhotoId: any;
    fileTenthCertificate: any;
    fileTwelthCertificate: any;
    items = [{
        university: '',
        institution: '',
        passing_year: '',
        course: '',
        branch: '',
        obtained_percenatge: '',
        completion_certificate: '',
        data: ''
    }];
    item = {
        university: '',
        institution: '',
        passing_year: '',
        course: '',
        branch: '',
        obtained_percenatge: '',
        completion_certificate: '',
        data: ''
    };
    programs = [];
    courses = [];
    branches = [];
    imageChangedProfileEvent: any;
    croppedProfileImageEvent: any;
    croppedProfileImage: any;
    submitted: any = false;
    submitting: any = false;
    personalInfoForm: FormGroup;
    addressForm: FormGroup;
    qualificationDetailsForm: FormGroup;
    studentCourseDetailsForm: FormGroup;
    personalInfoData: any;
    addressData: any;
    qualificationData: any;
    moreQualificationData: any;
    studentCourseData: any;
    id: any;
    universityData: any;
    contentHeader: object;
    studentDetailData: any;

    constructor(public _coreConfigService: CoreConfigService,
                public stateService: StateService,
                private programService: ProgramService,
                private pcbMappingService: PcbMappingService,
                private alertService: AlertService,
                private globalService: GlobalService,
                private _location: Location,
                private router: Router,
                public modalService: NgbModal,
                private admissionService: AdmissionService,
                private fb: FormBuilder,
                private authService: AuthService,
                private studentPersonalInfoService: StudentPersonalInfoService,
                private studentAddressService: StudentAddressService,
                private studentQualificationService: StudentQualificationService,
                private studentMoreQualificationService: StudentMoreQualifcationService,
                private studentCourseDetailsService: StudentCourseDetailsService,
                public universityService: UniversityService,
                public studentService: StudentService,
                private activatedRoute: ActivatedRoute) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.getStudentDetail();
        this.authService.self({all: true}).subscribe((data) => {
            this.user = data;
            if (!this.user || !this.user['id']) {
                /*this.router.navigate(['']).then(() => {
                    this.
                });*/
            }
            /*if (!this.user || !this.user['id']) {
                this.router.navigate(['']).then(() => {
                    window.location.reload();
                });
            }*/

            if (this.personalInfoForm && this.studentDetailData) {
                this.personalInfoForm.patchValue({
                    'email': (this.studentDetailData && this.studentDetailData['email']) ? this.studentDetailData['email'] : '',
                    'contact_no': (this.studentDetailData && this.studentDetailData['contact']) ? this.studentDetailData['contact'] : '',
                });
            }
        }, error => {
            /*this.router.navigate(['']).then(() => {
                window.location.reload();
            });*/
        });
        this.refresh();
    }

    refresh() {
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });

        this.verticalWizardStepper = new Stepper(document.querySelector('#stepper'), {
            linear: false,
            animation: true
        });

        this.personalInfoForm = this.fb.group({
            'full_name': ['', Validators.required],
            'email': [{
                value: '',
                disabled: true
            }, Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'father_name': ['', Validators.required],
            'mother_name': ['', Validators.required],
            'photograph': ['', Validators.required],
            'govt_photo_id': ['', Validators.required],
            'contact_no': [{
                value: '',
                disabled: true
            }, Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'alternate_contact_no': ['', Validators.compose([Validators.pattern('^[5-9]{1}[0-9]{9}$')])]
        });

        this.addressForm = this.fb.group({
            'address': ['', Validators.required],
            'landmark': [''],
            'tehsil_town': ['', Validators.required],
            'district': ['', Validators.required],
            'state_id': ['', Validators.required],
            'pin': ['', Validators.required]
        });

        this.qualificationDetailsForm = this.fb.group({
            'tenth_institution_name': ['', Validators.required],
            'tenth_board': ['', Validators.required],
            'tenth_year_of_passing': ['', Validators.required],
            'tenth_marking_scheme': ['', Validators.required],
            'tenth_obtained_percentage_cgpa': ['', Validators.required],
            'twelth_institution_name': ['', Validators.required],
            'twelth_board': ['', Validators.required],
            'twelth_year_of_passing': ['', Validators.required],
            'twelth_marking_scheme': ['', Validators.required],
            'twelth_obtained_percentage_cgpa': ['', Validators.required],
            'tenth_certificate': [''],
            'twelth_certificate': ['']
        });

        this.studentCourseDetailsForm = this.fb.group({
            'program_id': ['', Validators.required],
            'course_id': ['', Validators.required],
            'branch_id': ['', Validators.required]
        });

        this.getStates();

        /*if (this.id) {
            this.detail(this.id);
            this.disableAllForms();
        } else {
            this.getPersonalInfo();
            this.getQualifications();
            this.getCourseDetails();
        }
        this.getApplicationDetails();*/
        this.getPersonalInfo();
        this.getAddress();
        this.getQualifications();
        this.getMoreQualifications();
        this.getCourseDetails();
        this.getUniversityDetail();
        // this.getCourseDetails();
    }

    getStudentDetail() {
        this.studentService.detailByCounsellor(this.id).subscribe(data => {
            if (data) {
                this.studentDetailData = data;
                if (!this.id || !this.studentDetailData) {
                    this.router.navigateByUrl('dashboard');
                } else {
                    this.contentHeader = {
                        headerTitle: this.studentDetailData.name + '\'s : Admission',
                        actionButton: false,
                        breadcrumb: {
                            type: '',
                            links: [
                                {
                                    name: 'Students',
                                    isLink: true,
                                    link: '/students'
                                },
                                {
                                    name: 'admission',
                                    isLink: false
                                }
                            ]
                        }
                    };
                }
                this.getPrograms();
            }
        });
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
            //this.getPrograms();
        });
    }

    getPrograms() {
        this.programs = [];
        if (this.studentDetailData && this.studentDetailData['student_detail'] && this.studentDetailData['student_detail'].university_id) {
            this.programService.getByAdmin(this.studentDetailData['student_detail'].university_id, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.programs = data['data'];
                }
            });
        }
    }

    getCourse(id) {
        if (id) {
            this.courses = [];
            this.branches = [];
            this.pcbMappingService.getByLanding(id, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = Helper.uniqByKeepLast(data['data'], it => it.course_id);
                }
            });
        } else {
            this.alertService.showErrors('Something went wrong, please select program again');
        }
    }

    getBranch(id) {
        if (id) {
            this.branches = [];
            this.pcbMappingService.getByLanding(id, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.branches = Helper.uniqByKeepLast(data['data'], it => it.branch_id);
                }
            });
        } else {
            this.alertService.showErrors('Something went wrong, please select course again');
        }
    }

    getPersonalInfo() {
        this.submitting = true;
        this.studentPersonalInfoService.getByCounsellor(this.id).subscribe(data => {
            this.personalInfoData = data;
            this.submitting = false;
            this.patchPersonlInfoForm();
        }, error => {
            this.submitting = false;
        });
    }

    getAddress() {
        this.submitting = true;
        this.studentAddressService.getByCounsellor(this.id).subscribe(data => {
            this.addressData = data;
            this.submitting = false;
            this.patchAddressForm();
        }, error => {
            this.submitting = false;
        });
    }

    getQualifications() {
        this.submitting = true;
        this.studentQualificationService.getByCounsellor(this.id).subscribe(data => {
            this.qualificationData = data;
            this.submitting = false;
            this.patchQualificationForm();
        }, error => {
            this.submitting = false;
        });
    }

    getMoreQualifications() {
        this.submitting = true;
        this.studentMoreQualificationService.getByCounsellor(this.id).subscribe(data => {
            this.moreQualificationData = data;
            this.submitting = false;
            this.patchMoreQualificationForm();
        }, error => {
            this.submitting = false;
        });
    }

    getUniversityDetail(params: any = {}) {
        if (this.user && this.user['university_id']) {
            this.universityService.detailByLanding(this.user['university_id']).subscribe(data => {
                if (data) {
                    this.universityData = data;
                    let title = (this.universityData && this.universityData['name']) ? this.universityData['name'].toUpperCase() : 'Landing Page';
                }
            });
        }
    }

    getCourseDetails() {
        this.submitting = true;
        this.studentCourseDetailsService.getByAdmin(this.id).subscribe(data => {
            this.studentCourseData = data;
            this.submitting = false;
            this.patchCourseForm();
        }, error => {
            this.submitting = false;
        });
    }

    patchPersonlInfoForm() {
        this.personalInfoForm.patchValue({
            'full_name': (this.personalInfoData && this.personalInfoData.full_name) ? this.personalInfoData.full_name : '',
            'email': (this.personalInfoData && this.personalInfoData.email) ? this.personalInfoData.email : '',
            'father_name': (this.personalInfoData && this.personalInfoData.father_name) ? this.personalInfoData.father_name : '',
            'mother_name': (this.personalInfoData && this.personalInfoData.mother_name) ? this.personalInfoData.mother_name : '',
            'contact_no': (this.personalInfoData && this.personalInfoData.contact_no) ? this.personalInfoData.contact_no : '',
            'alternate_contact_no': (this.personalInfoData && this.personalInfoData.alternate_contact_no) ? this.personalInfoData.alternate_contact_no : '',
            'photograph': (this.personalInfoData && this.personalInfoData.photograph) ? this.personalInfoData.photograph.id : '',
            'govt_photo_id': (this.personalInfoData && this.personalInfoData.govt_photo_id) ? this.personalInfoData.govt_photo_id.id : '',
        });
        if (this.personalInfoData && this.personalInfoData.photograph) {
            this.filePhotograph = {'url': (this.personalInfoData && this.personalInfoData.photograph && this.personalInfoData.photograph['url']) ? this.personalInfoData.photograph['url'] : ''};
        }
        if (this.personalInfoData && this.personalInfoData.govt_photo_id) {
            this.fileGovtPhotoId = {'url': (this.personalInfoData && this.personalInfoData.govt_photo_id && this.personalInfoData.govt_photo_id['url']) ? this.personalInfoData.govt_photo_id['url'] : ''};
        }
    }

    patchAddressForm() {
        this.addressForm.patchValue({
            'address': (this.addressData && this.addressData.address) ? this.addressData.address : '',
            'landmark': (this.addressData && this.addressData.landmark) ? this.addressData.landmark : '',
            'tehsil_town': (this.addressData && this.addressData.tehsil_town) ? this.addressData.tehsil_town : '',
            'district': (this.addressData && this.addressData.district) ? this.addressData.district : '',
            'state_id': (this.addressData && this.addressData.state_id) ? this.addressData.state_id : '',
            'pin': (this.addressData && this.addressData.pin) ? this.addressData.pin : ''
        });
    }

    patchQualificationForm() {
        this.qualificationDetailsForm.patchValue({
            'tenth_institution_name': (this.qualificationData && this.qualificationData.tenth_institution_name) ? this.qualificationData.tenth_institution_name : '',
            'tenth_board': (this.qualificationData && this.qualificationData.tenth_board) ? this.qualificationData.tenth_board : '',
            'tenth_year_of_passing': (this.qualificationData && this.qualificationData.tenth_year_of_passing) ? this.qualificationData.tenth_year_of_passing : '',
            'tenth_marking_scheme': (this.qualificationData && this.qualificationData.tenth_marking_scheme) ? this.qualificationData.tenth_marking_scheme : '',
            'tenth_obtained_percentage_cgpa': (this.qualificationData && this.qualificationData.tenth_obtained_percentage_cgpa) ? this.qualificationData.tenth_obtained_percentage_cgpa : '',
            'twelth_institution_name': (this.qualificationData && this.qualificationData.twelth_institution_name) ? this.qualificationData.twelth_institution_name : '',
            'twelth_board': (this.qualificationData && this.qualificationData.twelth_board) ? this.qualificationData.twelth_board : '',
            'twelth_year_of_passing': (this.qualificationData && this.qualificationData.twelth_year_of_passing) ? this.qualificationData.twelth_year_of_passing : '',
            'twelth_marking_scheme': (this.qualificationData && this.qualificationData.twelth_marking_scheme) ? this.qualificationData.twelth_marking_scheme : '',
            'twelth_obtained_percentage_cgpa': (this.qualificationData && this.qualificationData.twelth_obtained_percentage_cgpa) ? this.qualificationData.twelth_obtained_percentage_cgpa : '',
            'tenth_certificate': (this.qualificationData && this.qualificationData.tenth_certificate) ? this.qualificationData.tenth_certificate.id : '',
            'twelth_certificate': (this.qualificationData && this.qualificationData.twelth_certificate) ? this.qualificationData.twelth_certificate.id : '',
        });
        if (this.qualificationData && this.qualificationData.tenth_certificate) {
            this.fileTenthCertificate = {'url': (this.qualificationData && this.qualificationData.tenth_certificate && this.qualificationData.tenth_certificate['url']) ? this.qualificationData.tenth_certificate['url'] : ''};
        }
        if (this.qualificationData && this.qualificationData.twelth_certificate) {
            this.fileTwelthCertificate = {'url': (this.qualificationData && this.qualificationData.twelth_certificate && this.qualificationData.twelth_certificate['url']) ? this.qualificationData.twelth_certificate['url'] : ''};
        }
    }

    patchMoreQualificationForm() {
        this.items = [];
        if (this.moreQualificationData && this.moreQualificationData.length > 0) {
            let items = [];
            this.moreQualificationData.forEach(moreQualification => {
                items.push({
                    'university': (moreQualification && moreQualification['university']) ? moreQualification['university'] : '',
                    'institution': (moreQualification && moreQualification['institution_name']) ? moreQualification['institution_name'] : '',
                    'passing_year': (moreQualification && moreQualification['year_of_passing']) ? moreQualification['year_of_passing'] : '',
                    'course': (moreQualification && moreQualification['course']) ? moreQualification['course'] : '',
                    'branch': (moreQualification && moreQualification['branch']) ? moreQualification['branch'] : '',
                    'obtained_percenatge': (moreQualification && moreQualification['obtained_percentage']) ? moreQualification['obtained_percentage'] : '',
                    'completion_certificate': (moreQualification && moreQualification['completion_certificate']) ? moreQualification['completion_certificate'].id : '',
                    'data': (moreQualification && moreQualification['completion_certificate']) ? moreQualification['completion_certificate'] : ''
                });
            });
            this.items = items;
        } else {
            this.items = [{
                university: '',
                institution: '',
                passing_year: '',
                course: '',
                branch: '',
                obtained_percenatge: '',
                completion_certificate: '',
                data: ''
            }];
        }
    }

    patchCourseForm() {
        if (this.studentCourseData && this.studentCourseData.program_id) {
            this.getCourse(this.studentCourseData.program_id);
        }

        if (this.studentCourseData && this.studentCourseData.course_id) {
            this.getBranch(this.studentCourseData.course_id);
        }
        this.studentCourseDetailsForm.patchValue({
            'program_id': (this.studentCourseData && this.studentCourseData.program_id) ? this.studentCourseData.program_id : '',
            'course_id': (this.studentCourseData && this.studentCourseData.course_id) ? this.studentCourseData.course_id : '',
            'branch_id': (this.studentCourseData && this.studentCourseData.branch_id) ? this.studentCourseData.branch_id : ''
        });
    }

    verticalWizardNext() {
        this.verticalWizardStepper.next();
    }

    verticalWizardPrevious() {
        this.verticalWizardStepper.previous();
    }

    onFileChange(e, type, index?) {
        this.onFileChanged = e;
        this.studentPersonalInfoService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'GOVT_PHOTO_ID') {
                this.fileGovtPhotoId = data['data'];
                this.personalInfoForm.patchValue({
                    'govt_photo_id': this.fileGovtPhotoId.id
                });
            } else if (type === 'TENTH_CERTIFICATE') {
                this.fileTenthCertificate = data['data'];
                this.qualificationDetailsForm.patchValue({
                    'tenth_certificate': this.fileTenthCertificate.id
                });
            } else if (type === 'TWELTH_CERTIFICATE') {
                this.fileTwelthCertificate = data['data'];
                this.qualificationDetailsForm.patchValue({
                    'twelth_certificate': this.fileTwelthCertificate.id
                });
            } else if (type === 'MORE_QUALIFICATION') {
                this.items[index].completion_certificate = data['data'].id;
                this.items[index].data = data['data'];
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openPhotograph() {
        window.open(this.filePhotograph['url'], '_blank');
    }

    openPhotoId() {
        window.open(this.fileGovtPhotoId['url'], '_blank');
    }

    openTenthCertificate() {
        window.open(this.fileTenthCertificate['url'], '_blank');
    }

    openTwelthCertificate() {
        window.open(this.fileTwelthCertificate['url'], '_blank');
    }

    openCompletetionCertificate(data) {
        window.open(data['data'].url, '_blank');
    }

    addItem() {
        this.items.push({
            university: '',
            institution: '',
            passing_year: '',
            course: '',
            branch: '',
            obtained_percenatge: '',
            completion_certificate: '',
            data: ''
        });
    }

    deleteItem(id) {
        if (this.items && this.items.length > 1) {
            for (let i = 0; i < this.items.length; i++) {
                if (this.items.indexOf(this.items[i]) === id) {
                    this.items.splice(i, 1);
                    break;
                }
            }
        } else if (this.items && this.items.length === 1) {
            this.items = [{
                university: '',
                institution: '',
                passing_year: '',
                course: '',
                branch: '',
                obtained_percenatge: '',
                completion_certificate: '',
                data: ''
            }];
        }
    }

    openPhotographModal(content) {
        /*this.showWritePost = false;
        this.showProfileUpload = true;
        this.showCoverUpload = false;*/
        this.modalService.open(content, {size: 'lg'});
    }

    imageProfileCropped(event: ImageCroppedEvent) {
        this.croppedProfileImageEvent = event;
        this.croppedProfileImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    fileChangeProfileEvent(event: any): void {
        this.imageChangedProfileEvent = event;
    }

    saveProfileImage() {
        const params = {
            'type': 'PROFILE_IMAGE',
            'user_id': this.id,
            'fileType': 'BASE64'
        };
        this.studentPersonalInfoService.fileUpload(params, this.croppedProfileImage).subscribe(data => {
            if (data) {
                this.personalInfoForm.patchValue({
                    'photograph': (data && data['data'] && data['data'].id) ? data['data'].id : ''
                });
                this.filePhotograph = {'url': data['data'].url};
                this.imageChangedProfileEvent = undefined;
                this.modalService.dismissAll('');
                this.alertService.showSuccess({'title': 'success', 'message': 'Image successfully loaded'});
            }
        });
    }

    savePersonalInfo() {
        this.submitted = true;
        if (!this.personalInfoForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_id': this.id,
                'full_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().full_name) ? this.personalInfoForm.getRawValue().full_name : '',
                'email': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().email) ? this.personalInfoForm.getRawValue().email : '',
                'father_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().father_name) ? this.personalInfoForm.getRawValue().father_name : '',
                'mother_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().mother_name) ? this.personalInfoForm.getRawValue().mother_name : '',
                'contact_no': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().contact_no) ? this.personalInfoForm.getRawValue().contact_no : '',
                'alternate_contact_no': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().alternate_contact_no) ? this.personalInfoForm.getRawValue().alternate_contact_no : '',
                'photograph': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().photograph) ? this.personalInfoForm.getRawValue().photograph : '',
                'govt_photo_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().govt_photo_id) ? this.personalInfoForm.getRawValue().govt_photo_id : '',
            };
            this.submitting = true;
            this.studentPersonalInfoService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.getPersonalInfo();
                    this.verticalWizardNext();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updatePersonalInfo() {
        this.submitted = true;
        if (!this.personalInfoForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'full_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().full_name) ? this.personalInfoForm.getRawValue().full_name : '',
                'father_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().father_name) ? this.personalInfoForm.getRawValue().father_name : '',
                'mother_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().mother_name) ? this.personalInfoForm.getRawValue().mother_name : '',
                'contact_no': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().contact_no) ? this.personalInfoForm.getRawValue().contact_no : '',
                'email': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().email) ? this.personalInfoForm.getRawValue().email : '',
                'alternate_contact_no': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().alternate_contact_no) ? this.personalInfoForm.getRawValue().alternate_contact_no : '',
                'photograph': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().photograph) ? this.personalInfoForm.getRawValue().photograph : '',
                'govt_photo_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().govt_photo_id) ? this.personalInfoForm.getRawValue().govt_photo_id : '',
            };
            this.submitting = true;
            this.studentPersonalInfoService.update(this.personalInfoData['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.verticalWizardNext();
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    saveAddressInfo() {
        this.submitted = true;
        if (!this.addressForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_id': this.id,
                'address': (this.addressForm.getRawValue() && this.addressForm.getRawValue().address) ? this.addressForm.getRawValue().address : '',
                'landmark': (this.addressForm.getRawValue() && this.addressForm.getRawValue().landmark) ? this.addressForm.getRawValue().landmark : '',
                'tehsil_town': (this.addressForm.getRawValue() && this.addressForm.getRawValue().tehsil_town) ? this.addressForm.getRawValue().tehsil_town : '',
                'district': (this.addressForm.getRawValue() && this.addressForm.getRawValue().district) ? this.addressForm.getRawValue().district : '',
                'state_id': (this.addressForm.getRawValue() && this.addressForm.getRawValue().state_id) ? this.addressForm.getRawValue().state_id : '',
                'pin': (this.addressForm.getRawValue() && this.addressForm.getRawValue().pin) ? this.addressForm.getRawValue().pin : ''
            };
            this.submitting = true;
            this.studentAddressService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.getAddress();
                    this.verticalWizardNext();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updateAddress() {
        this.submitted = true;
        if (!this.addressForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'address': (this.addressForm.getRawValue() && this.addressForm.getRawValue().address) ? this.addressForm.getRawValue().address : '',
                'landmark': (this.addressForm.getRawValue() && this.addressForm.getRawValue().landmark) ? this.addressForm.getRawValue().landmark : '',
                'tehsil_town': (this.addressForm.getRawValue() && this.addressForm.getRawValue().tehsil_town) ? this.addressForm.getRawValue().tehsil_town : '',
                'district': (this.addressForm.getRawValue() && this.addressForm.getRawValue().district) ? this.addressForm.getRawValue().district : '',
                'state_id': (this.addressForm.getRawValue() && this.addressForm.getRawValue().state_id) ? this.addressForm.getRawValue().state_id : '',
                'pin': (this.addressForm.getRawValue() && this.addressForm.getRawValue().pin) ? this.addressForm.getRawValue().pin : ''
            };
            this.submitting = true;
            this.studentAddressService.update(this.addressData['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.verticalWizardNext();
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    saveQualificationDetails() {
        this.submitted = true;
        if (!this.qualificationDetailsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_id': this.id,
                'tenth_institution_name': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_institution_name) ? this.qualificationDetailsForm.getRawValue().tenth_institution_name : '',
                'tenth_board': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_board) ? this.qualificationDetailsForm.getRawValue().tenth_board : '',
                'tenth_year_of_passing': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().tenth_year_of_passing : '',
                'tenth_marking_scheme': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().tenth_marking_scheme : '',
                'tenth_obtained_percentage_cgpa': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa : '',
                'twelth_institution_name': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_institution_name) ? this.qualificationDetailsForm.getRawValue().twelth_institution_name : '',
                'twelth_board': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_board) ? this.qualificationDetailsForm.getRawValue().twelth_board : '',
                'twelth_year_of_passing': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().twelth_year_of_passing : '',
                'twelth_marking_scheme': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().twelth_marking_scheme : '',
                'twelth_obtained_percentage_cgpa': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa : '',
                'tenth_certificate': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_certificate) ? this.qualificationDetailsForm.getRawValue().tenth_certificate : '',
                'twelth_certificate': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_certificate) ? this.qualificationDetailsForm.getRawValue().twelth_certificate : '',
            };
            this.submitting = true;
            this.studentQualificationService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.getQualifications();
                    this.verticalWizardNext();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updateQualificationDetails() {
        this.submitted = true;
        if (!this.qualificationDetailsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'tenth_institution_name': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_institution_name) ? this.qualificationDetailsForm.getRawValue().tenth_institution_name : '',
                'tenth_board': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_board) ? this.qualificationDetailsForm.getRawValue().tenth_board : '',
                'tenth_year_of_passing': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().tenth_year_of_passing : '',
                'tenth_marking_scheme': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().tenth_marking_scheme : '',
                'tenth_obtained_percentage_cgpa': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa : '',
                'twelth_institution_name': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_institution_name) ? this.qualificationDetailsForm.getRawValue().twelth_institution_name : '',
                'twelth_board': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_board) ? this.qualificationDetailsForm.getRawValue().twelth_board : '',
                'twelth_year_of_passing': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().twelth_year_of_passing : '',
                'twelth_marking_scheme': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().twelth_marking_scheme : '',
                'twelth_obtained_percentage_cgpa': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa : '',
                'tenth_certificate': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().tenth_certificate) ? this.qualificationDetailsForm.getRawValue().tenth_certificate : '',
                'twelth_certificate': (this.qualificationDetailsForm.getRawValue() && this.qualificationDetailsForm.getRawValue().twelth_certificate) ? this.qualificationDetailsForm.getRawValue().twelth_certificate : '',
            };
            this.submitting = true;
            this.studentQualificationService.update(this.qualificationData['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.verticalWizardNext();
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    saveMoreQualifications() {
        let more_qualifications = [];
        if (this.items && this.items.length > 0) {
            this.items.forEach(item => {
                more_qualifications.push({
                    university: (item && item.university) ? item.university : '',
                    institution: (item && item.institution) ? item.institution : '',
                    passing_year: (item && item.passing_year) ? item.passing_year : '',
                    course: (item && item.course) ? item.course : '',
                    branch: (item && item.branch) ? item.branch : '',
                    obtained_percenatge: (item && item.obtained_percenatge) ? item.obtained_percenatge : '',
                    completion_certificate: (item && item.completion_certificate) ? item.completion_certificate : ''
                });
            });
            this.studentMoreQualificationService.create({
                'student_id': this.id,
                'more_qualifications': more_qualifications
            }).subscribe(data => {
                if (data) {
                    this.getMoreQualifications();
                    this.verticalWizardNext();
                }
            });
        } else {
            this.alertService.showErrors('More Qualifications details can\'t be empty');
        }
    }

    updateMoreQualifications() {
        let more_qualifications = [];
        if (this.items && this.items.length > 1 &&
            (this.items[0].branch !== '' || this.items[0].completion_certificate !== '' || this.items[0].course !== '' || this.items[0].data !== '' || this.items[0].institution !== '' || this.items[0].obtained_percenatge !== '' || this.items[0].passing_year !== '' || this.items[0].university !== '')) {
            this.items.forEach(item => {
                more_qualifications.push({
                    university: (item && item.university) ? item.university : '',
                    institution: (item && item.institution) ? item.institution : '',
                    passing_year: (item && item.passing_year) ? item.passing_year : '',
                    course: (item && item.course) ? item.course : '',
                    branch: (item && item.branch) ? item.branch : '',
                    obtained_percenatge: (item && item.obtained_percenatge) ? item.obtained_percenatge : '',
                    completion_certificate: (item && item.completion_certificate) ? item.completion_certificate : ''
                });
            });
            this.studentMoreQualificationService.update(this.id, {'more_qualifications': more_qualifications}).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.getMoreQualifications();
                this.verticalWizardNext();
            });
        } else {
            this.studentMoreQualificationService.delete(this.id).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.getMoreQualifications();
                this.verticalWizardNext();
            });
        }
    }

    saveCourses() {
        this.submitted = true;
        if (!this.studentCourseDetailsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'student_id': this.id,
                'program_id': (this.studentCourseDetailsForm.getRawValue() && this.studentCourseDetailsForm.getRawValue().program_id) ? this.studentCourseDetailsForm.getRawValue().program_id : '',
                'course_id': (this.studentCourseDetailsForm.getRawValue() && this.studentCourseDetailsForm.getRawValue().course_id) ? this.studentCourseDetailsForm.getRawValue().course_id : '',
                'branch_id': (this.studentCourseDetailsForm.getRawValue() && this.studentCourseDetailsForm.getRawValue().branch_id) ? this.studentCourseDetailsForm.getRawValue().branch_id : '',
            };
            this.submitting = true;
            this.studentCourseDetailsService.create(params).subscribe(data => {
                if (data) {
                    this.getCourseDetails();
                    this.verticalWizardNext();
                }
            });
        }
    }

    updateCourses() {
        this.submitted = true;
        if (!this.studentCourseDetailsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'program_id': (this.studentCourseDetailsForm.getRawValue() && this.studentCourseDetailsForm.getRawValue().program_id) ? this.studentCourseDetailsForm.getRawValue().program_id : '',
                'course_id': (this.studentCourseDetailsForm.getRawValue() && this.studentCourseDetailsForm.getRawValue().course_id) ? this.studentCourseDetailsForm.getRawValue().course_id : '',
                'branch_id': (this.studentCourseDetailsForm.getRawValue() && this.studentCourseDetailsForm.getRawValue().branch_id) ? this.studentCourseDetailsForm.getRawValue().branch_id : '',
            };
            this.submitting = true;
            this.studentCourseDetailsService.update(this.studentCourseData['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.verticalWizardNext();
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }
}
