import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FileService} from '../../shared/services/file.service';
import {StorageService} from '../../shared/services/storage.service';
import {UniversityService} from '../../shared/services/university.service';
import {environment} from '../../../environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {AlertService} from '../../shared/services/alert.service';
import {Location} from '@angular/common';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {HeadCounsellorService} from '../../shared/services/head-counsellor.service';
import {AdminService} from '../../shared/services/admin.service';
import {DataService} from '../../shared/services/data.service';
import {CounsellorService} from '../../shared/services/counsellor.service';

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
    myProfileForm: FormGroup;
    updatePasswordForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    croppedImage: any;
    croppedImageEvent: any;
    imageChangedEvent: any;
    user: any;
    profileImage: any;
    profileImageUploadArea: any = 'assets/images/image-backgrounds/200-200.png';
    data: any;
    id: any;

    constructor(public modalService: NgbModal,
                public fileService: FileService,
                public universityService: UniversityService,
                private fb: FormBuilder,
                private authService: AuthService,
                private alertService: AlertService,
                private _location: Location,
                private headCounsellorService: HeadCounsellorService,
                private counsellorService: CounsellorService,
                private adminService: AdminService,
                private _coreSidebarService: CoreSidebarService,
                private dataService: DataService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
        this.getUniversityDetails();
        this.getCounsellorDetails();
        this.getAdminDetails();
    }

    refresh() {
        this.myProfileForm = this.fb.group({
            'name': ['', Validators.required],
            'username': [{'value': '', disabled: true}],
            'email': ['', Validators.required],
            'contact': ['', Validators.required]
        });
        this.getSelfData();
    }

    getSelfData() {
        this.authService.self({}).subscribe(data => {
            this.patchProfileForm(data);
        });
    }

    patchProfileForm(data) {
        this.myProfileForm.patchValue({
            'name': (data && data['name']) ? data['name'] : '',
            'username': (data && data['username']) ? data['username'] : '',
            'email': (data && data['email']) ? data['email'] : '',
            'contact': (data && data['contact']) ? data['contact'] : '',
        });
    }


    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-my-profile').toggleOpen();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.toggleSidebar();
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageEvent = event;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    onImageChange(e) {
        this.imageChangedEvent = e;
    }

    saveProfileImage() {
        const params = {
            'type': 'PROFILE_IMAGE',
            'user_id': this.user.userId,
            'fileType': 'BASE64'
        };
        this.fileService.fileUpload(params, this.croppedImage).subscribe(data => {
            if (data) {
                this.modalService.dismissAll('');
                this.imageChangedEvent = undefined;
                if (this.user && this.user['user_type'] === 'UNIVERSITY') {
                    this.updateUniversityProfileImage({'university_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'COUNSELLOR') {
                    this.updateCounsellorProfileImage({'counsellor_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'ADMIN') {
                    this.updateAdminProfileImage({'admin_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
                    this.updateHeadCounsellorProfileImage({'admin_id': this.user.userId, 'profile_image': data['data'].id});
                }
            }
        });
    }

    updateUniversityProfileImage(data) {
        this.universityService.updateProfile(this.user.userId, data).subscribe(data => {
            this.getUniversityDetails();
        });
    }

    updateCounsellorProfileImage(data) {
        this.counsellorService.updateProfile(this.user.userId, data).subscribe(data => {
            this.getCounsellorDetails();
        });
    }

    updateHeadCounsellorProfileImage(data) {
        this.headCounsellorService.updateProfile(this.user.userId, data).subscribe(data => {
            this.getHeadCounsellorDetails();
        });
    }

    updateAdminProfileImage(data) {
        this.adminService.updateProfile(this.user.userId, data).subscribe(data => {
            this.getAdminDetails();
        });
    }

    getUniversityDetails() {
        this.universityService.detail(this.user.userId).subscribe(data => {
            if (data && data['university_detail'] && data['university_detail']['profile_image']) {
                this.profileImage = data['university_detail']['profile_image'].url;
                this.profileImageUploadArea = data['university_detail']['profile_image'].url;
                this.dataService.sendMessage(this.profileImage);
            }
        });
    }

    getCounsellorDetails() {
        this.counsellorService.detail(this.user.userId).subscribe(data => {
            if (data && data['counsellor_detail'] && data['counsellor_detail']['profile_image']) {
                this.profileImage = data['counsellor_detail']['profile_image'].url;
                this.profileImageUploadArea = data['counsellor_detail']['profile_image'].url;
                this.dataService.sendMessage(this.profileImage);
            }
        });
    }

    getHeadCounsellorDetails() {
        this.headCounsellorService.detail(this.user.userId).subscribe(data => {
            if (data && data['head_counsellor_detail'] && data['head_counsellor_detail']['profile_image']) {
                this.profileImage = data['head_counsellor_detail']['profile_image'].url;
                this.profileImageUploadArea = data['head_counsellor_detail']['profile_image'].url;
                this.dataService.sendMessage(this.profileImage);
            }
        });
    }

    getAdminDetails() {
        this.adminService.detail(this.user.userId).subscribe(data => {
            if (data && data['admin_detail'] && data['admin_detail']['profile_image']) {
                this.profileImage = data['admin_detail']['profile_image'].url;
                this.profileImageUploadArea = data['admin_detail']['profile_image'].url;
                this.dataService.sendMessage(this.profileImage);
            }
        });
    }

    openModel(content) {
        this.modalService.open(content);
    }

    detail() {
        this.authService.self(parseInt(this.user['userId'])).subscribe(data => {
            if (data) {
                this.myProfileForm.patchValue({
                    'profilename': (data && data.name) ? data.name : '',
                    'username': (data && data.username) ? data.username : '',
                    'email': (data && data.email) ? data.email : '',
                    'contact': (data && data.contact) ? data.contact : ''
                });
            }
        }, error => {
            this.back();
        });
    }

    back() {
        this._location.back();
    }

    update() {
        this.submitted = true;
        if (!this.myProfileForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.myProfileForm.value && this.myProfileForm.value.name) ? this.myProfileForm.value.name : '',
                'email': (this.myProfileForm.value && this.myProfileForm.value.email) ? this.myProfileForm.value.email : '',
                'contact': (this.myProfileForm.value && this.myProfileForm.value.contact) ? this.myProfileForm.value.contact : ''
            };
            this.submitting = true;
            this.authService.update(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }
}
