import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReasonsRoutingModule} from './reasons-routing.module';
import {ReasonsComponent} from './reasons.component';
import {AddReasonComponent} from './add-reason/add-reason.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [ReasonsComponent, AddReasonComponent],
    imports: [
        CommonModule,
        ReasonsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule
    ],
    exports: [ReasonsComponent, AddReasonComponent]
})
export class ReasonsModule {
}
