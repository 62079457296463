import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProgramsComponent} from './programs.component';
import {AddProgramComponent} from './add-program/add-program.component';

const routes: Routes = [
    {
        path: 'programs',
        component: ProgramsComponent,
        data: {animation: 'programs'}
    },
    {
        path: 'program/add',
        component: AddProgramComponent,
        data: {animation: 'program/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProgramsRoutingModule {
}
