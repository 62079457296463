import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UniversitiesRoutingModule} from './universities-routing.module';
import {UniversitiesComponent} from './universities.component';
import {AddUniversityComponent} from './add-university/add-university.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbCarouselModule, NgbNavModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {UniversityLandingComponent} from './university-landing/university-landing.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {SharedModule} from '../../shared/shared.module';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
    declarations: [UniversitiesComponent, AddUniversityComponent, UniversityLandingComponent],
    imports: [
        CommonModule,
        UniversitiesRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgbCarouselModule,
        NgbNavModule,
        GoogleMapsModule,
        SharedModule,
        ImageCropperModule
    ],
    exports: [UniversitiesComponent, AddUniversityComponent, UniversityLandingComponent]
})
export class UniversitiesModule {
}
