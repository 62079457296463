import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoursesComponent} from './courses.component';
import {AddCourseComponent} from './add-course/add-course.component';

const routes: Routes = [
    {
        path: 'courses',
        component: CoursesComponent,
        data: {animation: 'courses'}
    },
    {
        path: 'course/add',
        component: AddCourseComponent,
        data: {animation: 'course/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CoursesRoutingModule {
}
