import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CounsellorsRoutingModule} from './counsellors-routing.module';
import {CounsellorsComponent} from './counsellors.component';
import {AddCounsellorComponent} from './add-counsellor/add-counsellor.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';


@NgModule({
    declarations: [CounsellorsComponent, AddCounsellorComponent],
    imports: [
        CommonModule,
        CounsellorsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgSelectModule
    ],
    exports: [CounsellorsComponent, AddCounsellorComponent],
})
export class CounsellorsModule {
}
