import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MailsRoutingModule} from './mails-routing.module';
import {MailsComponent} from './mails.component';
import {CoreSidebarModule} from '../../../@core/components';
import {MailSidebarComponent} from './mail-sidebar/mail-sidebar.component';
import {MailListComponent} from './mail-list/mail-list.component';
import {MailListItemComponent} from './mail-list/mail-list-item/mail-list-item.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MailComposeComponent} from './mail-compose/mail-compose.component';
import {MailDetailsComponent} from './mail-details/mail-details.component';
import {SharedModule} from '../../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {EmailService} from '../../shared/services/email.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {QuillModule, QuillService} from 'ngx-quill';

@NgModule({
    declarations: [MailsComponent, MailSidebarComponent, MailListComponent, MailListItemComponent, MailComposeComponent, MailDetailsComponent],
    imports: [
        CommonModule,
        MailsRoutingModule,
        CoreSidebarModule,
        PerfectScrollbarModule,
        SharedModule,
        FormsModule,
        NgSelectModule,
        QuillModule.forRoot(),
    ],
    exports: [
        MailsComponent, MailSidebarComponent
    ],
    providers: [
        EmailService,
        QuillService
    ]
})

export class MailsModule {
}
